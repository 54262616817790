import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';
import { ButtonComponent } from '@bupple/button';
import { UiIconComponent } from '@bupple/ui/icon';
import { GetSceneDto } from '../dtos/scene.dto';

@Component({
  selector: 'lib-scene-card',
  standalone: true,
  imports: [CommonModule, ButtonComponent, UiIconComponent],
  templateUrl: './scene-card.component.html',
  styleUrl: './scene-card.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SceneCardComponent {
  @Input({ required: true }) scene!: GetSceneDto;
  @Input() loadingDelete = false;

  @Output() selectScene: EventEmitter<MouseEvent> = new EventEmitter();
  @Output() clickDelete: EventEmitter<MouseEvent> = new EventEmitter();
}
