export const TIP_VIDEO_URLS = [
  {
    url: 'https://www.youtube.com/embed/2I6G1DFS2qM?si=01rQrguy6cdyE1k5',
    title: 'Find best social media content ideas with Bupple',
  },
  {
    url: 'https://www.youtube.com/embed/2I6G1DFS2qM?si=01rQrguy6cdyE1k5',
    title: 'How to make short videos with Bupple',
  },
  {
    url: 'https://www.youtube.com/embed/2I6G1DFS2qM?si=01rQrguy6cdyE1k5',
    title: 'Add auto subtitles to your videos using Bupple',
  },
];
