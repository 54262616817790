import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  OnDestroy,
  OnInit,
} from '@angular/core';
import {
  MatBottomSheet,
  MatBottomSheetModule,
} from '@angular/material/bottom-sheet';
import { MatIconModule } from '@angular/material/icon';
import { ButtonComponent } from '@bupple/button';
import { Ideaboard, NavigationService } from '@bupple/core';
import { IdeaboardCardComponent } from '@bupple/ideaboard-card';
import {
  IdeaboardApi,
  IdeaboardService,
  StudioFacade,
} from '@bupple/studio/data-access';
import { TopPatternComponent } from '@bupple/top-pattern';
import { UiBottomMenuComponent } from '@bupple/ui-bottom-menu';
import { UiIconComponent } from '@bupple/ui/icon';
import { UiLoadingComponent } from '@bupple/ui/loading';
import { UiNoDataFoundComponent } from '@bupple/ui/no-data-found';
import { LoadingDirective } from '@bupple/util/directives';
import { finalize } from 'rxjs';
import { SubSink } from 'subsink';
import { DeleteIdeaboardComponent } from '../delete-ideaboard/delete-ideaboard.component';
import { NewIdeaboardButtonComponent } from '../new-ideaboard-button/new-ideaboard-button.component';

@Component({
  selector: 'bupple-ideaboards',
  templateUrl: './ideaboards.component.html',
  styleUrls: ['./ideaboards.component.scss'],
  standalone: true,
  providers: [IdeaboardApi, IdeaboardService],
  imports: [
    CommonModule,
    MatIconModule,
    UiLoadingComponent,
    IdeaboardCardComponent,
    UiIconComponent,
    UiNoDataFoundComponent,
    LoadingDirective,
    MatBottomSheetModule,
    DeleteIdeaboardComponent,
    NewIdeaboardButtonComponent,
    UiBottomMenuComponent,
    ButtonComponent,
    TopPatternComponent,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class IdeaboardsComponent implements OnInit, OnDestroy {
  constructor(
    private ideaboardService: IdeaboardService,
    private navigation: NavigationService,
    private bottomSheet: MatBottomSheet,
    private studioFacade: StudioFacade,
    private cdr: ChangeDetectorRef,
  ) {}

  ngOnInit(): void {
    this.getIdeaboardsFromStore();
  }

  subsink = new SubSink();
  ideaboards: Ideaboard[] = [];
  inProgress = true;
  getIdeaboardsFromStore() {
    this.studioFacade.ideaboards$.subscribe((ideaboards) => {
      if (ideaboards) {
        this.ideaboards = ideaboards.data;
        this.currentPage = ideaboards.current_page;
        this.hasNextPage = Boolean(ideaboards.next_page_url);
        this.inProgress = false;
        this.cdr.markForCheck();
      }
    });
  }
  hasNextPage = false;
  currentPage = 1;
  getIdeaboards(page: number) {
    this.subsink.sink = this.ideaboardService.getAll(page).subscribe();
  }

  inProgressCreate = false;
  onCreateIdeaboard() {
    this.inProgressCreate = true;
    this.subsink.sink = this.ideaboardService.add().subscribe({
      next: (result: Ideaboard) => {
        this.navigation.goIdeaboard(result._id);
      },
      error: (error) => {
        this.inProgressCreate = false;
        this.cdr.markForCheck();
      },
    });
  }

  onDeleteIdeaboard(event: MouseEvent, ideaboard: Ideaboard) {
    this.subsink.sink = this.bottomSheet
      .open(DeleteIdeaboardComponent, {
        data: ideaboard,
      })
      .afterDismissed()
      .subscribe((result) => {
        result && this.deleteIdeaboard(ideaboard._id);
      });
  }

  deleteIdeaboard(id: string): void {
    this.ideaboards = this.ideaboards.map((i) =>
      i._id === id ? { ...i, inProgress: true } : i,
    );

    this.cdr.markForCheck();

    this.subsink.sink = this.ideaboardService
      .delete(id)
      .pipe(
        finalize(() => {
          this.cdr.markForCheck();
        }),
      )
      .subscribe({
        next: (result) => {
          if (result.msg) {
            this.ideaboards = this.ideaboards.filter((i) => i._id !== id);
          }
        },
        error: () => {
          this.ideaboards = this.ideaboards.map((i) =>
            i._id === id ? { ...i, inProgress: false } : i,
          );
        },
      });
  }

  ngOnDestroy(): void {
    this.subsink.unsubscribe();
  }
}
