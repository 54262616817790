import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  OnInit,
} from '@angular/core';
import { ReceivedMessageDto, StudioFacade } from '@bupple/studio/data-access';
import { UiIconComponent } from '@bupple/ui/icon';

@Component({
  selector: 'lib-progress-bar',
  standalone: true,
  imports: [CommonModule, UiIconComponent],
  templateUrl: './progress-bar.component.html',
  styleUrl: './progress-bar.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProgressBarComponent implements OnInit {
  @Input() recivedMessage?: ReceivedMessageDto;

  constructor(
    private studioFacade: StudioFacade,
    private cdr: ChangeDetectorRef,
  ) {}
  progressPercent = 0;
  ngOnInit() {
    this.listenStore();
  }
  progressNotif = '';
  listenStore() {
    this.studioFacade.videoProgress$.subscribe((progress) => {
      this.progressPercent = progress ?? 0;
      this.cdr.detectChanges();
    });
    this.studioFacade.videoNotif$.subscribe((notif) => {
      if (notif) {
        this.progressNotif = notif;
        this.cdr.detectChanges();
      }
    });
  }
}
