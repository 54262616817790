<div (click)="browseFiles()" class="text-center border p-3 rounded-lg">
  <mat-icon>cloud</mat-icon>
  <div *ngIf="(uploadPercentage$ | async) === 0">
    <h3>Tap to upload</h3>
    <p class="text-sm mt-4 text-base-content-secondary">
      Maximum allowed size: {{ config && config.maxSize }} MB
    </p>
  </div>

  <ng-template [ngIf]="uploadPercentage$ | async">
    <h3>
      <span class="loading loading-spinner loading-sm align-middle"></span>
      Uploading..
    </h3>
    <p class="text-sm mt-4 text-base-content-secondary">
      Completed {{ uploadPercentage$ | async }}% of the process
    </p>
  </ng-template>
</div>

<input
  type="file"
  class="hidden"
  [accept]="inputAcceptMap.get(config && config.model && config.model.type)"
  #fileInput
  (change)="changeFile($event)"
/>
