import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Environment } from '@bupple/interfaces';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class HttpClientAdaptor {
  constructor(
    private http: HttpClient,
    @Inject('environment') private environment: Environment,
  ) {}

  get<T>(url: string, options?: any): Observable<T> {
    return this.http.get<T>(this.getCompleteUrl(url), options) as Observable<T>;
  }

  post<T>(url: string, body: any, options?: any): Observable<T> {
    return this.http.post<T>(
      this.getCompleteUrl(url),
      body,
      options,
    ) as Observable<T>;
  }

  put<T>(url: string, body: any, options?: any): Observable<T> {
    return this.http.put<T>(
      this.getCompleteUrl(url),
      body,
      options,
    ) as Observable<T>;
  }

  patch<T>(url: string, body: any, options?: any): Observable<T> {
    return this.http.patch<T>(
      this.getCompleteUrl(url),
      body,
      options,
    ) as Observable<T>;
  }

  delete<T>(url: string, options?: any): Observable<T> {
    return this.http.delete<T>(
      this.getCompleteUrl(url),
      options,
    ) as Observable<T>;
  }

  private getCompleteUrl(url: string): string {
    return this.environment.api + '/' + url;
  }
}
