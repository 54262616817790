import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, OnDestroy } from '@angular/core';
import { FormBuilder, ReactiveFormsModule, Validators } from '@angular/forms';
import {
  MatBottomSheetModule,
  MatBottomSheetRef,
} from '@angular/material/bottom-sheet';
import { MatIconModule } from '@angular/material/icon';
import { ModalResult } from '@bupple/core';
import { SubSink } from 'subsink';
import { AddCardTitleComponent } from '../add-card-title/add-card-title.component';
import { Regex } from '../enums/regex.enum';
import { ButtonComponent } from '@bupple/button';

@Component({
  selector: 'bupple-add-link',
  standalone: true,
  imports: [
    CommonModule,
    MatBottomSheetModule,
    AddCardTitleComponent,
    ReactiveFormsModule,
    MatIconModule,
    ButtonComponent,
  ],
  templateUrl: './add-link.component.html',
  styleUrl: './add-link.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AddLinkComponent implements OnDestroy {
  constructor(
    private fb: FormBuilder,
    private bottomSheetRef: MatBottomSheetRef<AddLinkComponent>,
  ) {}

  subsink = new SubSink();
  form = this.fb.group({
    content: ['', [Validators.required, Validators.pattern(Regex.url)]],
  });

  get link(): string {
    return this.form.controls.content.value as string;
  }

  onAdd() {
    const result: Partial<ModalResult> = {
      data: this.form.value.content,
    };
    this.bottomSheetRef.dismiss(result);
  }

  ngOnDestroy(): void {
    this.subsink.unsubscribe();
  }
}
