<div>
  <div class="collapse bg-base-100 border border-base-content/12 md:min-h-20">
    <input type="checkbox"
      id="toggle"
      [checked]="false" />
    <div class="collapse-title text-xl font-medium !p-0">
      <div class="flex justify-between items-center w-full h-full px-4">
        <div class="flex items-center gap-x-2 md:gap-x-3 grow">
          <span class="h-9 px-4 rounded-full flex items-center special-shadow"
            [ngClass]="iconBg">
            <bupple-icon className="!w-4 !h-4"
              [name]="iconName"></bupple-icon>
          </span>
          <div class="grow">
            <p class="font-bold title-base-bold">
              {{ platformName }}
            </p>
          </div>
        </div>
        <div class="flex justify-end gap-x-2 flex-none"
          *ngIf="connectedAccountsInput.length">
          <div
            class="w-7 h-7 min-h-7 !rounded-full bg-base-content text-base-100 title-xs flex-none flex justify-center items-center"
            *ngIf="connectedAccountsInput.length">
            <span>
              {{ connectedAccountsInput.length }}
            </span>
          </div>
          <div>
            <bupple-icon size="sm"
              name="Outline / Arrows / Alt Arrow Down"></bupple-icon>
          </div>
        </div>
        <div class="relative z-50 flex-none">
          <lib-button *ngIf="!connectedAccountsInput.length"
            size="xs"
            color="neutral"
            (clickEvent)="connectedAccount()"
            [disabled]="connectDisable">
            <span>
              <bupple-icon name="Bold / Electronic, Devices / Plug Circle"
                color="base-100"
                size="sm"></bupple-icon>
              Connect
            </span>
          </lib-button>
        </div>
      </div>
    </div>
    <div class="collapse-content"
      *ngIf="connectedAccountsInput.length">
      <div class="space-y-3">
        <ul class="flex flex-col gap-y-3 md:flex-row md:gap-x-3 md:flex-wrap">
          <li class="text-base-content/50 min-w-[10rem]"
            *ngFor="let account of connectedAccountsInput">
            <div class="w-full rounded-3xl bg-base-500 flex justify-between p-2 items-center">
              <div class="flex-1 flex gap-x-3">
                <img class="w-6 h-6 rounded-full"
                  [src]="account.avatar_path"
                  alt="Acount Image" />
                <h4 class="sub-text-xl">{{ account.account.name }}</h4>
              </div>
              <bupple-icon class="flex-0 px-2 rotate-90 invisible"
                name="Bold / Essentional, UI / Menu Dots"></bupple-icon>
            </div>
          </li>
        </ul>
        <div class="max-32">
          <lib-button block
            color="neutral"
            appearance="text"
            size="xs"
            (clickEvent)="connectedAccount()">
            <bupple-icon name="Outline / Essentional, UI / Add Square"
              size="md"></bupple-icon>
            Add New Account
          </lib-button>
        </div>
      </div>
    </div>
  </div>
</div>