import { CommonModule } from '@angular/common';
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  Output,
  QueryList,
  ViewChildren,
} from '@angular/core';
import { FormsModule } from '@angular/forms';
import { TrackByService } from '@bupple/util/services';
import { cloneDeep } from 'lodash-es';
import { debounceTime, fromEvent } from 'rxjs';
import { SubSink } from 'subsink';
import { SegmentLine } from '../interfaces/subtitle.interface';

@Component({
  selector: 'lib-subtitle-line',
  standalone: true,
  imports: [CommonModule, FormsModule],
  templateUrl: './subtitle-line.component.html',
  styleUrl: './subtitle-line.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SubtitleLineComponent implements AfterViewInit, OnDestroy {
  constructor(protected trackBy: TrackByService) {}
  _line: SegmentLine = new SegmentLine('');
  @Input({ required: true }) set line(value: SegmentLine) {
    this._line = cloneDeep(value);
  }

  @Output() changeText: EventEmitter<SegmentLine> = new EventEmitter();
  @ViewChildren('textInput') inputs!: QueryList<ElementRef>;

  ngAfterViewInit(): void {
    this.setupListeners();

    this.inputs.changes.subscribe(() => {
      this.setupListeners();
    });
  }

  subsink = new SubSink();
  setupListeners() {
    this.subsink.unsubscribe();

    this.inputs.forEach((input, index) => {
      this.subsink.sink = fromEvent(input.nativeElement, 'input')
        .pipe(debounceTime(500))
        .subscribe(() => {
          this._line.text = this._line.words.map((w) => w.word).join(' ');
          this.changeText.emit(this._line);
        });
    });
  }

  ngOnDestroy(): void {
    this.subsink.unsubscribe();
  }
}
