import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  CreateMessageResultDto,
  IdeaboardChatApi,
  message,
  MessageType,
  ReceivedMessageDto,
  SentMessageDto,
  SseService,
  StudioFacade,
} from '@bupple/studio/data-access';
import { JsonHelper } from 'libs/core/src/lib/helpers/json.helper';
import {
  catchError,
  concatMap,
  delay,
  Observable,
  of,
  switchMap,
  tap,
  throwError,
} from 'rxjs';
import { v4 } from 'uuid';
import { SseResponseHandlerService } from './sse-handler.service';

@Injectable()
export class ChatActionService {
  constructor(
    private ideaboardChatApi: IdeaboardChatApi,
    private sseService: SseService,
    private studioFacade: StudioFacade,
    private sseResponseHandler: SseResponseHandlerService,
    private jsonHelper: JsonHelper,
  ) {}

  private ownerIdeaboardId = '';
  btn(message: message, func: string): Observable<any> {
    this.ownerIdeaboardId = message.ideaboard_id;
    this.studioFacade.setLoadingAsAMessage();

    return this.ideaboardChatApi
      .action(message._id, func)
      .pipe(switchMap((result) => this.getResponse(result)));
  }

  private getResponse(
    result: CreateMessageResultDto<SentMessageDto>,
  ): Observable<unknown> {
    return this.sseService.sse(result.url).pipe(
      catchError((error: HttpErrorResponse) => {
        this.studioFacade.removeLoadingMessage();
        this.studioFacade.setIsReceivingMessage(false);
        return this.jsonHelper.isJSON(error?.error)
          ? throwError(() => error)
          : of();
      }),
      concatMap((x) =>
        of(x).pipe(
          delay(50),
          tap((result) => {
            this.sseResponseHandler.handle(result, this.ownerIdeaboardId);
          }),
        ),
      ),
    );
  }

  setMockRecievedMessage(): void {
    const msg: ReceivedMessageDto = {
      _id: v4(),
      created_at: new Date().toString(),
      file_full_path: '',
      ideaboard_id: '',
      sender_id: null,
      updated_at: '',
      content: '',
      type: MessageType.text,
      chat_id: '',
      metadata: [],
      buttons: undefined,
      is_publishable: false,
    };
    this.studioFacade.setMockReceivedMessage(msg);
  }
}
