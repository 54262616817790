import { Injectable } from '@angular/core';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { ContinuePremiumComponent } from '../components/continue-premium/continue-premium.component';
import { NavigationService } from './navigation.service';

@Injectable({
  providedIn: 'root',
})
export class PayWallService {
  constructor(
    private bottomSheet: MatBottomSheet,
    private navigation: NavigationService,
  ) {}
  showContinuePremiumOverlay() {
    this.bottomSheet
      .open(ContinuePremiumComponent)
      .afterDismissed()
      .subscribe((result: boolean) => {
        result && this.navigation.goPlans();
      });
  }
}
