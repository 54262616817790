import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ThumbnailVideoComponent } from '@bupple/thumbnail-video';
import { SentMessageDto } from '@bupple/studio/data-access';

@Component({
  selector: 'bupple-sent-chat-message-video',
  standalone: true,
  imports: [CommonModule, ThumbnailVideoComponent],
  templateUrl: './sent-chat-message-video.component.html',
  styleUrl: './sent-chat-message-video.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SentChatMessageVideoComponent {
  @Input() message!: SentMessageDto;
}
