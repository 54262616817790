import { Ideaboard, MessageAction, SocialNetwork } from '@bupple/core';
import { ListDto } from '@bupple/interfaces';
import { createAction, props } from '@ngrx/store';
import {
  CreateMessageDto,
  ReceivedMessageDto,
  SentMessageDto,
  message,
} from '../dtos/message.dto';
import { AvailablePostDto, PublishableDto } from '../dtos/post.dto';
import { MessageType } from '../enums/message-type.enum';

export const setActiveIdeaboard = createAction(
  '[Studio/API] Set Active Ideaboard Id',
  props<{ ideaboard: Ideaboard }>(),
);

export const udpateIdeaboardName = createAction(
  `[Studio/API] Update Active Ideeaboard's Name`,
  props<{ name: string; id: string }>(),
);

export const resetActiveIdeaboard = createAction(
  '[Studio/API] Reset Active Ideeaboard',
);

export const setChatMessage = createAction(
  '[Studio/API] Set Chat Message',
  props<{ value: CreateMessageDto | null }>(),
);

export const setHomeInputChatMessage = createAction(
  '[Studio/API] Set Home Input Chat Message',
  props<{ value: CreateMessageDto | null }>(),
);

export const setSelectedAction = createAction(
  '[Studio/API] Set Selected Action',
  props<{ action: MessageAction | null }>(),
);

// Chat
export const setActiveIdeaboardChat = createAction(
  '[Studio/API] Set Active Ideaboard Chat',
  props<{ chat: ListDto<message> }>(),
);

export const prependNewChatMessages = createAction(
  '[Studio/API] Prepend New Chat Message',
  props<{ chat: ListDto<message> }>(),
);

export const setMockSentMessage = createAction(
  '[Studio/API] Set Mock Sent Message',
  props<{ message: SentMessageDto }>(),
);

export const removeMocklSentMessage = createAction(
  '[Studio/API] Remove Mock Sent Message',
);

export const setRealSentMessage = createAction(
  '[Studio/API] Set Real Sent Message',
  props<{ message: SentMessageDto }>(),
);

export const setMockReceivedMessage = createAction(
  '[Studio/API] Set Mocked Received Message',
  props<{ message: ReceivedMessageDto }>(),
);

export const setLoadingAsAMessage = createAction(
  '[Studio/API] Set Loading As A Message',
  props<{ message: SentMessageDto }>(),
);

export const removeLoadingMessage = createAction(
  '[Studio/API] Set Loading Message',
);

export const udpateMockReceivedMessageContent = createAction(
  '[Studio/API] Update Mock Received Message Content',
  props<{ content: string }>(),
);

export const udpateMockReceivedMessageType = createAction(
  '[Studio/API] Update Received Message Type',
  props<{ messageType: MessageType }>(),
);

export const setRealReceivedMessage = createAction(
  '[Studio/API] Set Real Received Message',
  props<{ message: ReceivedMessageDto }>(),
);

export const clearSelectedUnitToReply = createAction(
  '[Studio/API] Clear Selected Unit To Reply',
);

export const updateChatMessage = createAction(
  '[Studio/API] Update Chat Message',
  props<{ message: ReceivedMessageDto }>(),
);

export const SetPosts = createAction(
  '[Studio/API] Set Posts',
  props<{ posts: any[] }>(),
);

export const setPublishableId = createAction(
  '[Studio/API/Make Post] Set Publishable Id',
  props<{ id: string | null }>(),
);

export const setPublishable = createAction(
  '[Studio/API/Make Post] Set Publishable',
  props<{ publishable: PublishableDto }>(),
);

export const updateAvailablePost = createAction(
  '[Studio/API/Make Post] Update Available Post',
  props<{ availablePost: AvailablePostDto }>(),
);

export const resetPublishable = createAction(
  '[Studio/API/Make Post] Reset Publishable',
);

export const deleteMessage = createAction(
  '[Studio/API/Chat] Delete Message',
  props<{ messageId: string }>(),
);

export const setTimeToScroll = createAction(
  '[Studio/API/Chat] Set Time To Scroll',
);

export const setIdeaboards = createAction(
  '[Studio/API] Set Ideaboards',
  props<{ ideaboards: ListDto<Ideaboard> }>(),
);

export const addNewIdeaboard = createAction(
  '[Studio/API] Add New Ideaboard',
  props<{ ideaboard: Ideaboard }>(),
);

export const removeIdeaboard = createAction(
  '[Studio/API] Remove Ideaboard',
  props<{ ideaboardId: string }>(),
);

export const reset = createAction('[Studio/API] Reset Everything');

export const setIsReceivingMessage = createAction(
  '[Studio/API] Set Is Receiving Message',
  props<{ value: boolean }>(),
);
export const setVideoProgress = createAction(
  '[Studio/API] Set Video Progress',
  props<{ progress: number }>(),
);

export const setVideoNotif = createAction(
  '[Studio/API] Set Video Notification',
  props<{ text: string }>(),
);

export const setIsPostPublished = createAction(
  '[Studio/API] Set Is Post Published',
  props<{ value: boolean }>(),
);
