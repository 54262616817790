import { error } from 'console';

export const TITLE = {
  root: '',
  intro: 'Bupple - Intro',

  // Auth
  auth: 'Bupple - Auth',
  signUp: 'Bupple - Sign up',
  signIn: 'Bupple - Sign In',
  forgotPassword: 'Bupple - Forget Password',
  resetPassword: 'Bupple - Reset Password',

  // Connect accounts
  connectAccounts: 'Bupple - Connect Accounts',
  home: 'Bupple - Home',

  studio: 'Bupple - studio',

  // Ideaboards
  ideaboards: 'Bupple - Ideaboards',
  ideaboard: 'Bupple - Ideaboard',
  chat: 'Bupple - Chat',
  makePost: 'Bupple - Make Post',
  schedule: 'Bupple - Schedule',
  published: 'Bupple - Published',
  posts: 'Bupple - Posts',
  scheduled: 'Bupple - Scheduled',
  pending: 'Bupple - Pending',
  plans: 'Bupple - Plans',
  planSchedule: 'Bupple - Plan Schedule',
  editor: 'Bupple - Editor',

  profile: 'Bupple - Profile',

  // Hub
  hub: 'Bupple - hub',
  error: 'Bupple - Error',
};
