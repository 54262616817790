import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { FormsModule } from '@angular/forms';
import { ButtonComponent } from '@bupple/button';
import { NavigationService } from '@bupple/core';
import { UiBottomMenuComponent } from '@bupple/ui-bottom-menu';
import { UiIconComponent } from '@bupple/ui/icon';
import { filter, finalize } from 'rxjs';
import { SubSink } from 'subsink';
import { PlanDTO, PlansDTO } from '../dtos/plans.dtos';
import { PlanFeaturesComponent } from '../plan-features/plan-features.component';
import { PlanSkeletonComponent } from '../plan-skeleton/plan-skeleton.component';
import { PlansService } from '../services/plans.service';

@Component({
  selector: 'lib-price-plans',
  standalone: true,
  imports: [
    CommonModule,
    UiIconComponent,
    UiBottomMenuComponent,
    ButtonComponent,
    FormsModule,
    PlanSkeletonComponent,
    PlanFeaturesComponent,
  ],
  templateUrl: './price-plans.component.html',
  styleUrl: './price-plans.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PricePlansComponent implements OnInit, OnDestroy {
  private subSink = new SubSink();
  premiumPlan!: PlansDTO;
  plansYearly: PlanDTO[] = [];
  plansMonthly: PlanDTO[] = [];
  selectedPlan!: PlanDTO;
  showMore = false;
  waitingData = false;
  radioModel = '';

  constructor(
    protected navigation: NavigationService,
    private plansService: PlansService,
    private cdr: ChangeDetectorRef,
  ) {}

  ngOnInit(): void {
    this.loadPlan();
  }

  private loadPlan() {
    this.waitingData = true;
    this.cdr.markForCheck();
    this.subSink.sink = this.plansService
      .getPlan()
      .pipe(
        filter((result) => !!result),
        finalize(() => {
          this.waitingData = false;
          this.cdr.markForCheck();
        }),
      )
      .subscribe({
        next: (result) => {
          this.premiumPlan = result;
          console.log('Premium', result);
          this.updatePlans();
        },
      });
  }

  private updatePlans(): void {
    this.plansYearly = this.premiumPlan.yearly.sort(
      (a, b) => b.price - a.price,
    );
    this.plansMonthly = this.premiumPlan.monthly.sort(
      (a, b) => b.price - a.price,
    );
    this.selectedPlan = this.plansYearly[0];
  }

  onSelectedPlan(planInfo: PlanDTO): void {
    this.selectedPlan = planInfo;
  }

  subscribePlan() {
    if (this.selectedPlan && this.premiumPlan) {
      this.subSink.sink = this.plansService
        .subscribePlan(this.selectedPlan.id, this.selectedPlan.stripe_price_id)
        .subscribe();
    }
  }

  selectedTab = 'yearly';
  tabHandler(tab: string) {
    this.selectedTab = tab;
    if (tab === 'monthly') {
      this.selectedPlan = this.plansMonthly[0];
    } else {
      this.selectedPlan = this.plansYearly[0];
    }
  }

  ngOnDestroy(): void {
    this.subSink.unsubscribe();
  }
}
