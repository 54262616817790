import { CommonModule } from '@angular/common';
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  OnDestroy,
  ViewChild,
} from '@angular/core';
import { Application, Container } from 'pixi.js';
import { combineLatest, filter, fromEvent } from 'rxjs';
import { SubSink } from 'subsink';
import { VideoEditorFacade } from '../+state/video-editor.facade';
import { SegmentLine } from '../interfaces/subtitle.interface';
import {
  trackItem,
  TrackVideoItem,
} from '../interfaces/timeline-track-item.interface';
import { VideoClip } from '../interfaces/video-clip.interface';
import { PixiService } from '../services/pixi.service';
import { SubtitleService } from '../services/subtitle.service';
import { VideoElementService } from '../services/video-element.service';

@Component({
  selector: 'lib-video-editor-canvas',
  standalone: true,
  imports: [CommonModule],
  providers: [PixiService, VideoElementService, SubtitleService],
  templateUrl: './video-editor-canvas.component.html',
  styleUrl: './video-editor-canvas.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class VideoEditorCanvasComponent implements AfterViewInit, OnDestroy {
  constructor(
    private videoEditorFacade: VideoEditorFacade,
    private pixiService: PixiService,
    private videoElementService: VideoElementService,
  ) {}

  async ngAfterViewInit() {
    await this.setupPixi();
    this.listenStore();
  }

  subsink = new SubSink();
  pixi!: Application;
  @ViewChild('pixiCanvas') pixiCanvas: ElementRef = {} as ElementRef;
  async setupPixi() {
    this.pixi = await this.pixiService.setupPixi(this.pixiCanvas);
    this.setupPixiContainers();
    this.setupPixiTicker();
  }

  videoContainer!: Container;
  overlayContainer!: Container;
  subtitleContainer!: Container;
  setupPixiContainers() {
    this.videoContainer = this.pixiService.getContainer();
    this.pixiService.addContainerToPixi(this.videoContainer, this.pixi);

    this.overlayContainer = this.pixiService.getContainer();
    this.pixiService.addContainerToPixi(this.overlayContainer, this.pixi);

    this.subtitleContainer = this.pixiService.getContainer();
    this.pixiService.addContainerToPixi(this.subtitleContainer, this.pixi);
  }

  setupPixiTicker() {
    this.pixi.ticker.add(() => this.updateSubtitles());
  }

  activeTrackItem!: TrackVideoItem;
  nextClip!: trackItem;
  totalTimeSpent = 0;
  trackItems: TrackVideoItem[] = [];
  isPlaying = false;
  listenStore() {
    this.subsink.sink = combineLatest([
      this.videoEditorFacade.activeTrackItem$,
      this.videoEditorFacade.isPlaying$,
    ])
      .pipe(filter(([activeClip]) => !!activeClip))
      .subscribe(([activeClip, isPlaying]) => {
        const isANewClip =
          this.activeTrackItem?.asset?.id !== activeClip.asset.id;
        this.activeTrackItem = activeClip as TrackVideoItem;
        isANewClip && this.loadClip(this.activeTrackItem as TrackVideoItem);

        this.isPlaying = isPlaying;
        isPlaying ? this.playVideo() : this.pauseVideo();
      });

    this.subsink.sink = this.videoEditorFacade.nextClip$.subscribe(
      (nextClip) => {
        this.nextClip = nextClip;
      },
    );

    this.subsink.sink = this.videoEditorFacade.totalTimeSpent$.subscribe(
      (totalTimeSpent) => {
        this.totalTimeSpent = totalTimeSpent;
      },
    );

    this.subsink.sink = this.videoEditorFacade.subtitleLines$.subscribe(
      (lines) => {
        this.loadSubtitles(lines);
      },
    );

    this.subsink.sink = this.videoEditorFacade.clips$.subscribe(
      (trackItems) => {
        this.trackItems = trackItems as TrackVideoItem[];
      },
    );
  }

  currentVideoElement: VideoClip | null = null;
  loadClip(trackItem: TrackVideoItem) {
    this.updateSubtitles();
    this.resetAllThingsRelatedToTheContainer();

    const video = this.pixiService.getHtmlVideoElement(trackItem, this.pixi);

    const videoTexture = this.pixiService.getVideoTexture(video);

    const videoSprite = this.pixiService.getVideoSpirit(
      videoTexture,
      this?.pixi,
    );

    this.videoContainer.addChild(videoSprite);

    this.currentVideoElement = this.videoElementService.getVideoElement(
      trackItem,
      video,
    );

    this.addEventListeners();
  }

  resetAllThingsRelatedToTheContainer() {
    this.videoContainer.removeChildren();
    this.resetCurrentVideo();
    this.setTotalTimeSpent();
  }

  removeEventListeners() {
    this.subsinkEvents.unsubscribe();
  }

  setTotalTimeSpent() {
    this.videoEditorFacade.resetTotalTimeSpent();
    const time = this.videoElementService.calculateTotalTimeBeforeThisTrack(
      this.trackItems,
      this.activeTrackItem,
    );
    this.videoEditorFacade.updateTotalTimeSpent(time);
  }

  subsinkEvents = new SubSink();
  addEventListeners() {
    const video = (this.currentVideoElement as VideoClip)
      .element as HTMLVideoElement;

    this.subsinkEvents.sink = fromEvent(video, 'timeupdate').subscribe(
      (event) => {
        this.onTimeUpdate(event);
      },
    );

    this.subsinkEvents.sink = fromEvent(video, 'canplaythrough').subscribe(
      () => {
        this.videoEditorFacade.setVideoIsReady();
      },
    );

    this.subsinkEvents.sink = combineLatest([
      fromEvent(video, 'error'),
      fromEvent(video, 'waiting'),
      fromEvent(video, 'stalled'),
      fromEvent(video, 'suspend'),
      fromEvent(video, 'pause'),
      fromEvent(video, 'ended'),
    ]).subscribe(() => {
      this.videoEditorFacade.setVideoIsPlaying(false);
    });

    this.subsinkEvents.sink = fromEvent(video, 'playing').subscribe(() => {
      this.videoEditorFacade.setVideoIsPlaying(true);
    });
  }

  onTimeUpdate = (event: Event) => {
    const video = event.currentTarget as HTMLVideoElement;
    if (!this.isPlaying) {
      video.pause();
      return;
    }

    if (video.currentTime >= this.activeTrackItem.length) {
      video.pause();
      this.playNextClip();
    }

    const timeDifference =
      video.currentTime -
      (this.currentVideoElement as VideoClip).lastTimeUpdate;
    (this.currentVideoElement as VideoClip).lastTimeUpdate = video.currentTime;

    this.videoEditorFacade.updateTotalTimeSpent(timeDifference);
  };

  resetCurrentVideo() {
    this.removeEventListeners();
    this.currentVideoElement = null;
  }

  pauseVideo() {
    this.currentVideoElement?.element?.pause();
  }

  playVideo() {
    this.currentVideoElement?.element?.play();
  }

  playNextClip() {
    this.videoEditorFacade.setVideoIsPlaying(false);

    if (this.nextClip) {
      this.videoEditorFacade.setActiveClip(this.nextClip.id as string);
      this.videoEditorFacade.setIsPlaying(true);
    } else {
      this.videoEditorFacade.setIsPlaying(false);
      this.videoEditorFacade.resetTotalTimeSpent();
      this.videoEditorFacade.setFirstClipActive();
    }
  }

  lineContainers: Container[] = [];
  loadSubtitles(lines: SegmentLine[]) {
    this.resetLineContainers();

    this.lineContainers = this.pixiService.generateLineContainersBySubtitle(
      lines,
      this.pixi,
    );

    this.lineContainers.forEach((lineContainer) => {
      this.subtitleContainer.addChild(lineContainer);
    });
  }

  resetLineContainers() {
    this.lineContainers.forEach((lineContainer) => {
      this.subtitleContainer.removeChild(lineContainer);
      lineContainer.destroy({ children: true });
    });

    this.lineContainers = [];
  }

  updateSubtitles() {
    const currentTime = this.totalTimeSpent;

    this.lineContainers.forEach((lineContainer: any) => {
      // Check if the current time is within the line's display time
      if (
        currentTime >= lineContainer.startTime &&
        currentTime <= lineContainer.endTime
      ) {
        this.pixiService.showLineContainer(lineContainer, currentTime);
      } else {
        this.pixiService.hideLineContainer(lineContainer);
      }
    });
  }

  ngOnDestroy(): void {
    this.subsink.unsubscribe();
  }
}
