import { Directive, ElementRef, Input, Renderer2 } from '@angular/core';

@Directive({
  selector: '[loading]',
  standalone: true,
})
export class LoadingDirective {
  constructor(private el: ElementRef, private renderer: Renderer2) {}

  @Input() set loading(value: boolean | undefined) {
    value ? this.show() : this.hide();
  }

  show(): void {
    this.renderer.setAttribute(this.el.nativeElement, 'disabled', 'true');
    this.renderer.addClass(this.el.nativeElement, 'animate-pulse');
  }

  hide(): void {
    this.renderer.removeAttribute(this.el.nativeElement, 'disabled');
    this.renderer.removeClass(this.el.nativeElement, 'animate-pulse');
  }
}
