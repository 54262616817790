<label [for]="receivedMessage?._id">
  <div class="w-[360px] h-[360px] max-sm:w-[298px] max-sm:h-[298px] max-w-full">
    <img
      *ngIf="receivedMessage?.thumbnail_full_path || scenePreview"
      [src]="receivedMessage?.thumbnail_full_path || scenePreview"
      alt=" "
      class="mb-2 rounded-t-xl md:my-0 w-full h-full"
    />
  </div>
</label>
