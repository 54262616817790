import { Component } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { RouterLink } from '@angular/router';
import { ButtonComponent } from '@bupple/button';
import { NavigationService } from '@bupple/core';

@Component({
  selector: 'bupple-connect-accounts-onboarding',
  templateUrl: './connect-accounts-onboarding.component.html',
  styleUrls: ['./connect-accounts-onboarding.component.scss'],
  standalone: true,
  imports: [MatIconModule, RouterLink, ButtonComponent],
})
export class ConnectAccountsOnboardingComponent {
  constructor(protected navigation: NavigationService) {}
}
