import { Injectable } from '@angular/core';
import {
  DeleteIdeaboardResultDto,
  GetIdeaboardDto,
  GetIdeaboardsListDto,
  IdeaboardDto,
} from '@bupple/core';
import { HttpClientAdaptor } from '@bupple/util/adaptors';
import { Observable } from 'rxjs';

@Injectable()
export class IdeaboardApi {
  constructor(private http: HttpClientAdaptor) {}
  private endpointHead = 'ideaboard/';

  getAll(page: number): Observable<GetIdeaboardsListDto> {
    return this.http.get<GetIdeaboardsListDto>(
      `${this.endpointHead}table?page=${page}`,
    );
  }

  get(id: string): Observable<GetIdeaboardDto> {
    return this.http.get<GetIdeaboardDto>(this.endpointHead + 'id/' + id);
  }

  add(): Observable<GetIdeaboardDto> {
    return this.http.post<GetIdeaboardDto>(this.endpointHead + 'add', null);
  }

  update(ideaboard: IdeaboardDto): Observable<GetIdeaboardDto> {
    return this.http.post<GetIdeaboardDto>(
      this.endpointHead + 'update/' + ideaboard._id,
      ideaboard,
    );
  }

  delete(id: string): Observable<DeleteIdeaboardResultDto> {
    return this.http.post<DeleteIdeaboardResultDto>(
      this.endpointHead + 'delete/' + id,
      null,
    );
  }

  getVideoData(ideaboardId: string, script: string) {
    return this.http.post<DeleteIdeaboardResultDto>(
      this.endpointHead + ideaboardId + '/video-editor/initial',
      {
        script,
      },
    );
  }
}
