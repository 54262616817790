import { Injectable } from '@angular/core';
import {
  CreateMessageDto,
  CreateMessageResultDto,
  IdeaboardChatService,
  MessageType,
  SentMessageDto,
  SseService,
  StudioFacade,
} from '@bupple/studio/data-access';
import {
  Observable,
  catchError,
  concatMap,
  delay,
  filter,
  of,
  switchMap,
  tap,
} from 'rxjs';
import { v4 } from 'uuid';
import { SendChatMessage } from '../interfaces/send-message.interface';
import { SseResponseHandlerService } from './sse-handler.service';

@Injectable()
export class TextMessageService implements SendChatMessage {
  constructor(
    private sseService: SseService,
    private studioFacade: StudioFacade,
    private ideaboardChatService: IdeaboardChatService,
    private sseResponseHandler: SseResponseHandlerService,
  ) {}

  private ownerIdeaboardId = '';
  send(ideaboardId: string, message: CreateMessageDto): Observable<unknown> {
    this.ownerIdeaboardId = ideaboardId;
    this.setMockSentMessage(message.content);
    this.studioFacade.setLoadingAsAMessage();

    return this.ideaboardChatService.send(ideaboardId, message).pipe(
      filter((result: any) => !!result?.body?.chat),
      switchMap((result: any) => {
        this.studioFacade.setRealSentMessage(result.body.chat);

        return this.getResponse(result.body);
      }),
    );
  }

  private getResponse(
    result: CreateMessageResultDto<SentMessageDto>,
  ): Observable<unknown> {
    return this.sseService.sse(result.url).pipe(
      filter((result) => !!result?.data),
      catchError((error) => {
        this.studioFacade.removeLoadingMessage();
        this.studioFacade.setIsReceivingMessage(false);
        return of();
      }),
      concatMap((x) =>
        of(x).pipe(
          delay(30),
          tap((result: MessageEvent) => {
            this.sseResponseHandler.handle(result, this.ownerIdeaboardId);
          }),
        ),
      ),
    );
  }

  setMockSentMessage(content: string): void {
    const msg: SentMessageDto = {
      is_publishable: false,
      _id: v4(),
      created_at: new Date().toString(),
      file_full_path: '',
      ideaboard_id: '',
      sender_id: v4(),
      updated_at: '',
      type: MessageType.text,
      content: content,
    };
    this.studioFacade.setMockSentMessage(msg);
  }
}
