import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { UiIconComponent } from '@bupple/ui/icon';

@Component({
  selector: 'lib-credit-badge',
  standalone: true,
  imports: [CommonModule, UiIconComponent],
  templateUrl: './credit-badge.component.html',
  styleUrl: './credit-badge.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CreditBadgeComponent {
  @Input() icon = 'Bold / Stars';
  @Input() size = 'md';
  private _circle = false;
  @Input() set circle(value: boolean | string) {
    this._circle = value === true || value === '';
  }
  get circle() {
    return this._circle;
  }
  @Input() side = '';

  protected get _classes(): string {
    return `credit ${this.circle === true || this.circle === '' ? 'circle' : ''}  ${this.side === 'rt' ? 'side-credit' : ''} `;
  }
}
