import { Injectable } from '@angular/core';
import {
  KeyOfPing,
  MessageType,
  ReceivedMessageDto,
  StudioFacade,
} from '@bupple/studio/data-access';
import { v4 } from 'uuid';

@Injectable()
export class SseResponseHandlerService {
  constructor(private studioFacade: StudioFacade) {
    this.listenStore();
  }

  activeIdeaboardId!: string;
  listenStore() {
    this.studioFacade.activeIdeaboardId$.subscribe((id) => {
      this.activeIdeaboardId = id;
    });
  }

  handle(result: any, ownerIdeaboardId: string) {
    if (ownerIdeaboardId !== this.activeIdeaboardId) {
      return;
    }

    const parsedData = result?.data ? JSON.parse(result.data) : null;
    if (!parsedData) {
      return;
    }

    if (parsedData.key === KeyOfPing.qnm) {
      this.studioFacade.setRealReceivedMessage(parsedData.val);
    }

    if (parsedData.key === KeyOfPing.type) {
      this.studioFacade.removeLoadingMessage();
    }

    if (parsedData.key === KeyOfPing.bmb) {
      this.setMockRecievedMessage();
    }

    if (
      parsedData.key === KeyOfPing.type &&
      parsedData.val !== MessageType.image &&
      parsedData.val !== MessageType.images &&
      parsedData.val !== MessageType.video &&
      parsedData.val !== MessageType.sound &&
      parsedData.val !== MessageType.error
    ) {
      this.setMockRecievedMessage();
      this.studioFacade.udpateMockReceivedMessageType(parsedData.val);
    }

    if (parsedData.key === KeyOfPing.text) {
      this.studioFacade.udpateMockReceivedMessageContent(parsedData.val);
    }

    if (parsedData.key === KeyOfPing.eom) {
      this.studioFacade.setRealReceivedMessage(parsedData.val.chat);
    }

    if (parsedData.key === MessageType.error) {
      throw new Event('Not Implemented - Recieved Message');
    }
  }

  setMockRecievedMessage(): void {
    const msg: ReceivedMessageDto = {
      _id: v4(),
      created_at: new Date().toString(),
      file_full_path: '',
      ideaboard_id: '',
      sender_id: null,
      updated_at: '',
      type: MessageType.text,
      content: '',
      chat_id: '',
      metadata: [],
      buttons: undefined,
      is_publishable: false,
    };
    this.studioFacade.setMockReceivedMessage(msg);
  }
}
