<div class="px-page w-full">
    <div class="flex w-[21.4rem] max-w-full flex-col gap-3 bg-base-400 p-4 min-h-[12.5rem] rounded-2xl">
        <div class="flex gap-x-3 w-full">
            <div class="flex gap-x-2 w-2/3">
                <div class="w-4 h-4 rounded-full skeleton"></div>
                <div class="skeleton h-4 w-full"></div>
            </div>
            <div class="flex-1">
                <div class="w-full h-8 skeleton"></div>
            </div>
        </div>
        <div class="h-1 w-full skeleton"></div>
        <div class="w-full flex flex-col gap-y-3">
            <div class="skeleton h-4 w-full"></div>
            <div class="skeleton h-4 w-full"></div>
            <div class="skeleton h-4 w-28"></div>
        </div>
    </div>
</div>