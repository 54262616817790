import { Route } from '@angular/router';
import { AuthorizedGuard, PATH, TITLE } from '@bupple/core';

export const featureHomeRoutes: Route = {
  path: PATH.home,
  title: TITLE.home,
  canActivate: [AuthorizedGuard],
  loadComponent: () =>
    import('./feature-home/feature-home.component').then(
      (c) => c.FeatureHomeComponent,
    ),
};
