import { Route } from '@angular/router';
import { AuthorizedGuard, PATH, TITLE } from '@bupple/core';

export const featureMakePostRoutes: Route[] = [
  {
    path: `:ideaboardId/${PATH.makePost}/:publishableId`,
    title: TITLE.makePost,
    loadComponent: () =>
      import(
        './feature-make-post-layout/feature-make-post-layout.component'
      ).then((c) => c.FeatureMakePostLayoutComponent),
    children: [
      {
        path: '',
        title: TITLE.makePost,
        loadComponent: () =>
          import('./feature-make-post/feature-make-post.component').then(
            (c) => c.FeatureMakePostComponent,
          ),
      },
      {
        path: PATH.schedule + '/:socialNetwork',
        title: TITLE.schedule,
        canActivate: [AuthorizedGuard],
        loadComponent: () =>
          import(
            './feature-schedule-publish/feature-schedule-publish.component'
          ).then((c) => c.FeatureSchedulePublishComponent),
        children: [],
      },
    ],
  },
];
