import { Injectable } from '@angular/core';
import { filter, firstValueFrom } from 'rxjs';
import { CoreFacade } from '../+state/core.facade';
import { NavigationService } from '../services/navigation.service';

@Injectable({
  providedIn: 'root',
})
export class AuthorizedGuard {
  constructor(
    private coreFacade: CoreFacade,
    private navigation: NavigationService,
  ) {}
  async canActivate(): Promise<boolean> {
    await firstValueFrom(
      this.coreFacade.initialized$.pipe(filter((initialized) => !!initialized)),
    );

    const user = await firstValueFrom(this.coreFacade.user$);

    !user && this.navigation.goSignIn();

    return Promise.resolve(user !== null);
  }
}
