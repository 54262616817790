<div
  class="flex gap-3 cursor-pointer w-full p-3"
  [routerLink]="['/studio/chat/', ideaboard._id]"
  routerLinkActive="active bg-primary/16 rounded-xl"
>
  <div
    class="relative h-fit flex justify-start items-center gap-x-2 w-full overflow-visible"
  >
    <span>
      <bupple-icon
        color="base-content-tertiary"
        className="!w-4 !h-4"
        name="Outline / Messages, Conversation / Chat Round Dots"
      ></bupple-icon>
    </span>
    <div class="overflow-visible relative">
      <h4
        class="leading-5 md:sub-text-xl line-clamp-1 text-base-content w-full"
      >
        @if (this.isNameEditing) {
          <input
            type="text"
            placeholder="Ideaboard name ..."
            #nameInput
            class="input font-semibold focus:outline-none w-[calc(100%_-_3rem)] px-1 h-6 bg-transparent text-xs"
            [formControl]="nameFormControl"
            (keyup.enter)="onRename()"
          />
          <lib-button
            class="mask absolute right-0 top-1/2 -translate-y-1/2 z-50"
            size="xs"
            (clickEvent)="onRename()"
          >
            Save
          </lib-button>
        } @else {
          <div class="group">
            <span class="w-full pr-9 line-clamp-1 text-sm">
              {{ ideaboard.name }}
            </span>
            <!-- tooltip of title start -->
            <span class="md:hidden hidden tooltip-1 max-w-40">{{
              ideaboard && ideaboard.name
            }}</span>
          </div>
        }
      </h4>
    </div>
  </div>
</div>
<div
  class="opacity-0 group-hover/container:opacity-100 transition-all duration-500 absolute top-1/2 -translate-y-1/2 right-3"
>
  <div mat-button [matMenuTriggerFor]="menu">
    <bupple-icon
      color="base-content-tertiary"
      className="!w-4 !h-4"
      name="Bold / Essentional, UI / Menu Dots"
    ></bupple-icon>
  </div>
  <mat-menu #menu="matMenu" class="bg-transparent">
    <ul class="menu bg-base-400 rounded-box z-[1] w-fit p-2 shadow">
      <li>
        <a (click)="onEditName()">
          <bupple-icon
            name="Outline / Messages, Conversation / Pen 2"
          ></bupple-icon>
          Rename</a
        >
      </li>
      <li>
        <a (click)="onDelete($event)">
          <bupple-icon
            name="Outline / Essentional, UI / Trash Bin Trash"
          ></bupple-icon
          >Delete</a
        >
      </li>
    </ul>
  </mat-menu>
</div>
