import { Environment } from '@bupple/interfaces';
import Pusher, { Channel } from 'pusher-js';
import { StorageService } from './storage.service';
import { Inject, Injectable } from '@angular/core';
import { CoreFacade } from '../+state/core.facade';
import { UserCreditDto } from '../dtos/user.dto';

@Injectable({
  providedIn: 'root',
})
export class PusherService {
  constructor(
    private storage: StorageService,
    private coreFacade: CoreFacade,
    @Inject('environment') private environment: Environment,
  ) {}

  xsrfToken = '';

  async listenCreditEvents(userId: string) {
    this.subscribeCreditChannel(userId);
  }

  private channel!: Channel;
  private creditChannel!: Channel;

  private async subscribeCreditChannel(userId: string) {
    const pusher = new Pusher(this.environment.pusher.key, {
      channelAuthorization: {
        endpoint: this.environment.pusher.authEndpoint,
        headers: {
          Authorization: 'Bearer ' + this.storage.token,
        },
        transport: 'ajax',
      },
      cluster: this.environment.pusher.cluster,
    });

    this.creditChannel = pusher.subscribe('private-user-credit.' + userId);

    this.listenCreditChanges();
  }

  private listenCreditChanges() {
    this.creditChannel.bind('user-credit-update', (data: UserCreditDto) => {
      this.coreFacade.setUserCredit(data);
      console.log('credit pusher: ', data);
    });
  }
}
