import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SubscriptionPlan } from '@bupple/core';

@Component({
  selector: 'lib-credit-preview',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './credit-preview.component.html',
  styleUrl: './credit-preview.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CreditPreviewComponent {
  @Input() subscriptionPlan: SubscriptionPlan | undefined;

  changeToGB(value: number) {
    return value / 1024;
  }
}
