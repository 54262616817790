import { Route } from '@angular/router';
import { AuthorizedGuard, PATH, TITLE } from '@bupple/core';

export const profileRoutes: Route = {
  path: PATH.profile,
  title: TITLE.profile,
  canActivate: [AuthorizedGuard],
  loadComponent: () =>
    import('./profile/profile.component').then((c) => c.ProfileComponent),
};
