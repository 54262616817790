import { ChangeDetectionStrategy, Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UiIconComponent } from '@bupple/ui/icon';
import { EventEmitter, Output } from '@angular/core';

@Component({
  selector: 'lib-product-hunt-banner',
  standalone: true,
  imports: [CommonModule, UiIconComponent],
  templateUrl: './product-hunt-banner.component.html',
  styleUrl: './product-hunt-banner.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProductHuntBannerComponent {
  @Output() bannerClosed = new EventEmitter<void>();

  closeBanner() {
    this.bannerClosed.emit();
  }
}
