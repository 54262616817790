import { Injectable } from '@angular/core';
import { v4 } from 'uuid';
import { SegmentLine, SubtitleSegment } from '../interfaces/subtitle.interface';

@Injectable()
export class SubtitleService {
  private placeholder = 'LINE_PLACEHOLCER';
  getSegmentLines(
    segment: SubtitleSegment,
    maxCharacters: number,
  ): SegmentLine[] {
    const segmentLines: SegmentLine[] = [];
    let line: SegmentLine = new SegmentLine(segment.id);

    for (let i = 0; i < segment.words.length; i++) {
      line.words.push(segment.words[i]);

      if (
        line.words.map((w) => w.word).join('').length >= maxCharacters ||
        i === segment.words.length - 1
      ) {
        line.text = line.words.map((w) => w.word).join(' ');
        line.segmentText = segment.text.replace(line.text, this.placeholder);
        line.start = line.words[0].start;
        line.end = line.words[line.words.length - 1].end;

        segmentLines.push(line);
        line = new SegmentLine(segment.id);
      }
    }

    return segmentLines;
  }

  getUpdateSegmentByLine(line: SegmentLine) {
    return line.segmentText.replace(this.placeholder, line.text);
  }

  mapSegments(segments: SubtitleSegment[]) {
    return segments.map((s) => ({
      ...s,
      words: s.words.map((w) => ({ ...w, id: v4() })),
    }));
  }
}
