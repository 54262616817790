import {
  animate,
  state,
  style,
  transition,
  trigger,
} from '@angular/animations';
import { CommonModule } from '@angular/common';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { NavigationEnd, Router, RouterModule } from '@angular/router';
import {
  CoreFacade,
  GoogleTagManagerService,
  NavigationService,
  PwaUpdateService,
} from '@bupple/core';
import { filter } from 'rxjs';
import { SubSink } from 'subsink';
import { SplashComponent } from './components/splash/splash.component';

@Component({
  standalone: true,
  imports: [CommonModule, RouterModule, SplashComponent],
  selector: 'bupple-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  animations: [
    trigger('fadeOut', [
      state(
        'void',
        style({
          opacity: 0,
        }),
      ),
      transition(':leave', [animate('500ms ease')]),
    ]),
  ],
})
export class AppComponent implements OnInit, OnDestroy {
  constructor(
    private coreFacade: CoreFacade,
    protected navigation: NavigationService,
    private pwaUpdateService: PwaUpdateService,
    private router: Router,
    private gtmService: GoogleTagManagerService,
  ) {}

  ngOnInit(): void {
    this.pwaUpdateService.observeUpdates();
    this.listenRoute();
  }

  subsink = new SubSink();

  listenRoute() {
    this.router.events
      .pipe(filter((event) => event instanceof NavigationEnd))
      .subscribe((event) => {
        this.gtmService.pushToDataLayer({
          event: 'pageView',
          pagePath: (event as NavigationEnd).urlAfterRedirects,
        });
      });
  }

  theme$ = this.coreFacade.theme$;
  ngOnDestroy(): void {
    this.subsink.unsubscribe();
  }
}
