<div
  class="h-screen flex flex-col items-center justify-center px-page text-center"
>
  <h1 class="text-[70px] font-extrabold">404</h1>

  <h3 class="mt-10">There is nothing here!</h3>
  <p class="mt-4 mb-10 text-sm text-base-content/50">
    The page you're looking for is not found.
  </p>

  <lib-button (clickEvent)="navigation.goHome()"> Go Home </lib-button>
</div>
