import { Action, createReducer, on } from '@ngrx/store';
import { GetUserDto, UserCreditDto } from '../dtos/user.dto';
import { Theme } from '../enums/theme.enum';
import * as CoreActions from './core.actions';
import { ListAllPostsDto } from '@bupple/interfaces';

export const CORE_FEATURE_KEY = 'core';

export interface CoreState {
  initialized: boolean;
  user: GetUserDto | null;
  theme: Theme;
  promotionBanner?: any | null;
  posts?: ListAllPostsDto | null;
  userCredit?: UserCreditDto | null;
}

export const initialCoreState: CoreState = {
  initialized: false,
  user: null,
  theme: Theme.bupple,
  promotionBanner: null,
  posts: null,
  userCredit: null,
};

const reducer = createReducer(
  initialCoreState,
  on(CoreActions.resetUser, (state) => ({
    ...state,
    user: null,
  })),
  on(CoreActions.setInitialized, (state) => ({
    ...state,
    initialized: true,
  })),
  on(CoreActions.setUser, (state, { user }) => ({
    ...state,
    user: user,
  })),
  on(CoreActions.setPromotionBanner, (state, { banner }) => ({
    ...state,
    promotionBanner: banner,
  })),
  on(CoreActions.setPosts, (state, { posts }) => ({
    ...state,
    posts: posts,
  })),
  on(CoreActions.setUserCredit, (state, { credit }) => ({
    ...state,
    userCredit: credit,
  })),
);

export function coreReducer(state: CoreState | undefined, action: Action) {
  return reducer(state, action);
}
