import { Injectable } from '@angular/core';
import { NavigationEnd, NavigationExtras, Router } from '@angular/router';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class RouterAdaptor {
  _router: Router;
  private currentRouteSubject!: BehaviorSubject<string[]>;
  public currentRoute: Observable<string[]> | undefined;

  constructor(private router: Router) {
    this._router = this.router;

    this.currentRouteSubject = new BehaviorSubject<string[]>(
      this.extractCurrentRoute(),
    );
    this.currentRoute = this.currentRouteSubject.asObservable();
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        const currentRoute = this.extractCurrentRoute();
        this.currentRouteSubject.next(currentRoute);
      }
    });
  }

  navigate(commands: unknown[], extras?: NavigationExtras | undefined) {
    this._router.navigate(commands, extras);
  }

  private extractCurrentRoute(): string[] {
    const currentUrl = this.router.url;
    const currentUrlList = currentUrl.split('/');
    return currentUrlList;
  }
}
