<div class="h-screen flex flex-col border-r border-base-content/12 w-[280px]">
  <div class="p-3">
    <h4>Replacement Candidates</h4>
  </div>

  <div class="overflow-y-auto p-2 flex flex-wrap justify-center">
    @for (asset of assetCandidates; track asset.id) {
      <div
        class="video-item"
        [class.video-item--active]="
          activeTrackItemAsset &&
          asset.thumbnail_url === activeTrackItemAsset.thumbnail_url
        "
        (click)="onClickAsset(asset)"
      >
        <img [src]="asset.thumbnail_url" alt="" class="w-auto max-w-full" />
      </div>
    }
  </div>
</div>
