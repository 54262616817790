<div
  class="relative"
  [style.width.px]="videoWidth"
  [style.height.px]="videoHeight"
>
  <div *ngIf="loading">
    <div class="absolute top-0 left-0 w-full h-full">
      <div class="rounded-t-2xl skeleton"></div>
      <div
        class="h-full w-full absolute flex justify-center items-center z-50 top-0 left-0 skeleton"
      ></div>
    </div>
  </div>
  <div
    class="absolute top-0 left-0 w-full h-full"
    *ngIf="!loading && showThumbnail && receviedMessage?.thumbnail_full_path"
  >
    <img
      class="rounded-t-xl h-full object-cover"
      [src]="receviedMessage?.thumbnail_full_path"
      alt=" "
    />
    <div
      class="h-full w-full absolute flex justify-center items-center z-50 top-0 left-0"
      (click)="playVideo()"
    >
      <bupple-icon
        size="3xl"
        name="Bold / Video, Audio, Sound / Play Circle"
      ></bupple-icon>
    </div>
  </div>
  <video
    class="rounded-t-xl w-full h-full opacity-0"
    controls
    playsInline
    muted
    (play)="hideThumbnail()"
    [ngClass]="{ 'opacity-100': !showThumbnail }"
    (loadedmetadata)="onLoadedMetadata()"
    #videoPlayer
  >
    <source [src]="receviedMessage?.file_full_path" type="video/mp4" />
    <source [src]="receviedMessage?.file_full_path" type="video/webm" />
    <source [src]="receviedMessage?.file_full_path" type="video/ogg" />
    Your browser does not support the video tag.
  </video>
</div>
