import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import {
  CreativeEngineService,
  CreativeSceneService,
  ScenePreviewResult,
} from '@bupple/core';
import {
  IdeaboardChatService,
  ReceivedMessageDto,
  message,
} from '@bupple/studio/data-access';
import { UiIconComponent } from '@bupple/ui/icon';
import CreativeEngine from '@cesdk/engine';

@Component({
  selector: 'bupple-received-chat-message-creative',
  standalone: true,
  imports: [CommonModule, UiIconComponent],
  templateUrl: './received-chat-message-creative.component.html',
  styleUrls: ['./received-chat-message-creative.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ReceivedChatMessageCreativeComponent {
  constructor(
    private creativeEnginService: CreativeEngineService,
    private creativeSceneService: CreativeSceneService,
    private sanitizer: DomSanitizer,
    private cdr: ChangeDetectorRef,
    private ideaboardChatService: IdeaboardChatService,
  ) {}

  receivedMessage?: ReceivedMessageDto;
  @Input({ required: true }) set message(value: message) {
    this.receivedMessage = value as ReceivedMessageDto;

    this.receivedMessage &&
      this.receivedMessage.ideaboard_id &&
      !this.receivedMessage?.thumbnail_full_path;
    this.getPreview();
  }

  @Output() previewIsReady: EventEmitter<Blob> = new EventEmitter();

  scenePreview!: SafeUrl;
  async getPreview() {
    const engine = await this.creativeEnginService.getEngine();

    this.creativeSceneService
      .getScenePreview(engine, this.receivedMessage as ReceivedMessageDto)
      .then((preview: ScenePreviewResult) => {
        this.scenePreview = this.sanitizer.bypassSecurityTrustUrl(preview.url);
        this.cdr.markForCheck();
        this.previewIsReady.emit(preview.blob);
      });
    this.updateScene(engine, this.receivedMessage?._id as string);
  }

  async updateScene(engine: CreativeEngine, chatId: string) {
    const scene = await engine.scene.saveToString();
    this.ideaboardChatService
      .updateCreative(chatId, scene)
      .subscribe((result) => {
        console.log(result);
      });
  }
}
