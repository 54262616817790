import { SocialNetwork } from '@bupple/core';

export const socialMediaIconMap = new Map([
  [SocialNetwork.instagram, 'InstagramLogo'],
  [SocialNetwork.facebook, 'FacebookLogo'],
  [SocialNetwork.x, 'XLogo'],
  [SocialNetwork.linkedin, 'LinkedinLogo'],
  [SocialNetwork.tiktok, 'TikTokLogo'],
]);

export const socialMediaTitleMap = new Map([
  [SocialNetwork.instagram, 'Instagram'],
  [SocialNetwork.facebook, 'Facebook'],
  [SocialNetwork.x, 'Twitter (X)'],
  [SocialNetwork.linkedin, 'LinkedIn'],
  [SocialNetwork.tiktok, 'TikTok'],
]);
