<div class="flex gap-4 min-h-44">
    <!-- Preview -->
    <div
        class=" skeleton flex-none w-[5.5rem] md:w-28 flex flex-wrap items-center justify-end  min-h-[9rem] md:min-h-[10rem] gap-1 rounded-xl">
        <div class="mask skeleton">
            <span class="w-5 h-5 "></span>
        </div>
    </div>
    <!-- Details -->
    <div class=" skeleton flex flex-col grow">

    </div>
</div>