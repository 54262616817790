import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { ToasterService } from '@bupple/core';
import {
  CreateMessageDto,
  IdeaboardApi,
  IdeaboardChatApi,
  IdeaboardChatService,
  IdeaboardService,
  MessageType,
  PusherService,
  SseService,
  StudioFacade,
} from '@bupple/studio/data-access';
import { filter } from 'rxjs';
import { SubSink } from 'subsink';
import { ChatBodyComponent } from '../chat-body/chat-body.component';
import { ChatInputbarComponent } from '../chat-inputbar/chat-inputbar.component';
import { ChatTopbarComponent } from '../chat-topbar/chat-topbar.component';
import { SendChatMessage } from '../interfaces/send-message.interface';
import { LinkMessageService } from '../services/link-message.service';
import { SseResponseHandlerService } from '../services/sse-handler.service';
import { TextMessageService } from '../services/text-message.service';

@Component({
  selector: 'bupple-feature-chat',
  standalone: true,
  imports: [
    CommonModule,
    ChatTopbarComponent,
    ChatBodyComponent,
    ChatInputbarComponent,
  ],
  providers: [
    StudioFacade,
    IdeaboardService,
    TextMessageService,
    SseResponseHandlerService,
    LinkMessageService,
    IdeaboardChatApi,
    IdeaboardApi,
    SseService,
    ToasterService,
    IdeaboardChatService,
  ],
  templateUrl: './feature-chat.component.html',
  styleUrl: './feature-chat.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FeatureChatComponent implements OnInit, OnDestroy {
  constructor(
    private studioFacade: StudioFacade,
    private ideaboardService: IdeaboardService,
    private textMessageService: TextMessageService,
    private linkMessageService: LinkMessageService,
    private toaster: ToasterService,
    private ideaboardChatService: IdeaboardChatService,
    private pusherService: PusherService,
  ) {}

  ngOnInit(): void {
    this.listenStore();
  }

  subsink = new SubSink();
  subsinkStore = new SubSink();
  listenStore() {
    this.subsinkStore.sink = this.studioFacade.chatMessage$
      .pipe(filter((m) => !!m))
      .subscribe((message) => {
        this.sender(message as CreateMessageDto)
          .send(this.ideaboardIdOnRoute as string, message as CreateMessageDto)
          .subscribe({
            error: ({ error }) => {
              error?.message && this.toaster.error(error?.message);
            },
          });

        this.studioFacade.setChatMessage(null);
      });
  }

  sender(message: CreateMessageDto): SendChatMessage {
    return message.type === MessageType.text
      ? this.textMessageService
      : this.linkMessageService;
  }

  ideaboardIdOnRoute!: string;
  @Input() set id(value: string) {
    const switchedIdeaboard = value && this.ideaboardIdOnRoute !== value;

    this.ideaboardIdOnRoute = value;
    this.pusherService.listenIdeaboardEvents(this.ideaboardIdOnRoute);
    if (switchedIdeaboard) {
      this.subsink.unsubscribe();
      this.studioFacade.resetActiveIdeaboard();
      this.getIdeaboard(this.ideaboardIdOnRoute);
    }
  }

  getIdeaboard(id: string) {
    this.subsink.sink = this.ideaboardService.get(id).subscribe(() => {
      this.getIdeaboardChat(id);
    });
  }

  getIdeaboardChat(id: string) {
    this.subsink.sink = this.ideaboardChatService.getAll(id).subscribe();
  }

  ngOnDestroy(): void {
    this.subsink.unsubscribe();
    this.subsinkStore.unsubscribe();
  }
}
