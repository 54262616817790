import { CommonModule, DatePipe, NgFor } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { RouterModule } from '@angular/router';
import { ButtonComponent } from '@bupple/button';
import { Ideaboard, LastStatusDto, NavigationService } from '@bupple/core';
import { UiIconComponent } from '@bupple/ui/icon';
import { LoadingDirective } from '@bupple/util/directives';
import { UpdatedAtPipe } from '@bupple/util/pipes';
import { DeepReadonly } from 'ts-essentials';
import { IdeaboardLastStatusComponent } from '../ideaboard-last-status/ideaboard-last-status.component';

@Component({
  selector: 'bupple-ideaboard-card',
  templateUrl: './ideaboard-card.component.html',
  styleUrls: ['./ideaboard-card.component.scss'],
  standalone: true,
  imports: [
    MatIconModule,
    RouterModule,
    DatePipe,
    LoadingDirective,
    NgFor,
    UiIconComponent,
    UpdatedAtPipe,
    CommonModule,
    IdeaboardLastStatusComponent,
    IdeaboardLastStatusComponent,
    ButtonComponent,
  ],
})
export class IdeaboardCardComponent implements OnInit {
  constructor(protected navigation: NavigationService) {}
  screenWidth = window.screen.width;
  numberOfIdeaboard = 1;
  ideaboardLastStatus!: LastStatusDto;

  @Input({
    required: true,
  })
  ideaboard!: DeepReadonly<Ideaboard>;

  @Output() delete: EventEmitter<MouseEvent> = new EventEmitter();

  onClickDelete(event: MouseEvent) {
    this.delete.emit(event);
  }

  ngOnInit(): void {
    if (
      this.ideaboard.__dto.thumbnail_icons.includes('lnk') &&
      this.ideaboard.__dto.thumbnail_icons.includes('txt')
    ) {
      this.numberOfIdeaboard = this.ideaboard.__dto.thumbnail_icons.length - 1;
    } else {
      this.numberOfIdeaboard = this.ideaboard.__dto.thumbnail_icons.length;
    }

    this.ideaboardLastStatus = this.ideaboard.__dto.last_status;
  }
}
