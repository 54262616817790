<div>
  <bupple-add-card-title
    title="Add Image"
    subtitle="Upload your image file"
    icon="photo"
    class="block mb-3"
  ></bupple-add-card-title>
  <bupple-chat-uploader
    [config]="uploaderConfig"
    (completeUpload)="onAddUnit($event)"
  ></bupple-chat-uploader>
</div>
