import { Route } from '@angular/router';
import { adminCreativeEditorRoutes } from '@bupple/admin-creative-editor';
import { authFeatureSignInRoutes } from '@bupple/auth/feature-sign-in';
import { authFeatureSignUpRoutes } from '@bupple/auth/feature-sign-up';
import { AuthorizedGuard, PATH, TITLE } from '@bupple/core';
import { errorPageRoutes } from '@bupple/error-page';
import { featureChatRoutes } from '@bupple/feature-chat';
import { featureEditorRoutes } from '@bupple/feature-editor';
import { featureForgotPasswordRoutes } from '@bupple/feature-forgot-password';
import { featureMakePostRoutes } from '@bupple/feature-make-post';
import { featurePostsRoutes } from '@bupple/feature-posts';
import { featureVideoEditorRoutes } from '@bupple/feature-video-editor';
import { featureConnectAccountsRoutes } from '@bupple/feature/connect-accounts';
import { featureHomeRoutes } from '@bupple/feature/home';
import { featureIntroRoutes } from '@bupple/feature/intro';
import { notFoundPageRoutes } from '@bupple/not-found-page';
import { pricePlansRoutes } from '@bupple/price-plans';
import { profileRoutes } from '@bupple/profile';
import {
  StudioEffects,
  StudioFacade,
  StudioFeature,
} from '@bupple/studio/data-access';
import { IdeaboardListRoutes } from '@bupple/studio/feature-ideaboards';
import { provideEffects } from '@ngrx/effects';
import { provideState } from '@ngrx/store';

export const appRoutes: Route[] = [
  {
    path: '',
    pathMatch: 'full',
    redirectTo: `${PATH.studio}`,
  },
  // Intro
  featureIntroRoutes,
  adminCreativeEditorRoutes,
  // Auth
  {
    path: PATH.auth,
    title: TITLE.auth,
    children: [
      authFeatureSignUpRoutes,
      authFeatureSignInRoutes,
      ...(featureForgotPasswordRoutes as Route[]),
    ],
  },
  // Connect Accounts
  {
    path: PATH.connectAccounts,
    title: TITLE.connectAccounts,
    children: featureConnectAccountsRoutes,
  },
  // Studio
  {
    path: PATH.studio,
    title: TITLE.studio,
    canActivate: [AuthorizedGuard],
    providers: [
      provideEffects(StudioEffects),
      provideState(
        StudioFeature.STUDIO_FEATURE_KEY,
        StudioFeature.studioReducer,
      ),
      StudioFacade,
    ],
    children: [
      {
        path: PATH.root,
        title: TITLE.home,
        loadComponent: () =>
          import('@bupple/ui/main-layout').then((c) => c.UiMainLayoutComponent),
        children: [
          featureHomeRoutes,
          IdeaboardListRoutes,
          featureChatRoutes,
          profileRoutes,
          featurePostsRoutes,
          pricePlansRoutes,
          featureEditorRoutes,
          ...featureMakePostRoutes,
        ],
      },
      ...featureVideoEditorRoutes,
    ],
  },
  errorPageRoutes,
  notFoundPageRoutes,
];
