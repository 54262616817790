import { createAction, props } from '@ngrx/store';
import { GetUserDto, UserCreditDto } from '../dtos/user.dto';
import { Theme } from '../enums/theme.enum';
import { ListAllPostsDto } from '@bupple/interfaces';

export const resetUser = createAction('[Core] Reset User');

export const setInitialized = createAction('[Core] Set Initialized');

export const setUser = createAction(
  '[Core] Set User',
  props<{ user: GetUserDto }>(),
);

export const setTheme = createAction(
  '[Core] Set Theme',
  props<{ theme: Theme }>(),
);

export const setPromotionBanner = createAction(
  '[Core] Set Promotion Banner',
  props<{ banner: any }>(),
);

export const setPosts = createAction(
  '[core] Set Posts',
  props<{ posts: ListAllPostsDto }>(),
);

export const setUserCredit = createAction(
  '[core] Set User Credit',
  props<{ credit: UserCreditDto }>(),
);
