import { MessageActionSlug } from '@bupple/enums';
import { MessageActionDto } from '../dtos/message-action.dto';

export class MessageAction {
  __dto: MessageActionDto;

  args: unknown[];
  func: string | null;
  icon: string | null;
  prompt: string;
  title: string;
  desc: string;
  slug: MessageActionSlug;
  placeholder!: string;

  constructor(dto: MessageActionDto) {
    this.__dto = dto;

    this.args = this.__dto.args;
    this.func = this.__dto.func;
    this.icon = this.__dto.icon;
    this.prompt = this.__dto.prompt;
    this.title = this.__dto.title;
    this.desc = this.__dto.desc;
    this.slug = this.__dto.slug;
  }
}
