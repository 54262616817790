import { SocialNetwork } from '@bupple/core';

export interface MakePublishableResponseDto {
  publishable: PublishableDto;
  publishable_ready: boolean;
}

export interface PublishableDto {
  _id: string;
  chat_id: string;
  ideaboard_id: string;
  captions: PostCaptionsDto;
  hashtags: PostHashtagsDto;
  updated_at: string;
  created_at: string;
  media: PostMediaDto[];
  available_posts: AvailablePostDto[];
}

export interface PostCaptionsDto {
  instagram: string;
  linkedin: string;
  facebook: string;
  tiktok: string;
  twitter: string;
  general: string;
}

export interface PostHashtagsDto {
  array: PostHashtagDto[];
  string: string;
  word: string;
}

export interface PostHashtagDto {
  hashtag: string;
  popularity: number;
}

export interface PostMediaDto {
  _id: string;
  publishable_id: string;
  media_id: string;
  updated_at: string;
  created_t: string;
  ui: PostMediaUi;
}

export type PostMediaUi = PostMediaVideoUiDto | PostMediaTextUiDto;

export interface PostMediaVideoUiDto {
  type: 'vid/img';
  thumbnail: string;
}

export interface PostMediaTextUiDto {
  type: 'txt';
  color: string;
  icon: string;
  icon_full_path: string;
}

export enum PostMediaTypeDto {
  video = 'vid',
  image = 'img',
  text = 'txt',
}

export interface AvailablePostDto {
  _id: string;
  publishable_id: string;
  nw: SocialNetwork;
  title: string;
  type: PostMediaTypeDto;
  caption: string;
  hashtags: string;
  selected_media: AvailablePostMediaDto[];
  account_id: string;
  scheduled_for: string;
  published: boolean;
  updated_at: string;
  created_at: string;
}

export interface AvailablePostMediaDto {
  media_id: string;
  selected: boolean;
}
