<div
  class="scene-card"
  [ngClass]="{ selected: scene.selected }"
  (click)="selectScene.emit($event)"
>
  <p class="text-sm">{{ scene && scene.name }}</p>
  <div class="flex pt-4">
    <lib-button
      square
      color="neutral"
      size="sm"
      appearance="outline"
      (clickEvent)="clickDelete.emit($event)"
      [loading]="scene.isDeleting"
    >
      <bupple-icon
        name="Outline / Essentional, UI / Trash Bin Trash"
      ></bupple-icon>
    </lib-button>
  </div>
</div>
