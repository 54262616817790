import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ServerErrorType } from '../enums/server-error-type.enum';
import { JsonHelper } from '../helpers/json.helper';
import { ServerError } from '../interfaces/server-error.interface';
import { AuthService } from './auth.service';
import { PayWallService } from './pay-wall.service';
import { ToasterService } from './toaster.service';

@Injectable({
  providedIn: 'root',
})
export class ServerErrorHandlerService {
  constructor(
    private toaster: ToasterService,
    private authService: AuthService,
    private paywallService: PayWallService,
    private jsonHelper: JsonHelper,
  ) {}

  handleError(error: HttpErrorResponse): void {
    const serverError = this.jsonHelper.isJSON(error.error)
      ? JSON.parse(error.error)
      : null;

    if (!serverError) {
      return;
    }

    const errorHandler = this.errorHandlerMap.get(serverError.status);
    errorHandler && errorHandler(serverError);
  }

  private errorHandlerMap = new Map([
    [401, this.handler401Errors.bind(this)],
    [403, this.handler403Errors.bind(this)],
    [500, this.handler500Errors.bind(this)],
  ]);

  private handler401Errors(error: ServerError) {
    error.is_presentable && this.toaster.error(error.message);
    this.authService.logoutOnClient();
  }

  private handler403Errors(error: ServerError) {
    if (error.type === ServerErrorType.insufficientCreditError) {
      this.paywallService.showContinuePremiumOverlay();
    }
  }

  private handler500Errors() {
    this.toaster.error('An unknown error occurred. Please try again.');
  }
}
