import { Route } from '@angular/router';
import { PATH, TITLE, UnauthorizedGuard } from '@bupple/core';

export const authFeatureSignUpRoutes: Route = {
  path: PATH.signUp,
  title: TITLE.signUp,
  canActivate: [UnauthorizedGuard],
  loadComponent: () =>
    import('./auth-feature-sign-up/auth-feature-sign-up.component').then(
      (m) => m.AuthFeatureSignUpComponent,
    ),
};
