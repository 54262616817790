import { Injectable } from '@angular/core';
import * as store from 'store';
import * as plugins from 'store/plugins/all';
import { Token } from '../enums/token.enum';

@Injectable({
  providedIn: 'root',
})
export class StorageService {
  storage = store;

  constructor() {
    this.storage.addPlugin(plugins);
  }

  get token(): string | null {
    return this.storage.get(Token.accessToken) || null;
  }

  get refreshToken(): string | null {
    return this.storage.get(Token.refreshToken) || null;
  }

  setToken(token: string): void {
    this.storage.set(Token.accessToken, token);
  }

  setRefreshToken(token: string): void {
    this.storage.set(Token.refreshToken, token);
  }

  clearAll(): void {
    this.storage.clearAll();
  }
}
