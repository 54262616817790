import { Injectable } from '@angular/core';
import { HttpClientAdaptor } from '@bupple/util/adaptors';

@Injectable()
export class PublishApi {
  constructor(private http: HttpClientAdaptor) {}
  private endpointHead = 'publish/';

  publishPost(
    platform: string,
    accountId: string,
    postId: string,
    dateTime?: string,
  ) {
    return this.http.post(
      this.endpointHead + `${platform}/${accountId}/${postId}`,
      dateTime ? { scheduled_for: dateTime } : {},
    );
  }
}
