import { Injectable } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { filter, map, tap } from 'rxjs';
import { PARAMS } from '../constants/route-path.constants';

@Injectable({
  providedIn: 'root',
})
export class RouteService {
  private appParams: any;
  constructor() {
    this.appParams = PARAMS;
  }

  listenIdeaobardId(route: ActivatedRoute) {
    return route.params.pipe(
      tap((params) => {
        console.log(params);
      }),
      filter((params) => params[this.appParams.ideaboardId]),
      map((params) => params[this.appParams.ideaboardId]),
    );
  }
}
