import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SentMessageDto } from '@bupple/studio/data-access';

@Component({
  selector: 'bupple-sent-chat-message-link',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './sent-chat-message-link.component.html',
  styleUrl: './sent-chat-message-link.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SentChatMessageLinkComponent {
  @Input() message!: SentMessageDto;
}
