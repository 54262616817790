<div class="pb-4">
  <p class="font-bold">Delete Message</p>
  <p class="text-sm text-base-content-secondary">Are you sure to delete?</p>
  <div class="flex gap-2 mt-8">
    <div class="grow">
      <lib-button
        block
        appearance="outline"
        (clickEvent)="bottomSheetRef.dismiss(false)"
      >
        Cancel
      </lib-button>
    </div>
    <div class="grow">
      <lib-button
        block
        color="error"
        (clickEvent)="bottomSheetRef.dismiss(true)"
      >
        Delete
      </lib-button>
    </div>
  </div>
</div>
