import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';

@Component({
  selector: 'bupple-add-card-title',
  standalone: true,
  imports: [CommonModule, MatIconModule],
  templateUrl: './add-card-title.component.html',
  styleUrls: ['./add-card-title.component.scss'],
})
export class AddCardTitleComponent {
  @Input({
    required: true,
  })
  title = '';
  @Input({
    required: true,
  })
  subtitle = '';
  @Input({
    required: true,
  })
  icon = '';
}
