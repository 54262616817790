import { HttpEvent } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { HttpClientAdaptor } from '@bupple/util/adaptors';
import { Observable } from 'rxjs';
import { ChatDto } from '../dtos/chat.dto';
import { FeedbackDto, FeedbackResultDto } from '../dtos/feedback.dto';
import {
  CreateMessageResultDto,
  SentMessageDto,
  UpdateChatMessageResultDto,
} from '../dtos/message.dto';
import { MessageType } from '../enums/message-type.enum';

@Injectable()
export class IdeaboardChatApi {
  constructor(private http: HttpClientAdaptor) {}

  private endpointHead = 'ideaboard/chat/';

  actions(type?: MessageType): Observable<any> {
    return this.http.get(
      this.endpointHead + 'actions' + (type ? '/' + type : ''),
    );
  }

  getAll(ideaboardId: string, page: number): Observable<ChatDto> {
    return this.http.get<ChatDto>(
      `${this.endpointHead}table/${ideaboardId}?page=${page}`,
    );
  }

  send(
    ideaboardId: string,
    data: FormData,
  ): Observable<
    | CreateMessageResultDto<SentMessageDto>
    | HttpEvent<CreateMessageResultDto<SentMessageDto>>
  > {
    const options = {
      reportProgress: true,
      observe: 'events',
    };

    return this.http.post<CreateMessageResultDto<SentMessageDto>>(
      this.endpointHead + 'send/' + ideaboardId,
      data,
      options,
    );
  }

  action(chatId: string, func: string) {
    const data = new FormData();
    data.append('func', func);

    return this.http.post<CreateMessageResultDto<SentMessageDto>>(
      this.endpointHead + 'btn/' + chatId,
      data,
    );
  }

  delete(messageId: string): Observable<any> {
    return this.http.post<Observable<any>>(
      this.endpointHead + `delete/${messageId}`,
      null,
    );
  }

  feedback(feedback: FeedbackDto): Observable<FeedbackResultDto> {
    return this.http.post<FeedbackResultDto>(
      this.endpointHead + `feedback`,
      feedback,
    );
  }

  updateCreative(chatId: string, scene: string) {
    return this.http.post<UpdateChatMessageResultDto>(
      this.endpointHead + 'update/creative/' + chatId,
      { scene },
    );
  }

  updateThumbnail(
    chatId: string,
    file: Blob,
  ): Observable<UpdateChatMessageResultDto> {
    const data = new FormData();
    data.append('thumbnail', file);

    return this.http.post<UpdateChatMessageResultDto>(
      this.endpointHead + 'update/thumbnail/' + chatId,
      data,
    );
  }
}
