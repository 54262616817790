<!-- Posts mobile Topbar start -->
<div class="min-h-20 w-full px-4 py-6 md:hidden">
  <div class="flex w-full justify-between items-center">
    <bupple-icon
      (click)="navigation.goHome()"
      className="w-4 h-4"
      name="Outline / Arrows / Alt Arrow Left"
    ></bupple-icon>
    <p class="grow text-center font-bold">Posts</p>
  </div>
</div>
<div role="tablist" class="tabs tabs-bordered pt-2 md:absolute right-4 top-0">
  <a
    role="tab"
    class="tab title-xs"
    routerLink="/studio/posts/pending"
    routerLinkActive="tab-active"
  >
    Pending
  </a>
  <a
    role="tab"
    class="tab title-xs"
    routerLink="/studio/posts/scheduled"
    routerLinkActive="tab-active"
  >
    <bupple-icon size="sm" name="Bold / Time / Calendar"></bupple-icon>
    <span>Scheduled</span>
  </a>
  <a
    role="tab"
    class="tab title-xs"
    routerLink="/studio/posts/published"
    routerLinkActive="tab-active"
    >Published</a
  >
</div>

<div class="p-page">
  <router-outlet></router-outlet>
</div>
