<input type="checkbox"
    [id]="id"
    class="modal-toggle" />
<div class="modal bg-base-content/50 z-50"
    role="dialog">
    <div class="group modal-box max-w-[40rem] p-1 md:p-2 !rounded-lg">
        <img [src]="imageUrl"
            width="100%"
            alt=""
            class="mb-2 rounded-md md:w-full md:h-full md:my-0 object-contain object-top" />
        <div
            class="modal-action md:mt-2 flex justify-between items-center px-3 py-4 absolute bottom-0 transition-all duration-300 md:opacity-50 group-hover:opacity-100 w-full md:w-[calc(100%_-_10px)] mx-auto bg-gradient-to-t from-base-100 via-base-100/90 to-base-100/0 from-10% via-75% to-100% bg-transparent">

            <div>
                <lib-button size="sm"
                    square
                    color="neutral"
                    appearance="tonal"
                    (clickEvent)="onClickDownloadImage()">
                    <bupple-icon size="sm"
                        name="Outline / Arrows Action / Download Minimalistic"></bupple-icon>
                </lib-button>
            </div>

            <label [for]="id"
                class="btn btn-primary btn-outline btn-sm sub-text-sm md:sub-text-l">Close</label>
        </div>
    </div>
    <label class="modal-backdrop bg-base-content/25 "
        [for]="id">Close</label>
</div>