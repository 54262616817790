import { Ideaboard, MessageAction, SocialNetwork } from '@bupple/core';
import { ListDto } from '@bupple/interfaces';
import { Action, createReducer, on } from '@ngrx/store';
import { StudioActions } from '../..';
import { CreateMessageDto, message } from '../dtos/message.dto';
import { PublishableDto } from '../dtos/post.dto';
import { MessageType } from '../enums/message-type.enum';
import * as studioActions from './studio.actions';

export const STUDIO_FEATURE_KEY = 'studio';

export interface StudioState {
  activeIdeaboard: Ideaboard | null;
  chatInputValue: string | null;
  chatMessage: CreateMessageDto | null;
  homeInputChatMessage: CreateMessageDto | null;
  activeIdeaboardChat: ListDto<message> | null;
  mockSentMessageId: string | null;
  mockReceivedMessageId: string | null;
  publishableId: string | null;
  publishable: PublishableDto | null;
  timeToScroll: number;
  isRecievingMessage: boolean;
  selectedAction: MessageAction | null;
  ideaboards: ListDto<Ideaboard> | null;
  posts: any[] | null;
  videoProgress: number | null;
  videoNotif: string | null;
  isPostPublished: boolean;
}

export const initialStudioState: StudioState = {
  activeIdeaboard: null,
  chatInputValue: null,
  chatMessage: null,
  homeInputChatMessage: null,
  activeIdeaboardChat: null,
  mockSentMessageId: null,
  mockReceivedMessageId: null,
  publishableId: null,
  publishable: null,
  timeToScroll: 0,
  isRecievingMessage: false,
  selectedAction: null,
  ideaboards: null,
  posts: null,
  videoProgress: null,
  videoNotif: null,
  isPostPublished: false,
};

const reducer = createReducer(
  initialStudioState,
  on(studioActions.reset, () => {
    return {
      ...initialStudioState,
    };
  }),
  on(studioActions.setActiveIdeaboard, (state, { ideaboard }) => {
    return {
      ...state,
      activeIdeaboard: ideaboard,
    };
  }),
  on(studioActions.resetActiveIdeaboard, (state) => ({
    ...state,
    activeIdeaboard: null,
    chatInputValue: null,
    chatMessage: null,
    activeIdeaboardChat: null,
    mockSentMessageId: null,
    mockReceivedMessageId: null,
    publishable: null,
    timeToScroll: 0,
    isRecievingMessage: false,
    selectedAction: null,
  })),
  on(studioActions.udpateIdeaboardName, (state, { name, id }) => {
    // Update Ideaboard List
    state = {
      ...state,
      ideaboards: {
        ...(state.ideaboards as ListDto<Ideaboard>),
        data: (state.ideaboards?.data as Ideaboard[]).map((i) => {
          if (i._id === id) {
            return { ...i, name: name };
          }
          return i;
        }),
      },
    };

    // Update Active Ideaboard
    if (id === state.activeIdeaboard?._id) {
      state = {
        ...state,
        activeIdeaboard: { ...state.activeIdeaboard, name: name },
      };
    }

    return state;
  }),
  on(studioActions.setChatMessage, (state, { value }) => ({
    ...state,
    chatMessage: value,
    isRecievingMessage: true,
  })),
  on(studioActions.setHomeInputChatMessage, (state, { value }) => ({
    ...state,
    homeInputChatMessage: value,
    isRecievingMessage: true,
  })),
  on(studioActions.setSelectedAction, (state, { action }) => ({
    ...state,
    selectedAction: action,
  })),
  on(studioActions.setActiveIdeaboardChat, (state, { chat }) => ({
    ...state,
    activeIdeaboardChat: chat,
  })),
  on(studioActions.prependNewChatMessages, (state, { chat }) => {
    const currentChatMessages = state.activeIdeaboardChat?.data;
    const newChatListIdeaboard =
      (chat?.data && chat?.data[0]?.ideaboard_id) || undefined;

    // skip if the recieved list is not for the active ideaboard
    if (newChatListIdeaboard !== state.activeIdeaboard?._id) {
      return state;
    }

    const newState = {
      ...state,
      activeIdeaboardChat: {
        ...chat,
        data: [...chat.data, ...(currentChatMessages as message[])],
      },
    } as StudioState;

    return newState;
  }),
  on(studioActions.setMockSentMessage, (state, { message }) => ({
    ...state,
    mockSentMessageId: message._id,
    activeIdeaboardChat: {
      ...state.activeIdeaboardChat,
      data: [...(state.activeIdeaboardChat?.data as message[]), message],
    } as ListDto<message>,
    timeToScroll: state.timeToScroll + 1,
  })),
  on(studioActions.removeMocklSentMessage, (state) => ({
    ...state,
    activeIdeaboardChat: {
      ...state.activeIdeaboardChat,
      data: (state.activeIdeaboardChat as ListDto<message>).data.filter(
        (m) => m._id !== state.mockSentMessageId,
      ),
    } as ListDto<message>,
    mockSentMessageId: null,
  })),
  on(studioActions.setLoadingAsAMessage, (state, { message }) => ({
    ...state,
    activeIdeaboardChat: {
      ...state.activeIdeaboardChat,
      data: [...(state.activeIdeaboardChat?.data as message[]), message],
    } as ListDto<message>,
    isRecievingMessage: true,
  })),
  on(studioActions.removeLoadingMessage, (state) => ({
    ...state,
    activeIdeaboardChat: {
      ...state.activeIdeaboardChat,
      data: (state.activeIdeaboardChat as ListDto<message>)?.data.filter(
        (m) => m.type !== MessageType.loading,
      ) as message[],
    } as ListDto<message>,
  })),
  on(studioActions.setRealSentMessage, (state, { message }) => {
    if (message.ideaboard_id !== state.activeIdeaboard?._id) {
      return state;
    }

    return {
      ...state,
      activeIdeaboardChat: {
        ...state.activeIdeaboardChat,
        data: (state.activeIdeaboardChat as ListDto<message>).data.map((m) =>
          m._id === state.mockSentMessageId ? message : m,
        ),
      } as ListDto<message>,
      mockSentMessageId: null,
      timeToScroll: state.timeToScroll + 1,
    };
  }),
  on(studioActions.setMockReceivedMessage, (state, { message }) => {
    return state.mockReceivedMessageId
      ? state
      : {
          ...state,
          mockReceivedMessageId: message._id,
          activeIdeaboardChat: {
            ...state.activeIdeaboardChat,
            data: [...(state.activeIdeaboardChat?.data as message[]), message],
          } as ListDto<message>,
          recievingMessage: true,
          isRecievingMessage: true,
        };
  }),
  on(studioActions.udpateMockReceivedMessageType, (state, { messageType }) => ({
    ...state,
    activeIdeaboardChat: {
      ...state.activeIdeaboardChat,
      data: (state.activeIdeaboardChat as ListDto<message>).data.map((m) =>
        m._id === state.mockReceivedMessageId ? { ...m, type: messageType } : m,
      ),
    } as ListDto<message>,
  })),
  on(studioActions.udpateMockReceivedMessageContent, (state, { content }) => ({
    ...state,
    activeIdeaboardChat: {
      ...state.activeIdeaboardChat,
      data: (state.activeIdeaboardChat as ListDto<message>).data.map((m) =>
        m._id === state.mockReceivedMessageId
          ? { ...m, content: m.content + content, inProgress: false }
          : m,
      ),
    } as ListDto<message>,
  })),
  on(studioActions.setRealReceivedMessage, (state, { message }) => {
    if (message.ideaboard_id !== state.activeIdeaboard?._id) {
      return state;
    }

    // refactor: quick solution for duplicated recieved message
    const thisMessageIsAlreadyInTheChat = state.activeIdeaboardChat?.data.some(
      (m) => m._id === message._id,
    );
    if (thisMessageIsAlreadyInTheChat) {
      return state;
    }

    if (state.mockReceivedMessageId) {
      state = {
        ...state,
        activeIdeaboardChat: {
          ...state.activeIdeaboardChat,
          data: (state.activeIdeaboardChat as ListDto<message>).data.map((m) =>
            m._id === state.mockReceivedMessageId ? message : m,
          ),
        } as ListDto<message>,
        mockReceivedMessageId: null,
      };
    } else {
      state = {
        ...state,
        activeIdeaboardChat: {
          ...state.activeIdeaboardChat,
          data: [
            ...(state.activeIdeaboardChat as ListDto<message>).data,
            message,
          ],
        } as ListDto<message>,
      };
    }
    return {
      ...state,
      isRecievingMessage: false,
      timeToScroll: state.timeToScroll + 1,
    };
  }),
  on(studioActions.updateChatMessage, (state, { message }) => ({
    ...state,
    activeIdeaboardChat: {
      ...state.activeIdeaboardChat,
      data: (state.activeIdeaboardChat as ListDto<message>).data.map((m) =>
        m._id === message._id ? message : m,
      ),
    } as ListDto<message>,
  })),
  on(studioActions.setPublishableId, (state, { id }) => ({
    ...state,
    publishableId: id,
  })),
  on(studioActions.setPublishable, (state, { publishable: publishable }) => ({
    ...state,
    publishable: publishable,
  })),
  on(studioActions.updateAvailablePost, (state, { availablePost }) => ({
    ...state,
    publishable: {
      ...state.publishable,
      available_posts: state.publishable?.available_posts.map((p) =>
        p._id === availablePost._id ? availablePost : p,
      ),
    } as PublishableDto,
  })),
  on(StudioActions.resetPublishable, (state) => ({
    ...state,
    publishableId: null,
    publishable: null,
  })),
  on(studioActions.deleteMessage, (state, { messageId }) => ({
    ...state,
    activeIdeaboardChat: {
      ...state.activeIdeaboardChat,
      data: (state.activeIdeaboardChat as ListDto<message>).data.filter(
        (m) => m._id !== messageId,
      ),
    } as ListDto<message>,
  })),
  on(studioActions.setIdeaboards, (state, { ideaboards }) => {
    const currentIdeaboardsList = (state.ideaboards?.data as Ideaboard[]) || [];
    const newIdeaboardsList = [...currentIdeaboardsList, ...ideaboards.data];

    const newState = {
      ...state,
      ideaboards: {
        ...ideaboards,
        data: newIdeaboardsList,
      },
    };

    return newState;
  }),
  on(studioActions.addNewIdeaboard, (state, { ideaboard }) => ({
    ...state,
    ideaboards: {
      ...(state.ideaboards as ListDto<Ideaboard>),
      data: [ideaboard, ...(state.ideaboards?.data as Ideaboard[])],
    },
  })),
  on(studioActions.removeIdeaboard, (state, { ideaboardId }) => ({
    ...state,
    ideaboards: {
      ...(state.ideaboards as ListDto<Ideaboard>),
      data: (state.ideaboards as ListDto<Ideaboard>).data.filter(
        (i) => i._id !== ideaboardId,
      ),
    },
    activeIdeaboard:
      state.activeIdeaboard?._id === ideaboardId ? null : state.activeIdeaboard,
  })),
  on(studioActions.setIsReceivingMessage, (state, { value }) => ({
    ...state,
    isRecievingMessage: value,
  })),
  on(studioActions.SetPosts, (state, { posts }) => ({
    ...state,
    posts: posts,
  })),
  on(studioActions.setVideoProgress, (state, { progress }) => ({
    ...state,
    videoProgress: progress,
  })),
  on(studioActions.setVideoNotif, (state, { text }) => ({
    ...state,
    videoNotif: text,
  })),
  on(studioActions.setIsPostPublished, (state, { value }) => ({
    ...state,
    isPostPublished: value,
  })),
);

export function studioReducer(state: StudioState | undefined, action: Action) {
  return reducer(state, action);
}
