import { PATH } from '@bupple/core';
import { MenuItem } from '@bupple/interfaces';
import { v4 } from 'uuid';

export const MAIN_PAGES_MENU: MenuItem[] = [
  {
    id: v4(),
    title: 'HOME',
    icon: 'Outline / Essentional, UI / Home Smile Angle',
    iconActive: 'Bold / Essentional, UI / Home Smile Angle',
    link: `/${PATH.studio}`,
    active: true,
  },
  {
    id: v4(),
    title: 'IDEABOARDS',
    icon: 'Outline / Notes / Clipboard Heart',
    iconActive: 'Bold / Notes / Clipboard Heart',
    link: `/${PATH.studio}/${PATH.ideaboards}`,
    active: false,
  },
  {
    id: v4(),
    title: 'PREMIUM',
    icon: 'Outline / Essentional, UI / Crown Star',
    iconActive: 'Bold / Essentional, UI / Crown Star',
    link: `/${PATH.studio}/${PATH.plans}`,
    active: false,
  },
];
