import { Environment } from '@bupple/interfaces';

const endpointBase = 'https://api.bupple.io';
export const environment: Environment = {
  prod: true,
  api: endpointBase + '/api',
  origin: endpointBase,
  imgly: {
    license: '57lYy0HAiWqKDoeeuRmONsIIkPIjH2_7ZVrNt2m8REjOwTGU5C0B8i1jw7cLXLlL',
    dsn: 'https://0b00e7f34404e796c5bb0d399290ab1b@o4507389518282752.ingest.us.sentry.io/4507905543569408',
  },
  pusher: {
    authEndpoint: endpointBase + '/broadcasting/auth',
    app_id: '1751832',
    key: 'b99fd1f4f7032f61070a',
    secret: '2def1dc2678c00c6ce34',
    cluster: 'mt1',
  },
};
