export enum ServerErrorType {
  validationError = 'validation_error',
  policyError = 'policy_error',
  genericError = 'generic_error',
  httpError = 'http_error',
  authError = 'auth_error',
  engineError = 'engine_error',
  serverError = 'server_error',
  insufficientCreditError = 'insufficient_credit_error',
}
