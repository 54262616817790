import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  HostBinding,
  Input,
} from '@angular/core';
import { UiIconComponent } from '@bupple/ui/icon';

@Component({
  selector: 'lib-promotion-banner',
  standalone: true,
  imports: [CommonModule, UiIconComponent],
  templateUrl: './promotion-banner.component.html',
  styleUrl: './promotion-banner.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PromotionBannerComponent {
  @Input() background = '';
  @Input() title = '';
  @Input() subtitle = '';
  @Input() image = 'assets/images/banner-images/bomb.png';
  @Input() imgStatus = '';
  @Input() icon = '';
  @HostBinding() class = 'h-full w-full relative';
}
