<div
  class="flex items-center bg-base-500 hover:bg-base-400 rounded-lg p-3 gap-2 min-h-inherit relative"
  [ngClass]="{ 'rounded-xl': creditSide }"
  (click)="onClick($event)"
>
  <span
    class="w-10 min-h-inherit md:w-9 md:h-9 flex-none rounded-md bg-base-content/8 flex items-center justify-center bg-gradient-to-t to-[#61c0fc14] from-[#8443ee33] mask md:sq-mask"
  >
    <bupple-icon [name]="action.icon || ''"></bupple-icon>
  </span>
  <div class="space-y-0.5 md:space-y-1.5 grow min-h-inherit">
    <div
      class="w-full flex justify-between items-center pr-12"
      [ngClass]="
        creditSide ? 'pr-6 md:pr-6 min-h-inherit flex items-center' : 'relative'
      "
    >
      <p
        class="title-sm text-base-content tracking-[0.5px] md:title-xs"
        [ngClass]="{ 'title-xs': creditSide }"
      >
        {{ action.title }}
      </p>
      <lib-credit-badge
        class="sub-text-l absolute right-0 md:top-0 md:-translate-y-0"
        [ngClass]="
          creditSide ? 'top-0 -translate-y-0' : 'top-1/2 -translate-y-1/2'
        "
        [side]="creditSide"
        [icon]="
          action.title === actionEnums.Ideas
            ? 'Bold / Star'
            : action.title === actionEnums.Script
              ? 'Bold / Star'
              : action.title === actionEnums.Image
                ? 'Bold / Two Stars'
                : action.title === actionEnums.Video
                  ? 'Bold / Star'
                  : ''
        "
        size="free"
        [circle]="
          action.title === actionEnums.Ideas ||
          action.title === actionEnums.Script
        "
      >
        @if (action.title === actionEnums.Video) {
          <span class="sub-text-md leading-none">3</span>
        }
      </lib-credit-badge>
    </div>
    <p class="text-base-content-secondary sub-text-sm" *ngIf="hasDes">
      {{ action.desc }}
    </p>
  </div>
</div>
