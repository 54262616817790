import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { RouterModule } from '@angular/router';
import { CoreFacade, GetUserDto, NavigationService } from '@bupple/core';
import { ListAllPostsDto } from '@bupple/interfaces';
import { PostService } from '@bupple/studio/data-access';
import { UiIconComponent } from '@bupple/ui/icon';
import { SubSink } from 'subsink';

@Component({
  selector: 'lib-feature-posts',
  standalone: true,
  imports: [CommonModule, RouterModule, UiIconComponent],
  templateUrl: './feature-posts.component.html',
  styleUrl: './feature-posts.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FeaturePostsComponent implements OnInit {
  constructor(
    protected navigation: NavigationService,
    private coreFacade: CoreFacade,
    private postService: PostService,
  ) {}
  ngOnInit(): void {
    this.listenStore();
    this.getPublishedSchedule();
  }
  subsink = new SubSink();
  user!: GetUserDto | null;
  listenStore() {
    this.subsink.sink = this.coreFacade.user$.subscribe((user) => {
      this.user = user;
    });
  }
  allPosts: ListAllPostsDto | undefined;
  getPublishedSchedule() {
    if (this.user) {
      this.postService.getAll(this.user._id).subscribe((result) => {
        this.allPosts = result;
      });
    }
  }
}
