<div>
  <div class="mb-2">
    <div class="skeleton bg-base-content/8 h-3 w-20"></div>
  </div>
  <div>
    <div class="flex mb-2 gap-1">
      <div class="skeleton bg-base-content/8 h-5 w-14"></div>
      <div class="skeleton bg-base-content/8 h-5 w-14"></div>
      <div class="skeleton bg-base-content/8 h-5 w-14"></div>
      <div class="skeleton bg-base-content/8 h-5 w-14"></div>
    </div>
  </div>
</div>
