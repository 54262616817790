<div class="h-52 flex flex-col justify-end relative">
  <span class="px-2 bg-base-content rounded-xl absolute top-0 right-0">
    <bupple-icon size="sm"
      name="Bold / Video, Audio, Sound / Clapperboard Play"
      color="base-100"></bupple-icon>
  </span>
  <div class="space-y-2 text-center w-full">
    <span
      class="px-2 py-1 bg-base-content/8 border border-base-content/4 text-base-content-secondary sub-text-l rounded-xl">{{
      progressPercent }}%</span>
    <p class="text-base-content-secondary sub-text-l max-w-56 mx-auto">
      {{ progressNotif }}
    </p>
    <progress class="progress progress-success w-56"
      [value]="progressPercent"
      max="100"></progress>
    <br />
  </div>
</div>