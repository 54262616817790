<swiper-container fmSwiper
  [config]="configSwiperMain"
  init="false"
  class="swiper-container z-0">
  <swiper-slide class="flex justify-center"
    *ngFor="let video of tipVideoUrls;  let i = index">
    <div class="w-[500px] ">
      <div class="plyr__video-embed"
        [id]="'player' + i">
        <iframe [src]="sanitizer.bypassSecurityTrustResourceUrl(video.url)"
          allowfullscreen
          allowtransparency
          allow="autoplay"></iframe>
      </div>
    </div>
    <!-- 
    <bupple-video-tips [videoWidth]="360"
      [videoHeight]="200"
      [videoUrl]="video.url"
      [title]="video.title"></bupple-video-tips> -->
  </swiper-slide>
</swiper-container>
<div class="swiper-scrollbar"></div>