import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { KatexOptions, MarkdownComponent, provideMarkdown } from 'ngx-markdown';
import { CustomMarkdownService } from '@bupple/core';
import {
  IdeaboardChatService,
  ReceivedMessageDto,
} from '@bupple/studio/data-access';
@Component({
  selector: 'bupple-received-chat-message-text',
  standalone: true,
  imports: [CommonModule, MarkdownComponent],
  providers: [provideMarkdown(CustomMarkdownService.getProvider())],
  templateUrl: './received-chat-message-text.component.html',
  styleUrl: './received-chat-message-text.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ReceivedChatMessageTextComponent {
  constructor(private ideaboardChatService: IdeaboardChatService) {}
  @Input() recievedMessage!: ReceivedMessageDto;

  public options: KatexOptions = {
    displayMode: true,
    throwOnError: true,
    errorColor: '#cc0000',
  };
}
