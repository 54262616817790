<div class="p-page">
  <div
    class="bg-base-500 w-[calc(100vw_-_64px)] h-[calc(100vw_-_64px)] rounded-lg flex items-center justify-center mb-8"
  >
    <mat-icon class="text-base-content scale-[2.7]">photo</mat-icon>
  </div>
  <h2 class="mb-4">Connect Accounts to using awesome features</h2>
  <p class="mb-10">
    To prepare your feed and awesome features.To prepare your feed and awesome
    features.To prepare your feed and awesome features.
  </p>
  <lib-button block class="mb-3" (clickEvent)="navigation.goConnectAccounts()">
    Connect Accounts
  </lib-button>
  <lib-button (clickEvent)="navigation.goHome()">Skip</lib-button>
</div>
