import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { ImageModalComponent } from '@bupple/image-modal';
import {
  IdeaboardChatService,
  ReceivedMessageDto,
  message,
} from '@bupple/studio/data-access';
import { UiIconComponent } from '@bupple/ui/icon';

@Component({
  selector: 'bupple-received-chat-message-image',
  standalone: true,
  imports: [CommonModule, UiIconComponent, ImageModalComponent],
  templateUrl: './received-chat-message-image.component.html',
  styleUrls: ['./received-chat-message-image.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ReceivedChatMessageImageComponent {
  constructor(private ideaboardChatService: IdeaboardChatService) {}
  @Input({ required: true }) set message(value: message) {
    this.receivedMessage = value as ReceivedMessageDto;
    console.log('recived-message', this.receivedMessage);
  }
  receivedMessage?: ReceivedMessageDto;
}
