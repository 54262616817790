import { CommonModule, NgIf } from '@angular/common';
import { HttpEvent, HttpEventType } from '@angular/common/http';
import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  Output,
  ViewChild,
} from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { MessageType } from '@bupple/studio/data-access';
import { BehaviorSubject } from 'rxjs';
import { SubSink } from 'subsink';
import { DeepReadonly } from 'ts-essentials';
import { SendChatMessage } from '../interfaces/send-message.interface';
import { UploaderConfig } from '../interfaces/uploader-config.interface';
import { ImageMessageService } from '../services/image-message.service';
import { VideoMessageService } from '../services/video-message.service';

@Component({
  selector: 'bupple-chat-uploader',
  standalone: true,
  imports: [CommonModule, MatIconModule, NgIf],
  templateUrl: './chat-uploader.component.html',
  styleUrls: ['./chat-uploader.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ChatUploaderComponent implements OnDestroy {
  constructor(
    private sendImageMessageService: ImageMessageService,
    private sendVideoMessageService: VideoMessageService,
  ) {}
  @ViewChild('fileInput') fileInput?: ElementRef<HTMLInputElement>;
  @Input({ required: true })
  config!: DeepReadonly<UploaderConfig>;
  @Output() selectFile: EventEmitter<DeepReadonly<File>> = new EventEmitter();
  @Output() completeUpload: EventEmitter<DeepReadonly<unknown>> =
    new EventEmitter();
  @Output() errorUpload: EventEmitter<Error> = new EventEmitter();
  inputAcceptMap = new Map([
    [
      MessageType.image,
      {
        accept: 'image/*',
        regex: /^image\//,
      } as any,
    ],
    [
      MessageType.video,
      {
        accept: 'video/*',
        regex: /^video\//,
      },
    ],
    [
      MessageType.sound,
      {
        accept: 'audio/*',
        regex: /^audio\//,
      },
    ],
  ]);

  subsink = new SubSink();
  browseFiles(): void {
    this.fileInput && this.fileInput.nativeElement.click();
  }

  file?: File | null;
  changeFile(event: any): void {
    const files: FileList = event.type != 'change' ? event : event.target.files;
    this.file = files && files[0] ? files[0] : null;

    if (this.file) {
      this.selectFile.emit(this.file);
      this.config = {
        ...this.config,
        model: { ...this.config.model, file: this.file as File },
      };
      try {
        this.runSizeValidation(this.file);
        this.runTypeValidation(this.file);
        this.upload();
      } catch (error) {
        throw new Error((error as Error).message);
      }
    }
  }

  upload(): void {
    this.uploadService
      .send(this.config.ideaboardId, this.config.model)
      .subscribe({
        next: (response) => this.handleUpdate(response as unknown),
        error: (error) => this.handleError(error),
      });
  }

  get uploadService(): SendChatMessage {
    return this.config.model.type === MessageType.image
      ? this.sendImageMessageService
      : this.sendVideoMessageService;
  }

  oneMeg = 1048576;
  runSizeValidation(file: File): void {
    const sizeValidated = (this.config as UploaderConfig).maxSize
      ? (this.config as UploaderConfig).maxSize >= file.size / this.oneMeg
      : true;

    if (!sizeValidated) {
      throw new Error('Selected file is larger than allowed');
    }
  }

  runTypeValidation(file: File): void {
    const typeValidated = this.inputAcceptMap.get(
      (this.config as UploaderConfig).model?.type,
    ).regex
      ? this.inputAcceptMap
          .get(this.config?.model?.type as MessageType)
          .regex.test(file.type)
      : true;

    if (!typeValidated) {
      throw new Error("Selected file's type is not allowed");
    }
  }

  uploadPercentage$ = new BehaviorSubject(0);
  handleUpdate(event: unknown): void {
    const e: HttpEvent<unknown> = event as HttpEvent<unknown>;
    switch (e?.type) {
      case HttpEventType.Response:
        this.onCompleted(e.body);
        break;
      case HttpEventType.UploadProgress:
        this.uploadPercentage$.next(
          Math.round((100 * e.loaded) / (e.total as number)),
        );
        break;
    }
  }

  handleError(error: Error): void {
    this.uploadPercentage$.next(0);
    this.errorUpload.emit(error);
  }

  onCompleted(result: unknown): void {
    this.uploadPercentage$.next(0);
    this.completeUpload.emit(result);
  }

  ngOnDestroy(): void {
    this.subsink.unsubscribe();
  }
}
