<label [for]="receivedMessage?._id">
  <img [src]="receivedMessage?.file_full_path"
    width="100%"
    alt=""
    class="mb-2 rounded-t-xl w-[360px] h-[360px] max-sm:w-[298px] max-sm:h-[298px] max-w-full object-cover md:my-0" />
</label>

<!-- Put this part before </body> tag -->
<!-- <input type="checkbox"
  [id]="receivedMessage?._id"
  class="modal-toggle" />
<div class="modal bg-base-content/50 z-50"
  role="dialog">
  <div class="modal-box max-w-[40rem] p-1 md:p-2">
    <img [src]="receivedMessage?.file_full_path"
      width="100%"
      alt=""
      class="mb-2 rounded-t-xl md:w-full md:h-full md:my-0 object-contain object-top" />
    <div class="modal-action md:mt-2">
      <label [for]="receivedMessage?._id"
        class="btn btn-primary btn-outline">Close</label>
    </div>
  </div>
  <label class="modal-backdrop bg-base-100/25"
    [for]="receivedMessage?._id">Close</label>
</div> -->

<!-- Modal -->
<lib-image-modal [id]="receivedMessage?._id"
  [imageUrl]="receivedMessage?.file_full_path"></lib-image-modal>