import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MarkdownComponent, provideMarkdown } from 'ngx-markdown';
import { CustomMarkdownService } from '@bupple/core';
import { SentMessageDto } from '@bupple/studio/data-access';

@Component({
  selector: 'bupple-sent-chat-message-text',
  standalone: true,
  imports: [CommonModule, MarkdownComponent],
  providers: [provideMarkdown(CustomMarkdownService.getProvider())],
  templateUrl: './sent-chat-message-text.component.html',
  styleUrl: './sent-chat-message-text.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SentChatMessageTextComponent {
  @Input() message!: SentMessageDto;
}
