import { ElementRef, Injectable } from '@angular/core';
import { gsap } from 'gsap';
import {
  Application,
  Container,
  Graphics,
  Sprite,
  Text,
  Texture,
} from 'pixi.js';
import { SegmentLine, SubtitleWord } from '../interfaces/subtitle.interface';
import { TrackVideoItem } from '../interfaces/timeline-track-item.interface';

@Injectable()
export class PixiService {
  async setupPixi(viewRef: ElementRef): Promise<Application> {
    const pixi = new Application();
    await pixi.init({
      width: 429,
      height: 767,
      backgroundColor: 0x000000,
    });
    viewRef.nativeElement.appendChild(pixi.canvas);
    pixi.stage.sortableChildren = true;

    return pixi;
  }

  getContainer(): Container {
    return new Container();
  }

  addContainerToPixi(container: Container, pixi: Application) {
    pixi.stage.addChild(container);
  }

  getVideoTexture(video: HTMLVideoElement) {
    return Texture.from(video);
  }

  getHtmlVideoElement(
    track: TrackVideoItem,
    pixi: Application,
  ): HTMLVideoElement {
    const video = document.createElement('video');
    video.src = track.asset.video_url;
    video.width = pixi.screen.width;
    video.height = pixi.screen.height;
    video.crossOrigin = 'anonymous';
    video.autoplay = false;
    video.muted = true;
    video.loop = false;
    video.preload = 'auto';
    video.pause();
    return video;
  }

  getVideoSpirit(texture: Texture, pixi: Application): Sprite {
    const spirit = new Sprite(texture);
    spirit.width = pixi.screen.width;
    spirit.height = pixi.screen.height;
    spirit.zIndex = 0;
    return spirit;
  }

  textStyle = {
    fontFamily: 'Arial',
    fontSize: 30,
    fill: '#ffffff',
    stroke: '#000000',
    align: 'center',
  };

  generateLineContainersBySubtitle(
    segmentLines: SegmentLine[],
    pixi: Application,
  ): Container[] {
    const lineContainers: Container[] = [];

    segmentLines.forEach((line) => {
      const lineContainer: any = new Container();

      lineContainer.startTime = line.start;
      lineContainer.endTime = line.end;

      let totalLineWidth = 0;
      const wordObjects: any[] = [];
      line.words.forEach((word: SubtitleWord) => {
        const wordText: any = new Text({
          text: word.word,
          style: this.textStyle as any,
        });

        totalLineWidth += wordText.width + 10;

        wordText.startTime = word.start;
        wordText.endTime = word.end;

        wordObjects.push(wordText);
      });

      let currentX = -totalLineWidth / 2 + 5;
      wordObjects.forEach((wordText) => {
        wordText.anchor.set(0, 0.5);
        wordText.x = currentX;
        wordText.y = 0;
        currentX += wordText.width + 10;
        lineContainer.addChild(wordText);
      });

      const highlight = new Graphics();
      highlight.visible = false;
      lineContainer.addChildAt(highlight, 0);

      lineContainer.highlightData = {
        x: 0,
        y: 0,
        width: 0,
        height: 0,
        radius: 10,
      };

      const subtitleMarginBottom = 100;
      lineContainer.x = pixi.screen.width / 2;
      lineContainer.y = pixi.screen.height - subtitleMarginBottom;
      lineContainer.wordObjects = wordObjects;
      lineContainer.highlight = highlight;
      lineContainer.activeWordText = null;

      lineContainers.push(lineContainer);
    });

    return lineContainers;
  }

  showLineContainer(lineContainer: any, currentTime: number) {
    // Make sure the line is visible
    lineContainer.visible = true;

    // Make sure all words are visible
    lineContainer.wordObjects.forEach((wordText: any) => {
      if (!wordText.visible) {
        wordText.visible = true;
        wordText.alpha = 1;
      }
    });

    // Find the active word
    let activeWordText: any = null;
    lineContainer.wordObjects.forEach((wordText: any) => {
      if (
        currentTime >= wordText.startTime &&
        currentTime <= wordText.endTime
      ) {
        activeWordText = wordText;
      }
    });

    if (activeWordText) {
      // If the active word has changed, animate the highlight
      if (lineContainer.activeWordText !== activeWordText) {
        lineContainer.activeWordText = activeWordText;

        const padding = 5;

        // Prepare the target properties for the highlight rectangle
        const targetX = activeWordText.x - padding;
        const targetY = activeWordText.y - activeWordText.height / 2 - padding;
        const targetWidth = activeWordText.width + 2 * padding;
        const targetHeight = activeWordText.height + 2 * padding;

        // Make the highlight visible
        lineContainer.highlight.visible = true;

        // Animate the highlight properties
        gsap.to(lineContainer.highlightData, {
          duration: 0.1,
          x: targetX,
          y: targetY,
          width: targetWidth,
          height: targetHeight,
          ease: 'power2.out',
          onUpdate: () => {
            // Clear and redraw the highlight rectangle
            lineContainer.highlight.clear();
            lineContainer.highlight.fill(0x800080); // Purple color
            lineContainer.highlight.roundRect(
              lineContainer.highlightData.x,
              lineContainer.highlightData.y,
              lineContainer.highlightData.width,
              lineContainer.highlightData.height,
              lineContainer.highlightData.radius,
            );
            lineContainer.highlight.fill();
          },
        });
      }
    } else {
      // No active word, hide the highlight
      lineContainer.activeWordText = null;
      lineContainer.highlight.visible = false;
      lineContainer.highlight.clear();
    }
  }

  hideLineContainer(lineContainer: any) {
    // Line is not active, hide the line
    lineContainer.visible = false;

    // Hide all words
    lineContainer.wordObjects.forEach((wordText: any) => {
      wordText.visible = false;
      wordText.alpha = 0;
    });

    // Hide the highlight
    lineContainer.activeWordText = null;
    lineContainer.highlight.visible = false;
    lineContainer.highlight.clear();
  }
}
