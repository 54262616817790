/**
 * @author Ronak Patel
 * @description This pipe create for find difference between two date.
 */

import { Pipe, PipeTransform } from '@angular/core';
import { DateDifferencePipe } from './time-difference.pipe';

@Pipe({
  name: 'updatedAt',
  pure: true,
  standalone: true,
})
export class UpdatedAtPipe implements PipeTransform {
  transform(startingDate: string | undefined): any {
    const difference = new DateDifferencePipe().transform(startingDate);

    return difference < 1
      ? 'Today'
      : difference < 2
      ? difference + ' Day'
      : difference + ' Days';
  }
}
