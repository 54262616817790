import { CommonModule } from '@angular/common';
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  CUSTOM_ELEMENTS_SCHEMA,
} from '@angular/core';
import { SwiperDirective } from '@bupple/util/directives';
import { TIP_VIDEO_URLS } from '../constants/videoUrls.constant';
import { VideoTipsComponent } from '../video-tips/video-tips.component';

import { SwiperOptions } from 'swiper/types';
import * as Plyr from 'plyr';
import { DomSanitizer } from '@angular/platform-browser';
@Component({
  selector: 'bupple-tutorial',
  standalone: true,
  imports: [CommonModule, VideoTipsComponent, SwiperDirective],
  templateUrl: './tutorial.component.html',
  styleUrl: './tutorial.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class TutorialComponent implements AfterViewInit {
  constructor(public sanitizer: DomSanitizer) {}

  tipVideoUrls = TIP_VIDEO_URLS;

  public configSwiperMain: SwiperOptions = {
    navigation: {
      nextEl: '.swiper-button-next',
      prevEl: '.swiper-button-prev',
    },
    spaceBetween: 10,
    pagination: false,
    breakpoints: {
      // When the screen is >= 320px (Mobile)
      320: {
        slidesPerView: 1,
        freeMode: true,
      },
      // When the screen is >= 768px (Tablet)
      768: {
        slidesPerView: 1.5,
      },
      // When the screen is >= 1024px (Desktop)
      1024: {
        slidesPerView: 3.25,
      },
      // When the screen is >= 1440px (Large screens)
      1600: {
        slidesPerView: 4.25,
      },
    },
  };

  ngAfterViewInit(): void {
    // const player = new Plyr('#player');
    const players = this.tipVideoUrls.map(
      (_, i) =>
        new Plyr(`#player${i}`, {
          controls: ['play-large', 'play', 'progress', 'mute', 'fullscreen'],
        }),
    );
  }
}
