import { bootstrapApplication } from '@angular/platform-browser';
import * as Sentry from '@sentry/angular';
import { AppComponent } from './app/app.component';
import { appConfig } from './app/app.config';
import { SplashComponent } from './app/components/splash/splash.component';
import { environment } from './environments/environment';
import { register } from 'swiper/element/bundle';

register();

Sentry.init({
  dsn: environment.imgly.dsn,
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration(),
  ],
  tracesSampleRate: 1.0,
  tracePropagationTargets: ['localhost', /^https:\/\/yourserver\.io\/api/],
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0,
});

bootstrapApplication(AppComponent, appConfig).catch(() => null);
bootstrapApplication(SplashComponent).catch(() => null);
