import {
  CdkDrag,
  CdkDragDrop,
  CdkDropList,
  moveItemInArray,
} from '@angular/cdk/drag-drop';
import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  OnInit,
} from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ButtonComponent } from '@bupple/button';
import { NavigationService, RouteService } from '@bupple/core';
import { UiIconComponent } from '@bupple/ui/icon';
import { cloneDeep } from 'lodash-es';
import { combineLatest, filter } from 'rxjs';
import { VideoEditorFacade } from '../+state/video-editor.facade';
import {
  TrackAudioItem,
  trackItem,
  TrackVideoItem,
} from '../interfaces/timeline-track-item.interface';
import { TimeFormatPipe } from '../pipes/time-format.pipe';
import { TimelineService } from '../services/timeline.service';
import { VideoEditorDomService } from '../services/video-editor-dom.service';
import { VideoElementService } from '../services/video-element.service';

@Component({
  selector: 'lib-video-editor-timeline',
  standalone: true,
  imports: [
    CommonModule,
    ButtonComponent,
    UiIconComponent,
    CdkDropList,
    CdkDrag,
    TimeFormatPipe,
  ],
  providers: [VideoEditorDomService],
  templateUrl: './video-editor-timeline.component.html',
  styleUrl: './video-editor-timeline.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class VideoEditorTimelineComponent implements OnInit {
  constructor(
    protected videoEditorFacade: VideoEditorFacade,
    protected videoEditorDomService: VideoEditorDomService,
    protected videoElementService: VideoElementService,
    private timelineService: TimelineService,
    private navigation: NavigationService,
    private route: ActivatedRoute,
    private routeService: RouteService,
    private cdr: ChangeDetectorRef,
  ) {}

  ngOnInit(): void {
    this.listenStore();
    this.listenRoute();
  }

  isPlaying = false;
  trackItems: TrackVideoItem[] = [];
  activeTrackItem!: TrackVideoItem;
  audio!: TrackAudioItem;
  audioElement!: HTMLAudioElement;
  canPlayTimeline = false;
  listenStore() {
    this.videoEditorFacade.isPlaying$
      .pipe(filter(() => !!this.audioElement))
      .subscribe((is) => {
        this.isPlaying = is;
        this.isPlaying ? this.audioElement.play() : this.audioElement.pause();
        this.cdr.markForCheck();
      });

    this.videoEditorFacade.clips$.subscribe((trackItems) => {
      this.trackItems = cloneDeep(trackItems) as TrackVideoItem[];

      this.cdr.markForCheck();
    });

    combineLatest([
      this.videoEditorFacade.activeTrackItem$,
      this.videoEditorFacade.totalTimeSpent$,
    ])
      .pipe(
        filter(
          ([activeTrackItem]) =>
            !!activeTrackItem && !!this.audioElement && !this.isPlaying,
        ),
      )
      .subscribe(([activeClip, totalTimeSpent]) => {
        this.activeTrackItem = activeClip as TrackVideoItem;

        this.setAudioCurrentTime(totalTimeSpent);
      });

    this.videoEditorFacade.audio$
      .pipe(filter((a) => !!a))
      .subscribe((audio) => {
        this.audio = audio as TrackAudioItem;
        this.audioElement = this.videoEditorDomService.getAudioElement(
          audio?.asset,
        );
        this.addEventListener();
      });

    this.videoEditorFacade.canPlayTimeline$.subscribe((can) => {
      this.canPlayTimeline = can;
      this.cdr.markForCheck();
    });

    this.videoEditorFacade.stopAudio$
      .pipe(
        filter(
          () => this.audioElement && this.canPlayTimeline && this.isPlaying,
        ),
      )
      .subscribe((stopAudio) => {
        stopAudio ? this.audioElement.pause() : this.audioElement.play();
      });
  }

  setAudioCurrentTime(time: number) {
    this.audioElement.currentTime = time;
  }

  addEventListener() {
    this.audioElement.addEventListener('canplaythrough', () => {
      this.videoEditorFacade.setAudioIsReady();
    });
  }

  ideaboardId!: string;
  listenRoute() {
    this.routeService.listenIdeaobardId(this.route).subscribe((ideaboardId) => {
      this.ideaboardId = ideaboardId;
    });
  }

  onClickTogglePlay() {
    this.videoEditorFacade.setIsPlaying(!this.isPlaying);
    this.isPlaying && this.audioElement.play();
  }

  drop(event: CdkDragDrop<string[]>) {
    moveItemInArray(this.trackItems, event.previousIndex, event.currentIndex);
    const clips = this.timelineService.deactivateAllTrackItems(this.trackItems);
    this.videoEditorFacade.setVideoClips(clips);
    this.videoEditorFacade.setActiveClip(clips[0].id as string);
  }

  onClickClip(clip: trackItem) {
    this.videoEditorFacade.setIsPlaying(false);
    this.videoEditorFacade.setActiveClip(clip.id as string);
    this.navigation.goVideoEditorElements(this.ideaboardId, 'video-id');
  }
}
