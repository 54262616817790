import { Injectable } from '@angular/core';
import { PublishApi } from '../apis/publish.api';

@Injectable()
export class PublishService {
  constructor(private publishApi: PublishApi) {}

  publishPost(
    platform: string,
    accountId: string,
    postId: string,
    dateTime?: string,
  ) {
    return this.publishApi.publishPost(platform, accountId, postId, dateTime);
  }
}
