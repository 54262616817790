import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class JsonHelper {
  isJSON(string: string) {
    try {
      return JSON.parse(string) && !!string;
    } catch (e) {
      return false;
    }
  }
}
