import { Injectable } from '@angular/core';
import { HttpClientAdaptor } from '@bupple/util/adaptors';
import { ObjectUtils } from '@bupple/util/helper';
import { Observable, firstValueFrom } from 'rxjs';
import { LoginDto, RegisterDto } from '../dtos/auth.dto';
import { MeResponseDto, UserWithTokenResponseDto } from '../dtos/user.dto';

@Injectable({
  providedIn: 'root',
})
export class AuthApi {
  constructor(private http: HttpClientAdaptor) {}

  private endpointHead = 'auth/';

  me(): Promise<MeResponseDto> {
    return firstValueFrom(
      this.http.get<MeResponseDto>(this.endpointHead + 'me'),
    );
  }

  register(model: RegisterDto): Observable<UserWithTokenResponseDto> {
    return this.http.post<UserWithTokenResponseDto>(
      this.endpointHead + 'register' + ObjectUtils.UrlSearchParams(model),
      null,
    );
  }

  login(model: LoginDto): Observable<UserWithTokenResponseDto> {
    return this.http.post<UserWithTokenResponseDto>(
      this.endpointHead + 'login' + ObjectUtils.UrlSearchParams(model),
      null,
    );
  }

  logout(): Promise<unknown> {
    return firstValueFrom(
      this.http.post<unknown>(this.endpointHead + 'logout', null),
    );
  }

  forgotPassword(email: string): Observable<unknown> {
    return this.http.post<unknown>(
      this.endpointHead +
        'forget-password' +
        ObjectUtils.UrlSearchParams({ email }),
      null,
    );
  }

  emailConfirmation(): Observable<unknown> {
    return this.http.get<unknown>(
      this.endpointHead + 'email-verification/' + 'resend-email',
    );
  }

  tokenValidation(token: string): Observable<boolean> {
    return this.http.get<boolean>(
      this.endpointHead + 'forget-password/validate/' + token,
    );
  }

  resetPassword(
    token: string,
    password: string,
    passwordCon: string,
  ): Observable<unknown> {
    return this.http.post<unknown>(
      this.endpointHead +
        'forget-password/change-password/' +
        ObjectUtils.UrlSearchParams({
          token,
          password,
          password_confirmation: passwordCon,
        }),
      null,
    );
  }
}
