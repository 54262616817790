import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UiIconComponent } from '@bupple/ui/icon';

@Component({
  selector: 'bupple-ui-top-badge',
  standalone: true,
  imports: [CommonModule, UiIconComponent],
  templateUrl: './ui-top-badge.component.html',
  styleUrl: './ui-top-badge.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UiTopBadgeComponent {
  @Input() title!: string;
  @Input() icon!: string;
}
