import { Inject, Injectable } from '@angular/core';
import { Environment } from '@bupple/interfaces';
import { Observable, tap } from 'rxjs';
import { CoreFacade } from '../+state/core.facade';
import { AuthApi } from '../apis/auth.api';
import { LoginDto, RegisterDto } from '../dtos/auth.dto';
import { UserCreditDto, UserWithTokenResponseDto } from '../dtos/user.dto';
import { NavigationService } from './navigation.service';
import { StorageService } from './storage.service';
import { PusherService } from './pusher.service';
import { user } from '../+state/core.selectors';
import { log } from 'console';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  constructor(
    private authApi: AuthApi,
    private coreFacade: CoreFacade,
    private storage: StorageService,
    private navigation: NavigationService,
    @Inject('environment') private environment: Environment,
    private pusherService: PusherService,
  ) {}

  async me(): Promise<void> {
    const result = await this.authApi.me();
    this.pusherService.listenCreditEvents(result.user._id);
    this.coreFacade.setUser(result.user);

    const credit: UserCreditDto = {
      user_id: result.user._id,
      current_credit: result.user.credits,
      credit_cost: 0,
      action_type: 'deducted',
    };
    this.coreFacade.setUserCredit(credit);
  }

  register(model: RegisterDto): Observable<UserWithTokenResponseDto> {
    return this.authApi.register(model).pipe(
      tap((result) => {
        this.storeAutherizationState(result);
      }),
    );
  }

  login(model: LoginDto): Observable<UserWithTokenResponseDto> {
    return this.authApi.login(model).pipe(
      tap((result) => {
        this.storeAutherizationState(result);
      }),
    );
  }

  storeAutherizationState(state: UserWithTokenResponseDto) {
    this.coreFacade.setUser(state.user);
    this.storage.setToken(state.token);
  }

  async logout(): Promise<void> {
    try {
      await this.authApi.logout();
      this.logoutOnClient();
    } catch (error) {
      this.logoutOnClient();
    }
  }

  logoutOnClient() {
    this.storage.clearAll();
    this.coreFacade.resetUser();
    this.navigation.goSignIn();
  }

  screenWidth = window.screen.width;
  screenHeight = window.screen.height;
  width = Math.min(600, this.screenWidth - 20);
  height = 600;
  left = (this.screenWidth - this.width) / 2;
  top = (this.screenHeight - this.height) / 2;

  continueWithGoogle(): Observable<boolean> {
    return new Observable((observer) => {
      window.open(
        this.environment.api + '/auth/external/jump/google',
        'Google Auth',
        `width=${this.width},height=${this.height},top=${this.top},left=${this.left}`,
      );

      const messageListener = (event: MessageEvent) => {
        if (event.origin !== this.environment.origin) {
          return;
        }

        if (!this.storage.token && event.data.token) {
          this.storage.setToken(event.data.token);
          this.coreFacade.setUser(event.data.user);

          observer.next(true);
          observer.complete();
        }

        if (!this.storage.token && event.data.error) {
          observer.error(new Error(event.data.error));
        }
      };

      window.addEventListener('message', messageListener);
    });
  }

  continueWithFacebook(): Observable<boolean> {
    return new Observable((observer) => {
      window.open(
        this.environment.api + '/auth/external/jump/fb',
        'Facebook Auth',
        `width=${this.width},height=${this.height},top=${this.top},left=${this.left}`,
      );

      const messageListener = (event: MessageEvent) => {
        if (event.origin !== this.environment.origin) {
          return;
        }

        if (!this.storage.token && event.data.token) {
          this.storage.setToken(event.data.token);
          this.coreFacade.setUser(event.data.user);

          observer.next(true);
          observer.complete();
        }

        if (!this.storage.token && event.data.error) {
          observer.error(new Error(event.data.error));
        }
      };

      window.addEventListener('message', messageListener);
    });
  }

  forgotPassword(email: string): Observable<unknown> {
    return this.authApi.forgotPassword(email);
  }

  emailConfirmation(): Observable<unknown> {
    return this.authApi.emailConfirmation();
  }

  tokenValidation(token: string): Observable<boolean> {
    const res = this.authApi.tokenValidation(token);
    return res;
  }

  resetPassword(token: string, password: string, passwordCon: string) {
    return this.authApi.resetPassword(token, password, passwordCon);
  }
}
