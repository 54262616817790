<div class="chat chat-start flex flex-col mr-auto px-3 md:py-0"
  *ngIf="recievedMessage?.meta?.notif?.progress !== 100"
  resizeObserver
  (resizeHeight)="onResize()">
  <div class="chat-bubble"
    [ngClass]="{
      'p-1':
        recievedMessage?.type === messageType.image ||
        recievedMessage?.type === messageType.video,
    }">
    <!-- Badge -->
    <div *ngIf="
        recievedMessage?.type === messageType.script ||
        recievedMessage?.type === messageType.story ||
        recievedMessage?.type === messageType.idea
      "
      class="mb-5">
      <div class="border-1 border-solid inline-block rounded-lg items-center px-2"
        [ngClass]="{
          'border-success bg-success/12':
            recievedMessage?.type === messageType.script,
          'border-info bg-info/50': recievedMessage?.type === messageType.story,
          'border-warning bg-warning/12':
            recievedMessage?.type === messageType.idea,
        }">
        <mat-icon class="align-middle text-sm w-5 h-5"
          [ngClass]="{
            'text-success': recievedMessage?.type === messageType.script,
            'text-base-content': recievedMessage?.type === messageType.story,
            'text-warning': recievedMessage?.type === messageType.idea,
          }">description</mat-icon>
        <span class="text-sm"
          [ngClass]="{
            'text-success': recievedMessage?.type === messageType.script,
            'text-base-content': recievedMessage?.type === messageType.story,
            'text-warning': recievedMessage?.type === messageType.idea,
          }">
          {{ recievedMessage?.type }}</span>
      </div>
    </div>

    <!-- Loading -->
    <bupple-loading *ngIf="recievedMessage?.type === messageType.loading"></bupple-loading>

    <!-- ProgressBar -->
    <div *ngIf="
        recievedMessage && recievedMessage.type === messageType.notification
      ">
      <lib-progress-bar [recivedMessage]="recievedMessage"></lib-progress-bar>
    </div>

    <!-- Text -->
    <bupple-received-chat-message-text *ngIf="
        recievedMessage &&
        (recievedMessage.type === messageType.text ||
          recievedMessage.type === messageType.idea ||
          recievedMessage.type === messageType.story ||
          recievedMessage.type === messageType.script ||
          (recievedMessage.type === messageType.notification &&
            recievedMessage.meta?.notif?.func !== notifType.video))
      "
      [recievedMessage]="recievedMessage"></bupple-received-chat-message-text>

    <!-- Video -->
    <bupple-received-chat-message-video *ngIf="
        recievedMessage &&
        (recievedMessage.type === messageType.video ||
          (recievedMessage.type === messageType.notification &&
            recievedMessage.meta?.notif?.func === notifType.video))
      "
      [message]="recievedMessage"></bupple-received-chat-message-video>

    <!-- Image -->
    <bupple-received-chat-message-image *ngIf="recievedMessage && recievedMessage.type === messageType.image"
      [message]="recievedMessage"></bupple-received-chat-message-image>

    <!-- Creative -->
    <bupple-received-chat-message-creative *ngIf="recievedMessage && recievedMessage.type === messageType.creative"
      [message]="recievedMessage"
      (previewIsReady)="onCreativePreviewIsReady(recievedMessage._id, $event)"></bupple-received-chat-message-creative>

    <!-- Created At -->
    <p class="text-xs text-right mt-2 text-base-content-secondary"
      [ngClass]="{
        'px-2':
          recievedMessage?.type === messageType.image ||
          recievedMessage?.type === messageType.video,
      }"
      *ngIf="recievedMessage?.type !== messageType.loading">
      {{ recievedMessage?.created_at | shortTime }}
    </p>

    <!-- Actions -->
    <div class="pt-4 pb-3 flex flex-col gap-3 md:flex-row flex-wrap"
      [ngClass]="{
        'md:flex-col p-2':
          recievedMessage?.type === messageType.image ||
          recievedMessage?.type === messageType.video ||
          recievedMessage?.type === messageType.creative,
      }"
      *ngIf="recievedMessage?.is_publishable || actionButtons?.length">
      <lib-button block
        size="sm"
        *ngIf="
          recievedMessage && recievedMessage?.type === messageType.creative
        "
        (clickEvent)="
          onClickEditCreative(
            recievedMessage._id,
            recievedMessage.file_full_path
          )
        ">
        Edit
      </lib-button>
      <lib-button block
        size="sm"
        appearance="tonal"
        (clickEvent)="onClickMakePost()"
        *ngIf="recievedMessage?.is_publishable">
        Publish / Schedule
      </lib-button>

      @for (button of actionButtons; track $index) {
      <lib-button [block]="screenSize.isXs ? true : false"
        size="sm"
        appearance="tonal"
        (clickEvent)="onClickAction(button)">
        {{ button.title }}
      </lib-button>
      }
    </div>
  </div>

  <!--  Buttons -->
  <div class="flex gap-2 mt-2">
    <lib-button size="sm"
      square
      color="neutral"
      appearance="tonal"
      [class.hidden]="!recievedMessage?.ideaboard_id"
      (clickEvent)="onClickCopyText()"
      *ngIf="
        recievedMessage?.type === messageType.script ||
        recievedMessage?.type === messageType.idea ||
        recievedMessage?.type === messageType.text ||
        recievedMessage?.type === messageType.story
      ">
      <bupple-icon size="sm"
        name="Outline / Essentional, UI / Copy"></bupple-icon>
    </lib-button>
    <lib-button size="sm"
      square
      color="neutral"
      appearance="tonal"
      [class.hidden]="!recievedMessage?.ideaboard_id"
      (clickEvent)="onClickDownloadImage()"
      *ngIf="recievedMessage?.type === messageType.image">
      <bupple-icon size="sm"
        name="Outline / Arrows Action / Download Minimalistic"></bupple-icon>
    </lib-button>
    <lib-button size="sm"
      square
      color="neutral"
      appearance="tonal"
      [class.hidden]="!recievedMessage?.ideaboard_id"
      (clickEvent)="onClickDislike()">
      <bupple-icon size="sm"
        name="Outline / Like / Dislike"></bupple-icon>
    </lib-button>
    <lib-button size="sm"
      square
      color="neutral"
      appearance="tonal"
      [class.hidden]="!recievedMessage?.ideaboard_id"
      (clickEvent)="onClickDelete()">
      <bupple-icon size="sm"
        name="Outline / Essentional, UI / Trash Bin Trash"></bupple-icon>
    </lib-button>
  </div>
</div>