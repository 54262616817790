import { Injectable } from '@angular/core';
import CreativeEngine from '@cesdk/engine';
import { CREATIVE_CONFIG } from '../constants/creative-config.constants';

@Injectable({
  providedIn: 'root',
})
export class CreativeEngineService {
  private _engine: CreativeEngine | undefined = undefined;

  async getEngine(): Promise<CreativeEngine> {
    if (!this._engine) {
      this._engine = await this.initializeEngine();
    }

    return this._engine;
  }

  private async initializeEngine(): Promise<CreativeEngine> {
    return await CreativeEngine.init(CREATIVE_CONFIG);
  }
}
