import { Route } from '@angular/router';
import { FeatureForgotPasswordComponent } from './feature-forgot-password/feature-forgot-password.component';
import { PATH, TITLE, UnauthorizedGuard } from '@bupple/core';

export const featureForgotPasswordRoutes: Route[] = [
  {
    path: PATH.forgotPassword,
    title: TITLE.forgotPassword,
    canActivate: [UnauthorizedGuard],
    loadComponent: () =>
      import(
        './feature-forgot-password/feature-forgot-password.component'
      ).then((m) => m.FeatureForgotPasswordComponent),
  },
  {
    path: PATH.resetPassword + '/' + 'verification' + '/' + ':token',
    title: TITLE.resetPassword,
    canActivate: [UnauthorizedGuard],
    loadComponent: () =>
      import('./reset-password/reset-password.component').then(
        (m) => m.ResetPasswordComponent,
      ),
  },
];
