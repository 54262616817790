import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { NavigationService } from '@bupple/core';
import {
  IdeaboardApi,
  IdeaboardService,
  StudioFacade,
} from '@bupple/studio/data-access';
import { head } from 'lodash-es';
import { combineLatest } from 'rxjs';
import { SubSink } from 'subsink';
import { VideoEditorFacade } from '../+state/video-editor.facade';
import { TrackAudioItem } from '../interfaces/timeline-track-item.interface';
import { Timeline } from '../interfaces/timeline.interface';
import { SubtitleService } from '../services/subtitle.service';
import { TimelineService } from '../services/timeline.service';
import { VideoElementService } from '../services/video-element.service';
import { VideoEditorCanvasComponent } from '../video-editor-canvas/video-editor-canvas.component';
import { VideoEditorMenuComponent } from '../video-editor-menu/video-editor-menu.component';
import { VideoEditorSidebarComponent } from '../video-editor-sidebar/video-editor-sidebar.component';
import { VideoEditorTimelineComponent } from '../video-editor-timeline/video-editor-timeline.component';
import { VideoEditorToolbarComponent } from '../video-editor-toolbar/video-editor-toolbar.component';

@Component({
  selector: 'lib-feature-video-editor',
  standalone: true,
  imports: [
    CommonModule,
    VideoEditorMenuComponent,
    VideoEditorSidebarComponent,
    VideoEditorToolbarComponent,
    VideoEditorCanvasComponent,
    VideoEditorTimelineComponent,
  ],
  providers: [
    IdeaboardService,
    IdeaboardApi,
    VideoEditorFacade,
    TimelineService,
    SubtitleService,
    VideoElementService,
  ],
  templateUrl: './feature-video-editor.component.html',
  styleUrl: './feature-video-editor.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FeatureVideoEditorComponent implements OnInit, OnDestroy {
  constructor(
    private route: ActivatedRoute,
    protected navigation: NavigationService,
    private studioFacade: StudioFacade,
    private ideaboardService: IdeaboardService,
    private videoEditorFacade: VideoEditorFacade,
    private subtitleService: SubtitleService,
    private timelineService: TimelineService,
  ) {}

  ngOnInit(): void {
    this.checkIdeaboardExistance();
  }

  subsink = new SubSink();
  checkIdeaboardExistance() {
    this.subsink.sink = combineLatest([
      this.route.params,
      this.studioFacade.activeIdeaboardId$,
    ]).subscribe(([{ ideaboardId }, activeIdeaboardId]) => {
      if (ideaboardId && !activeIdeaboardId) {
        this.subsink.sink = this.ideaboardService.get(ideaboardId).subscribe();
      }

      activeIdeaboardId && this.getTimeline(ideaboardId);
    });
  }

  getTimeline(ideaboardId: string) {
    this.ideaboardService.getVideoData(ideaboardId).subscribe((result: any) => {
      const timeline: Timeline = result.bundle_json.timeline;

      const videoTrack = this.timelineService.getVideosTrack(timeline);
      this.videoEditorFacade.setVideoClips(videoTrack.items);

      const subtitleTrack = this.timelineService.getSubtitleTrack(timeline);
      const segments = this.subtitleService.mapSegments(
        subtitleTrack.transcription.segments,
      );
      this.videoEditorFacade.setSubtitleSegments(segments);

      const firstVideo = head(videoTrack.items);
      this.videoEditorFacade.setActiveClip(firstVideo?.id as string);

      const audioTrack = this.timelineService.getAudioTrack(timeline);
      const mainAudio = head(audioTrack.items);
      this.videoEditorFacade.setAudio(mainAudio as TrackAudioItem);
    });
  }

  ngOnDestroy(): void {
    this.subsink.unsubscribe();
  }
}
