import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';
import { MessageAction } from '@bupple/core';
import { CreditBadgeComponent } from '@bupple/credit-badge';
import { UiIconComponent } from '@bupple/ui/icon';

@Component({
  selector: 'bupple-ui-chat-action-item',
  standalone: true,
  imports: [UiIconComponent, CommonModule, CreditBadgeComponent],
  templateUrl: './ui-chat-action-item.component.html',
  styleUrls: ['./ui-chat-action-item.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UiChatActionItemComponent {
  @Input({ required: true }) action!: MessageAction;
  @Input() hasDes = true;
  @Output() clickAction: EventEmitter<MouseEvent> = new EventEmitter();
  @Input() creditSide = '';
  actionEnums = ActionEnums;

  onClick(event: MouseEvent) {
    event.stopPropagation();
    this.clickAction.emit(event);
  }
}

enum ActionEnums {
  Video = 'Script to Video',
  Script = 'Video Script',
  Ideas = 'Brainstorm Ideas',
  Image = 'Generate Image',
}
