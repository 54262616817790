import { Injectable } from '@angular/core';
import {
  CreateMessageDto,
  IdeaboardChatService,
  MessageType,
  SentMessageDto,
  StudioFacade,
} from '@bupple/studio/data-access';
import { Observable, filter, of, switchMap } from 'rxjs';
import { v4 } from 'uuid';
import { SendChatMessage } from '../interfaces/send-message.interface';

@Injectable()
export class LinkMessageService implements SendChatMessage {
  constructor(
    private studioFacade: StudioFacade,
    private ideaboardChatService: IdeaboardChatService,
  ) {}

  send(ideaboardId: string, message: CreateMessageDto): Observable<unknown> {
    this.setMockSentMessage(message.content);

    return this.ideaboardChatService.send(ideaboardId, message).pipe(
      filter((result: any) => !!result?.body?.chat),
      switchMap((result: any) => {
        this.studioFacade.setRealSentMessage(result.body.chat);

        return of(result);
      }),
    );
  }

  setMockSentMessage(content: string): void {
    const msg: SentMessageDto = {
      is_publishable: false,
      _id: v4(),
      created_at: new Date().toString(),
      file_full_path: '',
      ideaboard_id: '',
      sender_id: v4(),
      updated_at: '',
      type: MessageType.link,
      content: content,
    };
    this.studioFacade.setMockSentMessage(msg);
  }
}
