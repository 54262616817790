import { Injectable, inject } from '@angular/core';
import { Store, select } from '@ngrx/store';
import { GetUserDto, UserCreditDto } from '../dtos/user.dto';
import * as CoreActions from './core.actions';
import * as CoreSelectors from './core.selectors';
import { ListAllPostsDto } from '@bupple/interfaces';

@Injectable({
  providedIn: 'root',
})
export class CoreFacade {
  private readonly store = inject(Store);

  initialized$ = this.store.pipe(select(CoreSelectors.initialized));
  user$ = this.store.pipe(select(CoreSelectors.user));
  theme$ = this.store.pipe(select(CoreSelectors.theme));
  promotionBanner$ = this.store.pipe(select(CoreSelectors.promotionBanner));
  posts$ = this.store.pipe(select(CoreSelectors.posts));
  userCredit$ = this.store.pipe(select(CoreSelectors.userCredit));
  currentCredit$ = this.store.pipe(select(CoreSelectors.currentCredit));

  resetUser() {
    this.store.dispatch(CoreActions.resetUser());
  }

  setInitialized() {
    this.store.dispatch(CoreActions.setInitialized());
  }

  setUser(user: GetUserDto) {
    this.store.dispatch(CoreActions.setUser({ user }));
  }

  setPromotionBanner(banner: any) {
    this.store.dispatch(CoreActions.setPromotionBanner({ banner }));
  }

  setPosts(posts: ListAllPostsDto) {
    this.store.dispatch(CoreActions.setPosts({ posts }));
  }

  setUserCredit(credit: UserCreditDto) {
    this.store.dispatch(CoreActions.setUserCredit({ credit }));
  }
}
