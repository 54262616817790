<div
  class="bg-base-100 py-4 overflow-y-auto overflow-x-hidden max-h-full"
  #chatBody
>
  <div class="chat-container">
    <div
      *ngIf="showLoadMoreSection"
      class="bg-base-500 text-center py-1 text-sm mb-4"
    >
      Loading
      <bupple-loading class="align-middle"></bupple-loading>
    </div>
    <div #chatBodyInner class="flex flex-col md:gap-y-5">
      <ng-container *ngFor="let message of chat?.data; trackBy: trackBy.id">
        <bupple-sent-chat-message
          *ngIf="message && message.sender_id"
          [message]="message"
          (action)="onAction($event, message)"
          (clickDelete)="onClickDeleteMeesage($event)"
          (clickMakePost)="navigation.goMakePost(ideaboardId, message._id)"
        ></bupple-sent-chat-message>
        <bupple-received-chat-message
          *ngIf="
            message &&
            !message.sender_id &&
            message?.meta?.notif?.progress !== 100
          "
          [message]="message"
          (resizeHeight)="isRecievingMessage && scrollToTheBottomOfChatBody()"
          (action)="onAction($event, message)"
          (clickDislike)="onClickDislike(message)"
          (clickMakePost)="navigation.goMakePost(ideaboardId, message._id)"
          (clickDelete)="onClickDeleteMeesage($event)"
        ></bupple-received-chat-message>
      </ng-container>
    </div>
    <div class="grow" *ngIf="!chat?.data?.length">
      @if (isLoadingChat) {
        <div class="grow flex w-full px-4">
          <div class="flex flex-col gap-3 w-full items-end">
            <div class="skeleton w-60 bg-base-content/12 h-32 self-start"></div>
            <div class="skeleton w-60 bg-base-content/12 h-32"></div>
            <div class="skeleton w-60 bg-base-content/12 h-12 self-start"></div>
            <div class="skeleton w-60 bg-base-content/12 h-20"></div>
            <div class="skeleton w-60 bg-base-content/12 h-32"></div>
            <div class="skeleton w-60 bg-base-content/12 h-16 self-start"></div>
          </div>
        </div>
      } @else {
        @if (selectedAction) {
          <div
            class="px-10 text-center mt-48 flex flex-col w-full max-w-[35rem] gap-y-5 mx-auto md:px-0"
          >
            <bupple-icon
              [name]="selectedAction.icon || ''"
              size="3xl"
              class="mx-auto"
            ></bupple-icon>
            <span>
              {{ selectedAction.placeholder }}
            </span>
          </div>
        } @else {
          <div
            class="px-10 text-center mt-48 flex flex-col w-full max-w-[35rem] gap-y-5 mx-auto md:px-0"
          >
            <img
              class="w-[4.5rem] h-[4.5rem] mx-auto"
              src="assets/images/Y/desktop/icon/heart-note-2.svg"
              alt=""
            />
            <span
              class="sub-text-xl md:title-base text-base-content md:text-base-content-secondary"
            >
              Start the chat or choose one of the actions to do magic together.
              🎨
            </span>
          </div>
        }
      }
    </div>
  </div>
</div>
