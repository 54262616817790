<div>
  <div class="flex flex-col justify-center items-center gap-y-4">
    <img class="w-24 h-24 md:w-[6.5rem] md:h-[6.5rem]" src="assets/images/Y/desktop/icon/heart-note.svg"
      alt="Heart note" />
    <div class="max-w-64 w-full flex flex-col gap-2 md:max-w-full">
      <h3 class="title-l-bold leading-8 px-4 md:px-0 md:leading-10">
        {{ title }}
      </h3>
      <p class="sub-text-l text-base-content-secondary tracking-wider md:title-base">
        {{ titleSub }}
      </p>
    </div>
  </div>
</div>