import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import {
  MatBottomSheetModule,
  MatBottomSheetRef,
} from '@angular/material/bottom-sheet';
import { ButtonComponent } from '@bupple/button';

@Component({
  selector: 'bupple-delete-message-confirmation',
  standalone: true,
  imports: [CommonModule, MatBottomSheetModule, ButtonComponent],
  templateUrl: './delete-message-confirmation.component.html',
  styleUrls: ['./delete-message-confirmation.component.scss'],
})
export class DeleteMessageConfirmationComponent {
  constructor(
    protected bottomSheetRef: MatBottomSheetRef<DeleteMessageConfirmationComponent>,
  ) {}
}
