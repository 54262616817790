import { CommonModule } from '@angular/common';
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  ViewChild,
} from '@angular/core';
import { MatDialog, MatDialogModule } from '@angular/material/dialog';
import { ButtonComponent } from '@bupple/button';
import {
  CREATIVE_CONFIG,
  CreativeEditorService,
  CreativeEngineService,
  CreativeSceneService,
} from '@bupple/core';
import { ListDto } from '@bupple/interfaces';
import { UiLoadingComponent } from '@bupple/ui/loading';
import CreativeEditorSDK, { AssetDefinition } from '@cesdk/cesdk-js';
import CreativeEngine from '@cesdk/engine';
import { remove } from 'lodash-es';
import { SubSink } from 'subsink';
import { AddSceneComponent } from '../add-scene/add-scene.component';
import { SceneApi } from '../apis/scene.api';
import { CreateSceneDto, GetSceneDto } from '../dtos/scene.dto';
import { SceneCardComponent } from '../scene-card/scene-card.component';
import { SceneService } from '../services/scene.service';

@Component({
  selector: 'lib-admin-creative-editor',
  standalone: true,
  imports: [
    CommonModule,
    ButtonComponent,
    SceneCardComponent,
    MatDialogModule,
    UiLoadingComponent,
  ],
  providers: [CreativeEditorService, SceneService, SceneApi],
  templateUrl: './admin-creative-editor.component.html',
  styleUrl: './admin-creative-editor.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AdminCreativeEditorComponent implements AfterViewInit {
  constructor(
    private creativeEditorService: CreativeEditorService,
    private creativeEngineService: CreativeEngineService,
    private sceneService: SceneService,
    private dialog: MatDialog,
    private cdr: ChangeDetectorRef,
    private editorSceneService: CreativeSceneService,
  ) {}
  @ViewChild('cesdk_container') containerRef: ElementRef = {} as ElementRef;

  subsink = new SubSink();
  editor!: CreativeEditorSDK;
  async ngAfterViewInit() {
    this.editor = await this.initializeEditor();
    this.setupEmptyScene();

    this.engine = await this.initializeEngine();

    this.getAllScenes();
    this.getGlobalAssets();
  }

  async initializeEditor(): Promise<CreativeEditorSDK> {
    const instance = await this.creativeEditorService.getEditor(
      this.containerRef,
      {
        ...CREATIVE_CONFIG,
        callbacks: {
          onUpload: async (file: File) => {
            return await this.uploadImage(file);
          },
        },
        ui: {
          ...CREATIVE_CONFIG.ui,
          elements: {
            ...CREATIVE_CONFIG.ui?.elements,
            navigation: {
              action: {
                custom: [
                  {
                    label: 'Save Changes',
                    iconName: 'save',
                    callback: () => {
                      this.onClickSaveChanges();
                    },
                  },
                ],
              },
            },
          },
        },
      },
    );

    return instance;
  }

  async uploadImage(file: File): Promise<AssetDefinition> {
    return await this.sceneService.uploadGlobalAsset(file);
  }

  async setupEmptyScene() {
    await this.editor.engine.scene.create();
  }

  engine!: CreativeEngine;
  async initializeEngine(): Promise<CreativeEngine> {
    const engine = await this.creativeEngineService.getEngine();
    return engine;
  }

  scenes: GetSceneDto[] = [];
  inProgressGetAll = true;
  getAllScenes() {
    this.cdr.markForCheck();
    this.subsink.sink = this.sceneService
      .getAll()
      .subscribe((result: ListDto<GetSceneDto>) => {
        this.scenes = result.data;
        this.scenes.length && this.onSelectScene(this.scenes[0]);
        this.inProgressGetAll = false;
        this.cdr.markForCheck();
      });
  }

  getGlobalAssets() {
    this.sceneService.getGlobalAssets().subscribe((result) => {
      result?.length && this.addAssetsToTheEditor(result);
    });
  }

  addAssetsToTheEditor(assets: AssetDefinition[]) {
    assets.forEach((a) => this.addAsset(this.editor, a));
  }

  async addAsset(instance: CreativeEditorSDK, asset: AssetDefinition) {
    instance.engine.asset.addAssetToSource('ly.img.image', asset);
  }

  onClickAddScene() {
    this.subsink.sink = this.dialog
      .open(AddSceneComponent)
      .afterClosed()
      .subscribe((result: string | undefined) => {
        result && this.addScene(result as string);
      });
  }

  inProgressAdd = false;
  async addScene(name: string) {
    this.inProgressAdd = true;
    this.cdr.markForCheck();
    const scene = await this.editorSceneService.createScene(this.engine);

    const model: CreateSceneDto = {
      name,
      scene,
    };
    this.subsink.sink = this.sceneService
      .add(model)
      .subscribe((result: GetSceneDto) => {
        this.scenes = [result, ...this.scenes];
        this.onSelectScene(result);
        this.inProgressAdd = false;
        this.cdr.markForCheck();
      });
  }

  selectedScene!: GetSceneDto | null;
  async onSelectScene(scene: GetSceneDto) {
    this.scenes = this.scenes.map((s) => ({
      ...s,
      selected: s._id === scene._id,
    }));

    this.selectedScene = this.scenes.find(
      (s) => s._id === scene._id,
    ) as GetSceneDto;

    await this.editorSceneService.loadSceneFromUrl(
      this.editor.engine,
      scene.file_full_path,
    );
    this.cdr.markForCheck();
  }

  onClickDeleteScene(scene: GetSceneDto) {
    const result = confirm('Are you sure');
    if (!result) {
      return;
    }

    scene.isDeleting = true;
    this.subsink.sink = this.sceneService.delete(scene._id).subscribe(() => {
      this.scenes = remove(this.scenes, (s) => s._id === scene._id);
      scene.isDeleting = false;

      if (!this.scenes.length) {
        this.selectedScene = null;
        this.setupEmptyScene();
      }

      this.cdr.markForCheck();
    });
  }

  async onClickSaveChanges() {
    if (this.selectedScene) {
      this.selectedScene.isSaving = true;
    }

    this.cdr.markForCheck();

    const scene = await this.editorSceneService.getCurrentScene(
      this.editor.engine,
    );
    const model: CreateSceneDto = {
      name: this.selectedScene?.name || '',
      scene,
    };
    this.subsink.sink = this.sceneService
      .update(this.selectedScene?._id as string, model)
      .subscribe(() => {
        if (this.selectedScene) {
          this.selectedScene.isSaving = false;
        }

        this.cdr.markForCheck();
      });
  }

  get selectedSceneIsSaving(): boolean {
    return Boolean(this.selectedScene?.isSaving);
  }
}
