import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component } from '@angular/core';
import { ButtonComponent } from '@bupple/button';
import { NavigationService } from '@bupple/core';

@Component({
  selector: 'lib-error-page',
  standalone: true,
  imports: [CommonModule, ButtonComponent],
  templateUrl: './error-page.component.html',
  styleUrl: './error-page.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ErrorPageComponent {
  constructor(protected navigation: NavigationService) {}
}
