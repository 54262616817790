import { ChangeDetectionStrategy, Component, Host } from '@angular/core';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'bupple-top-pattern',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './top-pattern.component.html',
  styleUrl: './top-pattern.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  host: {
    style: 'display: contents;',
  },
})
export class TopPatternComponent {}
