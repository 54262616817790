<bupple-top-pattern></bupple-top-pattern>
<div
  class="md:p-8 md:flex md:gap-x-10 md:w-full md:mx-auto md:flex-row-reverse"
>
  <!-- Header of profile -->
  <div class="w-full p-4 md:hidden">
    <div class="flex w-full justify-between items-center">
      <lib-button square appearance="text" (clickEvent)="navigation.goHome()">
        <bupple-icon
          className="w-4 h-4"
          name="Outline / Arrows / Alt Arrow Left"
        ></bupple-icon>
      </lib-button>
      <lib-button size="sm" routerLink="/studio/plans">
        <bupple-icon
          size="sm"
          name="Bold / Essentional, UI / Bolt"
        ></bupple-icon>
        Boost
      </lib-button>
    </div>
  </div>
  <div class="md:max-w-96 w-full">
    <!-- Profile info -->
    <div
      class="w-full flex flex-col justify-center items-center gap-y-4 py-8 md:pt-0"
    >
      <div class="w-16 h-16 rounded-full">
        @if (!this.user?.avatar) {
          <img
            class="w-full h-full object-cover rounded-full"
            src="assets/images/bupple-dark-logo.jpg"
            alt="avatar"
          />
        } @else {
          <img
            class="w-full h-full object-cover rounded-full"
            [src]="user?.avatar"
            alt="avatar"
          />
        }
      </div>
      <div class="text-center">
        <h1 class="title-l-bold">{{ user?.name }}</h1>
        <p class="sub-text-l text-base-content-secondary">{{ user?.email }}</p>
      </div>
    </div>

    <div class="px-page">
      <lib-credit-preview
        [subscriptionPlan]="user?.subscription_plan"
      ></lib-credit-preview>
      <p class="sub-text-l text-base-content-secondary text-center mt-2">
        <bupple-icon
          name="Outline / Essentional, UI / Info Circle"
          size="xs"
        ></bupple-icon>
        +10 credits at the beginning of each month.
      </p>
    </div>

    <!-- counencted account -->
    <div class="mt-4">
      <div class="flex flex-col gap-3 px-4 md:px-0">
        <bupple-connect-accounts-instagram></bupple-connect-accounts-instagram>
        <bupple-connect-account-facebook></bupple-connect-account-facebook>
        <bupple-connect-account-twitter></bupple-connect-account-twitter>
        <bupple-connect-account-linkedin></bupple-connect-account-linkedin>
        <bupple-connect-account-tiktok></bupple-connect-account-tiktok>
      </div>
    </div>
  </div>

  <!-- Profile items -->

  <!-- <a *ngFor="let item of profileItems" [href]="item.link ? item.link : '#'"  [routerLink]="item.route ?  item.route : null"
        class="flex justify-between items-center p-7 border-b border-base-500 cursor-pointer">
        <div class="flex gap-3 items-center">
            <bupple-icon className="w-6 h-6" [name]="item.icon" ></bupple-icon>
            <span class="title-base text-base-content">{{item.name}}</span>
        </div>
        <bupple-icon className="w-6 h-6" name="Outline / Arrows / Arrow Right"></bupple-icon>
    </a> -->

  <div class="p-page md:flex-1 md:p-0">
    <ul>
      <li
        class="md:rounded-xl md:px-3 hover:bg-base-400"
        *ngFor="let item of profileItems"
      >
        <a
          *ngIf="item.route"
          [routerLink]="[item.route]"
          class="flex justify-between items-center py-7 border-b border-base-500 cursor-pointer"
        >
          <div class="flex gap-3 items-center">
            <bupple-icon class="w-6 h-6" [name]="item.icon"></bupple-icon>
            <span class="title-base text-base-content">{{ item.name }}</span>
          </div>
          <bupple-icon
            class="w-6 h-6"
            name="Outline / Arrows / Arrow Right"
            color="base-content"
          ></bupple-icon>
        </a>
        <a
          *ngIf="!item.route && item.link"
          [href]="item.link"
          target="_blank"
          rel="noopener noreferrer"
          class="flex justify-between items-center py-7 border-b border-base-500 cursor-pointer"
        >
          <div class="flex gap-3 items-center">
            <bupple-icon class="w-6 h-6" [name]="item.icon"></bupple-icon>
            <span class="title-base text-base-content">{{ item.name }}</span>
          </div>
          <bupple-icon
            class="w-6 h-6"
            name="Outline / Arrows / Arrow Right"
            color="base-content"
          ></bupple-icon>
        </a>
      </li>
      <li class="md:rounded-xl md:px-3 hover:bg-base-400">
        <a
          target="_blank"
          rel="noopener noreferrer"
          class="flex justify-between items-center py-7 border-b border-base-500 cursor-pointer"
          (click)="signout()"
        >
          <div class="flex gap-3 items-center">
            <bupple-icon
              class="w-6 h-6"
              name="Outline / Arrows Action / Logout"
            ></bupple-icon>
            <span class="title-base text-error">Sign Out</span>
          </div>
          <bupple-icon
            class="w-6 h-6"
            name="Outline / Arrows / Arrow Right"
            color="base-content"
          ></bupple-icon>
        </a>
      </li>
    </ul>
  </div>
</div>
