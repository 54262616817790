import { Injectable } from '@angular/core';
import { Observable, map } from 'rxjs';
import { StationApi } from '../apis/station.api';
import { RootHeart } from '../dtos/root-heart.dto';
import { Ideaboard } from '../models/ideaboard.model';
import { MessageAction } from '../models/message-action.model';

@Injectable({
  providedIn: 'root',
})
export class StationService {
  constructor(private rootApi: StationApi) {}

  heart(): Observable<RootHeart> {
    return this.rootApi.heart().pipe(
      map((result) => ({
        buttons: result.buttons.map((b) => new MessageAction(b)),
        ideaboards: result.ideaboards.map((i) => new Ideaboard(i)),
        total_ideaboards: result.total_ideaboards,
      })),
    );
  }
}
