import { NgIf } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  Inject,
  OnDestroy,
} from '@angular/core';
import {
  FormBuilder,
  FormControl,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import {
  MAT_BOTTOM_SHEET_DATA,
  MatBottomSheetModule,
  MatBottomSheetRef,
} from '@angular/material/bottom-sheet';
import { MatIconModule } from '@angular/material/icon';
import {
  IdeaboardChatApi,
  IdeaboardChatService,
  MessageType,
  StudioFacade,
} from '@bupple/studio/data-access';
import { SubSink } from 'subsink';
import { AddCardTitleComponent } from '../add-card-title/add-card-title.component';
import { ChatUploaderComponent } from '../chat-uploader/chat-uploader.component';
import { LinkPreview } from '../interfaces/link-preview.interface';
import { UploaderConfig } from '../interfaces/uploader-config.interface';
import { LinkPreviewComponent } from '../link-preview/link-preview.component';
import { ImageMessageService } from '../services/image-message.service';
import { VideoMessageService } from '../services/video-message.service';
import { ButtonComponent } from '@bupple/button';

@Component({
  selector: 'bupple-add-video',
  standalone: true,
  imports: [
    MatBottomSheetModule,
    AddCardTitleComponent,
    ReactiveFormsModule,
    MatIconModule,
    LinkPreviewComponent,
    NgIf,
    ChatUploaderComponent,
    ButtonComponent,
  ],
  providers: [
    StudioFacade,
    VideoMessageService,
    ImageMessageService,
    IdeaboardChatService,
    IdeaboardChatApi,
  ],
  templateUrl: './add-video.component.html',
  styleUrls: ['./add-video.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AddVideoComponent implements OnDestroy {
  constructor(
    private fb: FormBuilder,
    private bottomSheetRef: MatBottomSheetRef<AddVideoComponent>,
    @Inject(MAT_BOTTOM_SHEET_DATA) protected ideaboardId: string,
  ) {}

  subsink = new SubSink();

  uploaderConfig: UploaderConfig = {
    ideaboardId: this.ideaboardId,
    maxSize: 512,
    model: {
      type: MessageType.video,
      content: 'no content',
      metadata: [],
    },
  };

  form = this.fb.group({
    content: ['', [Validators.required]],
    type: [MessageType.video, Validators.required],
    metadata: {},
  });

  get linkFormControl(): FormControl {
    return this.form.controls.content;
  }

  onChangeLinkPreview(event: LinkPreview) {
    this.form.controls.metadata.setValue({
      link_preview: event,
    });
  }

  onAddVideoLink() {}

  onSelectFile(file: File) {}

  onAddUnit(result: unknown) {
    this.bottomSheetRef.dismiss();
  }

  onErrorUpload() {
    this.bottomSheetRef.dismiss();
  }

  ngOnDestroy() {
    this.subsink.unsubscribe();
  }
}
