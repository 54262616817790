import { Injectable } from '@angular/core';
import { v4 } from 'uuid';
import {
  TimelineTrackType,
  trackItem,
} from '../interfaces/timeline-track-item.interface';
import {
  SubtitleTrack,
  TimelineTrack,
} from '../interfaces/timeline-track.interface';
import { Timeline } from '../interfaces/timeline.interface';

@Injectable()
export class TimelineService {
  adaptTimeline(timeline: Timeline): Timeline {
    return {
      ...timeline,
      tracks: (timeline.tracks as TimelineTrack[]).map((t) => ({
        ...t,
        items: t.items
          ? t.items.map((i) => ({ ...i, id: v4(), active: false }))
          : undefined,
      })) as TimelineTrack[],
    };
  }

  deactivateAllTrackItems(trackItems: trackItem[]): trackItem[] {
    return trackItems.map((c) => ({ ...c, active: false }));
  }

  getVideosTrack(timeline: Timeline): TimelineTrack {
    return timeline?.tracks.find(
      (t) => t.type === TimelineTrackType.aRoll,
    ) as TimelineTrack;
  }

  getSubtitleTrack(timeline: Timeline): SubtitleTrack {
    return timeline?.tracks.find(
      (t) => t.type === TimelineTrackType.caption,
    ) as SubtitleTrack;
  }

  getAudioTrack(timeline: Timeline): TimelineTrack {
    return timeline?.tracks.find(
      (t) => t.type === TimelineTrackType.audio,
    ) as TimelineTrack;
  }
}
