<div class="h-screen flex flex-col border-r border-base-content/12 w-[280px]">
  <div class="p-3">
    <h4>Captions</h4>
  </div>
  <div class="p-3 flex flex-col gap-4 grow overflow-y-auto">
    <div *ngFor="let line of lines; trackBy: trackBy.id">
      <lib-subtitle-line
        [line]="line"
        (changeText)="onChangeLineText($event)"
      ></lib-subtitle-line>
    </div>
    <ng-container *ngIf="!lines.length">
      <lib-subtitle-line-skeleton
        *ngFor="let item of '                  '.split('')"
      ></lib-subtitle-line-skeleton>
    </ng-container>
  </div>
</div>
