<nav
  class="flex flex-col gap-3 py-2 px-1 h-screen border-r border-base-content/12 w-[75px]"
>
  <div class="h-14 flex items-center justify-center">
    <lib-button
      class="mr-2"
      size="xs"
      appearance="text"
      [disabled]="!ideaboardId"
      (clickEvent)="this.navigation.goIdeaboard(ideaboardId)"
    >
      <bupple-icon
        name="Outline / Arrows / Alt Arrow Left"
        size="md"
      ></bupple-icon>
    </lib-button>
  </div>
  <a
    class="menu-item"
    [class.menu-item--active]="view === 'captions'"
    (click)="onClickMenuCaptions()"
  >
    <bupple-icon
      name="Bold / Notes / Document Text"
      [color]="view === 'captions' ? 'base-content' : 'base-content-secondary'"
    ></bupple-icon>
    <span> Captions </span>
  </a>
  <a
    class="menu-item"
    [class.menu-item--active]="view === 'elements'"
    (click)="onClickMenuElements()"
  >
    <bupple-icon
      name="Bold / Video, Audio, Sound / Album"
      [color]="view === 'elements' ? 'base-content' : 'base-content-secondary'"
    ></bupple-icon>
    <span> Elements </span>
  </a>
</nav>
