<h2>New update is available</h2>
<p class="text-xs text-base-content/50">Install the newest version of Bupple</p>
<div class="flex gap-2 mt-8">
  <div class="grow">
    <lib-button block appearance="outline" (clickEvent)="onClickNotNow()">
      Not now
    </lib-button>
  </div>
  <div class="grow">
    <lib-button block (clickEvent)="onClickInstall()"> Install </lib-button>
  </div>
</div>
