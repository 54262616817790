import { Route } from '@angular/router';
import { AuthorizedGuard, PATH, TITLE } from '@bupple/core';

export const featurePostsRoutes: Route = {
  path: PATH.posts,
  title: TITLE.posts,
  canActivate: [AuthorizedGuard],
  loadComponent: () =>
    import('./feature-posts/feature-posts.component').then(
      (c) => c.FeaturePostsComponent,
    ),
  children: [
    {
      path: PATH.published,
      title: TITLE.published,
      loadComponent: () =>
        import('./feature-published/feature-published.component').then(
          (c) => c.FeaturePublishedComponent,
        ),
    },
    {
      path: PATH.scheduled,
      title: TITLE.scheduled,
      loadComponent: () =>
        import('./feature-scheduled/feature-scheduled.component').then(
          (c) => c.FeatureScheduledComponent,
        ),
    },
    {
      path: PATH.pending,
      title: TITLE.pending,
      loadComponent: () =>
        import('./feature-pending/feature-pending.component').then(
          (c) => c.FeaturePendingComponent,
        ),
    },
  ],
};
