import { Injectable } from '@angular/core';
import {
  CreateHotToastRef,
  HotToastService,
  ToastConfig,
} from '@ngneat/hot-toast';
import { ScreenSizeService } from './screen-size.service';

@Injectable({
  providedIn: 'root',
})
export class ToasterService {
  constructor(
    private toast: HotToastService,
    private screenSize: ScreenSizeService,
  ) {}

  baseConfig: Partial<ToastConfig> = {
    position:
      this.screenSize.isLg || this.screenSize.isXl || this.screenSize.is2xl
        ? 'bottom-right'
        : 'bottom-center',
    autoClose: true,
    className: 'bupple-toast',
    theme: 'snackbar',
    icon: '',
  };

  error(message: string): void {
    const template = `
      <div class="flex items-center gap-x-3">
        <span class='grow-0 shrink-0 basis-6'>
          <img class="w-6" src="assets/images/hot-toast-icons/error.svg" alt="Error" style="margin-right: 8px;" />
        </span>
        <div class="sub-text-l tracking-wider">
          <div class="sub-text-xl w-full">
              Error
          </div>
          <div class="w-full text-text-primary">
            ${message}
          </div>
        </div>
      </div>
    `;

    this.toast.error(template, {
      ...this.baseConfig,
      style: {
        zIndex: 9999,
        padding: '12px',
        color: '#F2F2F3',
        backgroundColor: '#151418',
        borderRadius: '20px',
        border: '1px solid rgba(218, 60, 90, 0.12)',
      },
      closeStyle: {
        color: '#F2F2F3',
      },
    });
  }

  info(message: string): CreateHotToastRef<Record<string, any> | unknown> {
    const template = `
    <div class="flex items-center gap-x-3">
      <span class='grow-0 shrink-0 basis-6'>
        <img class="w-6" src="assets/images/hot-toast-icons/info.svg" alt="Error" style="margin-right: 8px;" />
      </span>
      <div class="sub-text-l tracking-wider">
        <div class="sub-text-xl w-full">
            Info
        </div>
        <div class="w-full text-text-primary">
          ${message}
        </div>
      </div>
    </div>
  `;

    return this.toast.info(template, {
      ...this.baseConfig,
      style: {
        padding: '12px',
        color: '#F2F2F3',
        backgroundColor: '#151418',
        borderRadius: '20px',
        border: '1px solid rgba(65, 67, 238, 0.12)',
      },
      closeStyle: {
        color: '#F2F2F3',
      },
    });
  }

  success(message: string): CreateHotToastRef<Record<string, any> | unknown> {
    const template = `
    <div class="flex items-center gap-x-3">
      <span class='grow-0 shrink-0 basis-6'>
        <img class="w-6" src="assets/images/hot-toast-icons/success.svg" alt="Error" style="margin-right: 8px;" />
      </span>
      <div class="sub-text-l tracking-wider">
        <div class="sub-text-xl w-full">
            Success
        </div>
        <div class="w-full text-text-primary">
          ${message}
        </div>
      </div>
    </div>
  `;

    return this.toast.success(template, {
      ...this.baseConfig,
      style: {
        padding: '12px',
        color: '#F2F2F3',
        backgroundColor: '#151418',
        borderRadius: '20px',
        border: '1px solid rgba(67, 238, 166, 0.12)',
      },
      closeStyle: {
        color: '#F2F2F3',
      },
    });
  }

  warning(message: string): CreateHotToastRef<Record<string, any> | unknown> {
    const template = `
    <div class="flex items-center gap-x-3">
      <span class='grow-0 shrink-0 basis-6'>
        <img class="w-6" src="assets/images/hot-toast-icons/warning.svg" alt="Error" style="margin-right: 8px;" />
      </span>
      <div class="sub-text-l tracking-wider">
        <div class="sub-text-xl w-full">
            Warning
        </div>
        <div class="w-full text-text-primary">
          ${message}
        </div>
      </div>
    </div>
  `;

    return this.toast.warning(template, {
      ...this.baseConfig,
      style: {
        padding: '12px',
        color: '#F2F2F3',
        backgroundColor: '#151418',
        borderRadius: '20px',
        border: '1px solid rgba(238, 180, 67, 0.12)',
      },
      closeStyle: {
        color: '#F2F2F3',
      },
    });
  }
}
