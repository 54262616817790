import {
  AfterViewInit,
  Directive,
  ElementRef,
  EventEmitter,
  OnDestroy,
  Output,
} from '@angular/core';
import { ScreenSizeService } from '@bupple/core';

@Directive({
  selector: '[resizeObserver]',
  standalone: true,
})
export class ResizeObserverDirective implements OnDestroy, AfterViewInit {
  @Output() resizeHeight = new EventEmitter<null>();

  private resizeObserver: ResizeObserver;
  private maxHeightOfMessageToScroll!: number;

  constructor(
    private elementRef: ElementRef,
    private screenSize: ScreenSizeService,
  ) {
    this.maxHeightOfMessageToScroll = this.screenSize.screenHeight - 200;

    this.resizeObserver = new ResizeObserver(([{ target }]) => {
      if (
        (target as HTMLDivElement)?.offsetHeight <
        this.maxHeightOfMessageToScroll
      ) {
        this.resizeHeight.emit();
      }
    });
  }

  ngAfterViewInit() {
    this.resizeObserver.observe(this.elementRef.nativeElement);
  }

  ngOnDestroy() {
    this.resizeObserver.disconnect();
  }
}
