<div class="p-4">
  <div
    class="grow justify-end flex flex-col h-[calc(100vh_-_60px)] p-4 border rounded-lg bg-[url('assets/images/background-studio-intro.jpeg')] bg-center bg-cover bg-no-repeat"
  >
    <h2 class="mt-6 font-medium">Use AI to Create Engaging Content</h2>
    <p class="text-xs text-base-content-secondary mt-2">
      Explore the most sophisticated tools.
    </p>
    <lib-button block class="mt-6" (clickEvent)="navigation.goIdeaboards()">
      Get Started
      <bupple-icon name="Outline / Arrows / Arrow Right"></bupple-icon>
    </lib-button>
  </div>
</div>
