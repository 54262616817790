export type trackItem = trackARollItem | TrackAudioItem;
export type trackARollItem = TrackVideoItem;

export interface TrackItemBase {
  id: string;
  type: TrackItemType;
  start_time: number;
  start_time_ms: number;
  end_time: number;
  end_time_ms: number;
  length: number;
  length_ms: number;
}

export interface TrackVideoItem extends TrackItemBase {
  asset: VideoAsset;
  asset_candidates: VideoAsset[];
}

export interface AssetBase {
  id: string;
  volume: number;
  duration: number;
  duration_ms: number;
}

export interface VideoAsset extends AssetBase {
  video_url: string;
  thumbnail_url: string;
}

export interface TrackAudioItem extends TrackItemBase {
  asset: AudioAsset;
}

export interface AudioAsset extends AssetBase {
  audio_url: string;
}

export enum TrackItemType {
  video = 'video',
  audio = 'audio',
  sound = 'sound',
  narration = 'narration',
}

export enum TimelineTrackType {
  bRoll = 'b_boll',
  aRoll = 'a_roll',
  audio = 'audio',
  caption = 'caption',
  text = 'text',
}
