<div class="p-6 bg-base-500">
  <h3 class="mb-4">Create new Scene</h3>
  <input type="text" class="input" placeholder="Name" [(ngModel)]="name" />

  <div class="flex mt-8 gap-4">
    <div class="grow">
      <lib-button block appearance="tonal" (clickEvent)="dialog.close()"
        >Cancel</lib-button
      >
    </div>
    <div class="grow">
      <lib-button block [disabled]="!name" (clickEvent)="dialog.close(name)"
        >Create</lib-button
      >
    </div>
  </div>
</div>
