import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  OnInit,
} from '@angular/core';
import { VideoEditorFacade } from '../+state/video-editor.facade';
import { VideoAsset } from '../interfaces/timeline-track-item.interface';

@Component({
  selector: 'lib-video-editor-elements',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './video-editor-elements.component.html',
  styleUrl: './video-editor-elements.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class VideoEditorElementsComponent implements OnInit {
  constructor(
    private videoEditorFacade: VideoEditorFacade,
    private cdr: ChangeDetectorRef,
  ) {}

  ngOnInit(): void {
    this.listenStore();
  }

  assetCandidates: VideoAsset[] = [];
  activeTrackItemAsset!: VideoAsset;
  listenStore() {
    this.videoEditorFacade.assetCandidates$.subscribe((candidates) => {
      this.assetCandidates = candidates;
      this.cdr.markForCheck();
    });
    this.videoEditorFacade.activeTrackItemAsset$.subscribe((asset) => {
      this.activeTrackItemAsset = asset;
      this.cdr.markForCheck();
    });
  }

  onClickAsset(asset: VideoAsset) {
    this.videoEditorFacade.replaceActiveTrackItemAsset(asset);
  }
}
