export const PATH = {
  root: '',
  intro: 'intro',

  // Auth
  auth: 'auth',
  signUp: 'sign-up',
  signIn: 'sign-in',
  forgotPassword: 'forget-password',
  resetPassword: 'reset-password',

  // Connect accounts
  connectAccounts: 'connect-accounts',
  home: '',

  studio: 'studio',

  ideaboards: 'ideaboards',
  ideaboard: 'ideaboard',
  chat: 'chat',
  makePost: 'make-post',
  schedule: 'schedule',
  published: 'published',
  posts: 'posts',
  scheduled: 'scheduled',
  pending: 'pending',
  plans: 'plans',
  planSchedule: 'plan-schedule',
  imageEditor: 'image-editor',
  videoEditor: 'video-editor',
  videoEditorCaptions: 'captions',
  videoEditorElements: 'elements',
  adminCreativeEditor: 'admin-creative-editor',
  profile: 'profile',

  // Hub
  hub: 'hub',

  notFound: '**',
  error: 'error',
};

export const PARAMS = {
  ideaboardId: 'ideaboardId',
};
