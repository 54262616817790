import { Injectable, inject } from '@angular/core';
import { Ideaboard, MessageAction, SocialNetwork } from '@bupple/core';
import { ListDto } from '@bupple/interfaces';
import { Store, select } from '@ngrx/store';
import { Observable } from 'rxjs';
import { v4 } from 'uuid';
import {
  CreateMessageDto,
  ReceivedMessageDto,
  SentMessageDto,
  message,
} from '../dtos/message.dto';
import { AvailablePostDto, PublishableDto } from '../dtos/post.dto';
import { MessageType } from '../enums/message-type.enum';
import * as StudioActions from './studio.actions';
import * as StudioSelectors from './studio.selectors';

@Injectable({
  providedIn: 'root',
})
export class StudioFacade {
  private readonly store = inject(Store);

  activeIdeaboard$ = this.store.pipe(select(StudioSelectors.activeIdeaboard));
  activeIdeaboardId$ = this.store.pipe(
    select(StudioSelectors.activeIdeaboardId),
  );
  activeIdeaboardChat$ = this.store.pipe(
    select(StudioSelectors.activeIdeaboardChat),
  ) as Observable<ListDto<message>>;
  chatInputValue$ = this.store.pipe(select(StudioSelectors.chatInputValue));
  chatMessage$ = this.store.pipe(select(StudioSelectors.chatMessage));
  homeInputChatMessage$ = this.store.pipe(
    select(StudioSelectors.homeInputChatMessage),
  );
  publishableId$ = this.store.pipe(select(StudioSelectors.publishableId));
  publishable$ = this.store.pipe(select(StudioSelectors.publishable));
  timeToScroll$ = this.store.pipe(select(StudioSelectors.timeToScroll));
  isRecievingMessage$ = this.store.pipe(
    select(StudioSelectors.isRecievingMessage),
  );
  selectedAction$ = this.store.pipe(select(StudioSelectors.selectedAction));
  ideaboards$ = this.store.pipe(select(StudioSelectors.ideaboards));
  recentIdeaboards$ = this.store.pipe(select(StudioSelectors.recentIdeaboards));
  homeIdeaboards$ = this.store.pipe(select(StudioSelectors.homeIdeaboards));
  posts$ = this.store.pipe(select(StudioSelectors.posts));
  videoProgress$ = this.store.pipe(select(StudioSelectors.videoProgress));
  videoNotif$ = this.store.pipe(select(StudioSelectors.videoNotif));
  isPostPublished$ = this.store.pipe(select(StudioSelectors.isPostPublished));
  setIdeaboards(ideaboards: ListDto<Ideaboard>): void {
    this.store.dispatch(StudioActions.setIdeaboards({ ideaboards }));
  }

  addNewIdeaboard(ideaboard: Ideaboard): void {
    this.store.dispatch(StudioActions.addNewIdeaboard({ ideaboard }));
  }

  removeIdeaboard(ideaboardId: string): void {
    this.store.dispatch(StudioActions.removeIdeaboard({ ideaboardId }));
  }

  setActiveIdeaboard(ideaboard: Ideaboard): void {
    this.store.dispatch(StudioActions.setActiveIdeaboard({ ideaboard }));
  }

  resetActiveIdeaboard(): void {
    this.store.dispatch(StudioActions.resetActiveIdeaboard());
  }

  updateIdeaboardName(name: string, id: string): void {
    this.store.dispatch(StudioActions.udpateIdeaboardName({ name, id }));
  }

  setChatMessage(message: CreateMessageDto | null): void {
    this.store.dispatch(StudioActions.setChatMessage({ value: message }));
  }

  setHomeInputChatMessage(message: CreateMessageDto | null): void {
    this.store.dispatch(
      StudioActions.setHomeInputChatMessage({ value: message }),
    );
  }

  setSelectedAction(action: MessageAction): void {
    this.store.dispatch(StudioActions.setSelectedAction({ action }));
  }

  resetSelectedAction(): void {
    this.store.dispatch(StudioActions.setSelectedAction({ action: null }));
  }

  setActiveIdeaboardChat(chat: ListDto<message>): void {
    this.store.dispatch(StudioActions.setActiveIdeaboardChat({ chat }));
  }

  prependNewChatMessages(chat: ListDto<message>): void {
    this.store.dispatch(StudioActions.prependNewChatMessages({ chat }));
  }

  setMockSentMessage(message: SentMessageDto): void {
    this.store.dispatch(StudioActions.setMockSentMessage({ message }));
  }

  removeMockSentMessage(): void {
    this.store.dispatch(StudioActions.removeMocklSentMessage());
  }

  setRealSentMessage(message: SentMessageDto): void {
    this.store.dispatch(StudioActions.setRealSentMessage({ message }));
  }

  setMockReceivedMessage(message: ReceivedMessageDto): void {
    this.store.dispatch(StudioActions.setMockReceivedMessage({ message }));
  }

  setLoadingAsAMessage(): void {
    const message: ReceivedMessageDto = {
      _id: v4(),
      created_at: '',
      file_full_path: '',
      ideaboard_id: '',
      sender_id: null,
      updated_at: '',
      type: MessageType.loading,
      content: '',
      chat_id: '',
      metadata: [],
      buttons: undefined,
      is_publishable: false,
    };
    this.store.dispatch(StudioActions.setLoadingAsAMessage({ message }));
  }
  removeLoadingMessage(): void {
    this.store.dispatch(StudioActions.removeLoadingMessage());
  }

  udpateMockReceivedMessageType(messageType: MessageType): void {
    this.store.dispatch(
      StudioActions.udpateMockReceivedMessageType({ messageType }),
    );
  }

  udpateMockReceivedMessageContent(content: string): void {
    this.store.dispatch(
      StudioActions.udpateMockReceivedMessageContent({ content }),
    );
  }

  setRealReceivedMessage(message: ReceivedMessageDto): void {
    this.store.dispatch(StudioActions.setRealReceivedMessage({ message }));
  }

  updateChatMessage(message: ReceivedMessageDto): void {
    this.store.dispatch(StudioActions.updateChatMessage({ message }));
  }

  setPublishableId(id: string): void {
    this.store.dispatch(StudioActions.setPublishableId({ id }));
  }

  setPublishable(publishable: PublishableDto): void {
    this.store.dispatch(
      StudioActions.setPublishable({ publishable: publishable }),
    );
  }

  resetPublishable(): void {
    this.store.dispatch(StudioActions.resetPublishable());
  }

  deleteMessage(messageId: string): void {
    this.store.dispatch(StudioActions.deleteMessage({ messageId }));
  }

  updateAvailablePost(availablePost: AvailablePostDto): void {
    this.store.dispatch(StudioActions.updateAvailablePost({ availablePost }));
  }

  reset() {
    this.store.dispatch(StudioActions.reset());
  }

  setIsReceivingMessage(value: boolean) {
    this.store.dispatch(StudioActions.setIsReceivingMessage({ value }));
  }

  SetPosts(posts: any[]) {
    this.store.dispatch(StudioActions.SetPosts({ posts }));
  }

  setVideoProgress(progress: number) {
    this.store.dispatch(StudioActions.setVideoProgress({ progress }));
  }
  setVideoNotif(text: string) {
    this.store.dispatch(StudioActions.setVideoNotif({ text }));
  }

  setIsPostPublished(value: boolean) {
    this.store.dispatch(StudioActions.setIsPostPublished({ value }));
  }
}
