import { Injectable } from '@angular/core';
import { HttpClientAdaptor } from '@bupple/util/adaptors';
import { Observable } from 'rxjs';
import { PlanResponseDTO } from '../dtos/plans.dtos';
import { ConnectUrlDto, RedirectUrl } from '@bupple/core';

@Injectable({
  providedIn: 'root',
})
export class PlanApi {
  constructor(private http: HttpClientAdaptor) {}

  getPlan(): Observable<PlanResponseDTO> {
    return this.http.get<PlanResponseDTO>('subscription/get-plan');
  }

  subscribePlan(
    plan_id: string,
    plan_price_id: string,
  ): Observable<ConnectUrlDto> {
    return this.http.post('subscription/subscribe', { plan_id, plan_price_id });
  }

  RedirectToPlanPanel(): Observable<RedirectUrl> {
    return this.http.get('subscription/panel');
  }
}
