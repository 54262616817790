export const COLORS_CLASS_MAP = new Map();
COLORS_CLASS_MAP.set('base-content', 'fill-base-content');
COLORS_CLASS_MAP.set('base-content-secondary', 'fill-base-content-secondary');
COLORS_CLASS_MAP.set('base-content-tertiary', 'fill-base-content-tertiary');
COLORS_CLASS_MAP.set('primary', 'fill-primary');
COLORS_CLASS_MAP.set('secondary', 'fill-secondary');
COLORS_CLASS_MAP.set('error', 'fill-error');
COLORS_CLASS_MAP.set('warning', 'fill-warning');
COLORS_CLASS_MAP.set('success', 'fill-success');
COLORS_CLASS_MAP.set('accent', 'fill-accent');
COLORS_CLASS_MAP.set('base-100', 'fill-base-100');
COLORS_CLASS_MAP.set('disable-icon', 'fill-base-content/50');

export const SIZE_CLASS_MAP = new Map();
SIZE_CLASS_MAP.set('xxs', 'w-3 h-3');
SIZE_CLASS_MAP.set('xs', 'w-3.5 h-3.5');
SIZE_CLASS_MAP.set('sm', 'w-4 h-4');
SIZE_CLASS_MAP.set('md', 'w-5 h-5');
SIZE_CLASS_MAP.set('lg', 'w-6 h-6');
SIZE_CLASS_MAP.set('xl', 'w-7 h-7');
SIZE_CLASS_MAP.set('2xl', 'w-10 h-10');
SIZE_CLASS_MAP.set('3xl', 'w-12 h-12');
SIZE_CLASS_MAP.set('free', 'w-auto h-auto');
