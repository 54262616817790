import { Injectable } from '@angular/core';
import { AudioAsset } from '../interfaces/timeline-track-item.interface';

@Injectable()
export class VideoEditorDomService {
  getAudioElement(asset: AudioAsset) {
    const audio = document.createElement('audio');
    audio.src = asset.audio_url;
    audio.autoplay = false;
    audio.pause();
    return audio;
  }
}
