import { Route } from '@angular/router';
import { AuthorizedGuard, PATH, TITLE } from '@bupple/core';

export const featureChatRoutes: Route = {
  path: PATH.chat + '/' + ':id',
  title: TITLE.chat,
  canActivate: [AuthorizedGuard],
  loadComponent: () =>
    import('./feature-chat/feature-chat.component').then(
      (c) => c.FeatureChatComponent,
    ),
};
