import { Injectable, signal } from '@angular/core';
import { fromEvent } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { ScreenSize } from '../enums/screen-size.enum';

@Injectable({
  providedIn: 'root',
})
export class ScreenSizeService {
  private currentSize = signal(this.detectScreenSize());

  constructor() {
    this.listenToResizeEvents();
  }

  private listenToResizeEvents(): void {
    fromEvent(window, 'resize')
      .pipe(debounceTime(10))
      .subscribe(() => {
        this.currentSize.set(this.detectScreenSize()); // Update the signal value
      });
  }

  private detectScreenSize(): ScreenSize {
    const width = window.innerWidth;
    if (width < 640) {
      return ScreenSize.xs;
    } else if (width >= 640 && width < 768) {
      return ScreenSize.sm;
    } else if (width >= 768 && width < 1024) {
      return ScreenSize.md;
    } else if (width >= 1024 && width < 1280) {
      return ScreenSize.lg;
    } else if (width >= 1280 && width < 1536) {
      return ScreenSize.xl;
    } else {
      return ScreenSize.xxl;
    }
  }

  get isXs(): boolean {
    return this.currentSize() === ScreenSize.xs;
  }

  get isSm(): boolean {
    return this.currentSize() === ScreenSize.sm;
  }

  get isMd(): boolean {
    return this.currentSize() === ScreenSize.md;
  }

  get isLg(): boolean {
    return this.currentSize() === ScreenSize.lg;
  }

  get isXl(): boolean {
    return this.currentSize() === ScreenSize.xl;
  }

  get is2xl(): boolean {
    return this.currentSize() === ScreenSize.xxl;
  }

  get screenHeight(): number {
    return window.innerHeight;
  }
}
