import { v4 } from 'uuid';

export interface SubtitleSegment {
  id: string;
  seek: number;
  start: number;
  end: number;
  text: string;
  temperature: number;
  avg_logprob: number;
  compression_ratio: number;
  no_speech_prob: number;
  words: SubtitleWord[];
}

export class SegmentLine {
  id: string;
  segmentId: string;
  words: SubtitleWord[];
  text: string;
  segmentText: string;
  start: number;
  end: number;

  constructor(segmentId: string) {
    this.id = v4();
    this.segmentId = segmentId;
    this.words = [];
    this.text = '';
    this.segmentText = '';
    this.start = 0;
    this.end = 0;
  }
}

export interface SubtitleWord {
  id?: string;
  word: string;
  start: number;
  end: number;
}
