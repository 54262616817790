<div
  class="chat chat-end flex flex-col justify-end items-end px-3 overflow-hidden md:items-start"
  resizeObserver
  (resizeHeight)="onResize()"
>
  <!-- Chat Bubble -->
  <div
    class="chat-bubble bg-gradient-to-r from-info to-primary-focus md:!rounded-br-2xl md:rounded-bl-none"
    [ngClass]="{
      'p-1':
        message.type === messageType.image ||
        message.type === messageType.video,
    }"
  >
    <bupple-sent-chat-message-text
      *ngIf="message.type === messageType.text"
      [message]="message"
    ></bupple-sent-chat-message-text>
    <bupple-sent-chat-message-link
      *ngIf="message.type === messageType.link"
      [message]="message"
    ></bupple-sent-chat-message-link>
    <bupple-sent-chat-message-image
      *ngIf="message.type === messageType.image"
      [message]="message"
    ></bupple-sent-chat-message-image>
    <bupple-sent-chat-message-video
      *ngIf="message.type === messageType.video"
      [message]="message"
    ></bupple-sent-chat-message-video>

    <!-- Created At -->
    <p
      class="text-xs text-right mt-2 text-base-content-secondary"
      *ngIf="message?.type !== messageType.loading"
      [ngClass]="{
        'px-3':
          message.type === messageType.image ||
          message.type === messageType.video,
      }"
    >
      {{ message.created_at | shortTime }}
    </p>

    <div
      *ngIf="message?.buttons?.length"
      class="pt-4 pb-3 flex flex-col gap-3 md:flex-row flex-wrap"
      [ngClass]="{
        'md:flex-col p-2':
          message.type === messageType.image ||
          message.type === messageType.video,
      }"
    >
      <lib-button
        size="sm"
        (clickEvent)="onClickMakePost()"
        *ngIf="message.is_publishable"
      >
        Publish / Schedule
      </lib-button>

      @for (button of message.buttons; track trackBy.id) {
        <lib-button
          [block]="screenSize.isXs ? true : false"
          size="sm"
          (clickEvent)="onClickAction(button)"
        >
          {{ button.title }}
        </lib-button>
      }
    </div>
  </div>

  <!-- Side Buttons -->
  <div class="flex gap-2 mt-2">
    <lib-button
      size="sm"
      square
      color="neutral"
      appearance="tonal"
      [class.hidden]="!message.ideaboard_id"
      (clickEvent)="onClickCopyText()"
      *ngIf="message.type === messageType.text"
    >
      <bupple-icon
        size="sm"
        name="Outline / Essentional, UI / Copy"
      ></bupple-icon>
    </lib-button>
    <lib-button
      square
      color="neutral"
      appearance="tonal"
      [class.hidden]="!message.ideaboard_id"
      (clickEvent)="onClickDelete()"
      size="sm"
    >
      <bupple-icon
        size="sm"
        name="Outline / Essentional, UI / Trash Bin Trash"
      ></bupple-icon>
    </lib-button>
  </div>
</div>
