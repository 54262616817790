<div class="h-screen flex overflow-hidden">
  <aside class="flex">
    <lib-video-editor-menu></lib-video-editor-menu>
    <lib-video-editor-sidebar></lib-video-editor-sidebar>
  </aside>
  <main class="flex flex-col grow">
    <lib-video-editor-toolbar></lib-video-editor-toolbar>
    <lib-video-editor-canvas
      class="flex grow overflow-y-auto bg-base-content/12 p-4"
    ></lib-video-editor-canvas>
    <lib-video-editor-timeline></lib-video-editor-timeline>
  </main>
</div>
