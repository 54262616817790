<div class="flex px-3 text-xs text-base-content-tertiary">
  <span class="">{{ _line.start | number }}</span>
  <span class="mx-2">-</span>
  <span class="">{{ _line.end | number }}</span>
</div>
<div class="flex flex-wrap py-2">
  <input
    *ngFor="let word of _line.words; let i = index; trackBy: trackBy.id"
    type="text"
    class="input input-xs px-1 py-1 text-xs focus:bg-base-500 focus:outline-none hover:bg-base-500 w-auto"
    #textInput
    [(ngModel)]="_line.words[i].word"
    [attr.size]="_line.words[i].word.length + 1 || 1"
  />
</div>
