import { Route } from '@angular/router';
import { AuthorizedGuard, PATH, TITLE } from '@bupple/core';

export const pricePlansRoutes: Route = {
  path: PATH.plans,
  title: TITLE.plans,
  canActivate: [AuthorizedGuard],
  loadComponent: () =>
    import('./feature-plans/feature-plans.component').then(
      (c) => c.FeaturePlansComponent,
    ),
  // children: [
  //   {
  //     path: '',
  //     loadComponent: () =>
  //       import('./price-plans/price-plans.component').then(
  //         (c) => c.PricePlansComponent,
  //       ),
  //   },
  //   {
  //     path: PATH.planSchedule,
  //     canActivate: [AuthorizedGuard],
  //     loadComponent: () =>
  //       import('./after-price-plan/after-price-plan.component').then(
  //         (c) => c.AfterPricePlanComponent,
  //       ),
  //   },
  // ],
};
