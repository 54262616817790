<div
  class="btm-nav md:hidden h-32 items-end pb-6 px-8 bg-gradient-to-t from-base-100 via-base-100/90 to-base-100/0 from-10% via-60% to-100% bg-transparent">
  @for (item of menuService.list; track $index) {
  <button class="group max-h-[50px]" [routerLink]="item.link" routerLinkActive="menu-item--active"
    [routerLinkActiveOptions]="{ exact: true }">
    <bupple-icon [name]="item.icon || ''" className="icon-menu" class="group-[.menu-item--active]:hidden"></bupple-icon>
    <bupple-icon [name]="item.iconActive || ''" className="icon-menu"
      class="group-[:not(.menu-item--active)]:hidden"></bupple-icon>
    <span class="btm-nav-label sub-text-md group-[:not(.menu-item--active)]:hidden text-base-content tracking-[1.5px]">
      {{ item.title}}
    </span>
  </button>
  }
</div>