import { Injectable } from '@angular/core';
import { ListDto } from '@bupple/interfaces';
import { HttpClientAdaptor } from '@bupple/util/adaptors';
import { AssetDefinition } from '@cesdk/cesdk-js';
import { firstValueFrom, Observable } from 'rxjs';
import {
  CreateSceneDto,
  DeleteSceneResultDto,
  GetSceneDto,
  UploadAssetResult,
} from '../dtos/scene.dto';

@Injectable()
export class SceneApi {
  constructor(private http: HttpClientAdaptor) {}
  private endpointHead = 'admin/scene/';

  getAll(page: number): Observable<ListDto<GetSceneDto>> {
    return this.http.get<ListDto<GetSceneDto>>(
      `${this.endpointHead}table?page=${page}`,
    );
  }

  get(id: string): Observable<GetSceneDto> {
    return this.http.get<GetSceneDto>(this.endpointHead + 'id/' + id);
  }

  add(model?: CreateSceneDto): Observable<GetSceneDto> {
    return this.http.post<GetSceneDto>(
      this.endpointHead + 'save',
      model || null,
    );
  }

  update(sceneId: string, scene: CreateSceneDto): Observable<GetSceneDto> {
    return this.http.post<GetSceneDto>(
      this.endpointHead + 'update/' + sceneId,
      scene,
    );
  }

  delete(id: string): Observable<DeleteSceneResultDto> {
    return this.http.post<DeleteSceneResultDto>(
      this.endpointHead + 'delete/' + id,
      null,
    );
  }

  getGlobalAsssets(): Observable<AssetDefinition[]> {
    return this.http.get(this.endpointHead + 'global-assets');
  }

  uploadAsset(sceneId: string, file: File): Observable<UploadAssetResult> {
    const data = new FormData();
    data.append('asset', file);

    return this.http.post<UploadAssetResult>(
      this.endpointHead + 'upload-asset/' + sceneId,
      data,
    );
  }

  uploadGlobalAsset(file: File): Promise<UploadAssetResult> {
    const data = new FormData();
    data.append('asset', file);

    return firstValueFrom(
      this.http.post<UploadAssetResult>(
        this.endpointHead + 'upload-global-asset',
        data,
      ),
    );
  }
}
