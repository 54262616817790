import { Pipe, PipeTransform } from '@angular/core';
import * as dayjs from 'dayjs';

@Pipe({
  name: 'shortTime',
  pure: true,
  standalone: true,
})
export class ShortTimePipe implements PipeTransform {
  transform(date: string | undefined): any {
    return date ? dayjs(date).format('hh:mm') : '';
  }
}
