import { AsyncPipe, CommonModule } from '@angular/common';
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { ActivatedRoute } from '@angular/router';
import {
  MessageAction,
  ModalResult,
  NavigationService,
  ToasterService,
} from '@bupple/core';
import { ListDto } from '@bupple/interfaces';
import {
  ChatActionButton,
  IdeaboardChatService,
  message,
  StudioFacade,
} from '@bupple/studio/data-access';
import { UiIconComponent } from '@bupple/ui/icon';
import { UiLoadingComponent } from '@bupple/ui/loading';
import { TrackByService } from '@bupple/util/services';
import { filter, fromEvent } from 'rxjs';
import { SubSink } from 'subsink';
import { ChatFeedbackComponent } from '../chat-feedback/chat-feedback.component';
import { DeleteMessageConfirmationComponent } from '../delete-message-confirmation/delete-message-confirmation.component';
import { ReceivedChatMessageComponent } from '../received-chat-message/received-chat-message.component';
import { SentChatMessageComponent } from '../sent-chat-message/sent-chat-message.component';
import { ChatActionService } from '../services/chat-action.service';
import { SseResponseHandlerService } from '../services/sse-handler.service';

@Component({
  selector: 'bupple-chat-body',
  standalone: true,
  imports: [
    CommonModule,
    SentChatMessageComponent,
    ReceivedChatMessageComponent,
    UiLoadingComponent,
    UiIconComponent,
  ],
  providers: [
    StudioFacade,
    ChatActionService,
    SseResponseHandlerService,
    TrackByService,
    IdeaboardChatService,
    ToasterService,
    AsyncPipe,
  ],
  templateUrl: './chat-body.component.html',
  styleUrl: './chat-body.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ChatBodyComponent implements AfterViewInit, OnInit, OnDestroy {
  constructor(
    private studioFacade: StudioFacade,
    protected chatActionService: ChatActionService,
    protected trackBy: TrackByService,
    private cdr: ChangeDetectorRef,
    protected ideaboardChatService: IdeaboardChatService,
    private toaster: ToasterService,
    protected navigation: NavigationService,
    private route: ActivatedRoute,
    private bottomSheet: MatBottomSheet,
  ) {}

  subsink = new SubSink();
  chat: ListDto<message> | null = null;
  isLoadingChat = true;
  @ViewChild('chatBody', { static: false }) chatBody?: ElementRef =
    new ElementRef(null);

  ngOnInit(): void {
    this.listenStore();
  }

  get showLoadMoreSection(): boolean {
    return Boolean(this.chat?.next_page_url) && this.isLoadingMoreChat;
  }

  ideaboardId = '';
  isRecievingMessage = false;
  selectedAction: MessageAction | null = null;
  listenStore() {
    this.subsink.sink = this.studioFacade.activeIdeaboardId$.subscribe((id) => {
      this.ideaboardId = id;
    });

    this.subsink.sink = this.studioFacade.activeIdeaboardChat$.subscribe(
      (chat) => {
        const isTheFirstChatList = this.chat === null && chat !== null;

        const noChatIsReceived = chat === null;
        this.isLoadingChat = noChatIsReceived;

        this.chat = chat;
        this.cdr.detectChanges();

        isTheFirstChatList && this.scrollToTheBottomOfChatBody();

        if (this.isLoadingMoreChat) {
          this.scrollToThePointBeforeLoadingMoreMessages();
          this.isLoadingMoreChat = false;
          this.cdr.markForCheck();
        }
      },
    );

    this.subsink.sink = this.studioFacade.timeToScroll$.subscribe(() => {
      this.scrollToTheBottomOfChatBody();
    });

    this.subsink.sink = this.studioFacade.isRecievingMessage$.subscribe(
      (result) => {
        this.isRecievingMessage = result;
      },
    );

    this.subsink.sink = this.studioFacade.selectedAction$.subscribe(
      (action) => {
        this.selectedAction = action;
        this.cdr.markForCheck();
      },
    );
  }

  ngAfterViewInit() {
    this.listenScrollEvent();
  }

  listenScrollEvent() {
    this.subsink.sink = fromEvent(this.chatBody?.nativeElement, 'scroll')
      .pipe(
        filter(
          (event: unknown) =>
            ((event as Event)?.target as HTMLElement)?.scrollTop === 0 &&
            this.CanLoadMoreMessages,
        ),
      )
      .subscribe(() => {
        this.loadMoreMessages();
      });
  }

  get CanLoadMoreMessages(): boolean {
    return !this.isLoadingMoreChat && this.chat?.next_page_url !== null;
  }

  @ViewChild('chatBodyInner', { static: false }) chatBodyInner?: ElementRef =
    new ElementRef(null);
  scrollToThePointBeforeLoadingMoreMessages() {
    setTimeout(() => {
      this.scrollTo(
        this.chatBody as ElementRef,
        this.chatBodyInner?.nativeElement.scrollHeight -
          this.scrollHeightBeforeLoadMore,
      );
    }, 0);
  }

  getChat(ideaboardId: string, page: number) {
    this.subsink.sink = this.ideaboardChatService
      .getAll(ideaboardId, page)
      .subscribe();
  }

  isLoadingMoreChat = false;
  scrollHeightBeforeLoadMore!: number;
  async loadMoreMessages() {
    this.isLoadingMoreChat = true;
    this.cdr.markForCheck();

    this.scrollHeightBeforeLoadMore =
      this.chatBodyInner?.nativeElement.scrollHeight;

    this.getChat(this.ideaboardId, (this.chat?.current_page as number) + 1);
  }

  countOnScrollToThebottom = 1;
  scrollToTheBottomOfChatBody() {
    setTimeout(() => {
      this.scrollTo(
        this.chatBody as ElementRef,
        this.chatBodyInner?.nativeElement?.offsetHeight,
      );
    }, 0);
  }

  private scrollTo(el: ElementRef, to: number) {
    if (el?.nativeElement) {
      (el?.nativeElement as HTMLElement).scrollTop = to;
    }
  }

  onAction(actionButton: ChatActionButton, message: message) {
    this.subsink.sink = this.chatActionService
      .btn(message, actionButton.func)
      .subscribe();
  }

  onClickDeleteMeesage(message: message) {
    const bottomSheetRef = this.bottomSheet
      .open(DeleteMessageConfirmationComponent)
      .afterDismissed();

    this.subsink.sink = bottomSheetRef.subscribe((result) => {
      if (result) {
        this.subsink.sink = this.ideaboardChatService
          .delete(message._id)
          .subscribe();
      }
    });
  }

  onClickDislike(message: message) {
    const bottomSheetRef = this.bottomSheet
      .open(ChatFeedbackComponent, {
        data: message,
      })
      .afterDismissed();

    this.subsink.sink = bottomSheetRef.subscribe((result: ModalResult) => {
      result?.data && this.toaster.success('Thank you for your feedback!');
    });
  }

  ngOnDestroy(): void {
    this.subsink.unsubscribe();
  }
}
