import { HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { StorageService } from '@bupple/core';
import { SseClient } from 'ngx-sse-client';
import { Observable } from 'rxjs';

@Injectable()
export class SseService {
  constructor(
    private storage: StorageService,
    private sseClient: SseClient,
  ) {}

  sse(url: string): Observable<any> {
    const headers = new HttpHeaders().set(
      'Authorization',
      `Bearer ` + this.storage.token,
    );

    return this.sseClient.stream(
      url,
      { keepAlive: true, reconnectionDelay: 1_000, responseType: 'event' },
      { headers },
      'GET',
    );
  }
}
