import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { TrackByService } from '@bupple/util/services';
import { SubSink } from 'subsink';
import { VideoEditorFacade } from '../+state/video-editor.facade';
import { SegmentLine } from '../interfaces/subtitle.interface';
import { SubtitleService } from '../services/subtitle.service';
import { SubtitleLineSkeletonComponent } from '../subtitle-line-skeleton/subtitle-line-skeleton.component';
import { SubtitleLineComponent } from '../subtitle-line/subtitle-line.component';

@Component({
  selector: 'lib-video-editor-captions',
  standalone: true,
  imports: [CommonModule, SubtitleLineComponent, SubtitleLineSkeletonComponent],
  providers: [TrackByService, SubtitleService],
  templateUrl: './video-editor-captions.component.html',
  styleUrl: './video-editor-captions.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class VideoEditorCaptionsComponent implements OnInit, OnDestroy {
  constructor(
    protected videoEditorFacade: VideoEditorFacade,
    private subtitleService: SubtitleService,
    protected trackBy: TrackByService,
    private cdr: ChangeDetectorRef,
  ) {}

  subsink = new SubSink();

  ngOnInit(): void {
    this.listenStore();
  }

  lines: SegmentLine[] = [];
  listenStore() {
    this.subsink.sink = this.videoEditorFacade.subtitleLines$.subscribe(
      (lines) => {
        this.lines = lines;
        this.cdr.markForCheck();
      },
    );
  }

  onChangeLineText(line: SegmentLine) {
    const modifiedSegmentText =
      this.subtitleService.getUpdateSegmentByLine(line);

    this.videoEditorFacade.updateSubtitleSegment(
      line.segmentId,
      modifiedSegmentText,
      line.words,
    );
  }

  ngOnDestroy(): void {
    this.subsink.unsubscribe();
  }
}
