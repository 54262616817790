<div
  class="flex justify-between items-center pb-2 mx-w-[21.43rem] md:flex-row-reverse md:gap-x-2 md:h-fit md:bg-base-content/8 md:rounded-full md:border md:border-base-content/4 md:py-1 md:pl-1 md:pr-3 md:w-fit"
>
  <p
    class="title-base-bold md:sub-text-xl md:text-base-content-secondary md:leading-4"
  >
    {{ title }}
  </p>
  <span
    class="w-7 h-7 rounded-full flex justify-center items-center bg-base-content/4"
  >
    <bupple-icon color="secondary" [name]="icon"></bupple-icon>
  </span>
</div>
