import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { CoreFacade, NavigationService, SocialNetwork } from '@bupple/core';
import { SubSink } from 'subsink';
import { ConnectAccountCardComponent } from '../connect-account-card/connect-account-card.component';
import { IntegrationsService } from '../services/integrations.service';

@Component({
  selector: 'bupple-connect-account-tiktok',
  standalone: true,
  imports: [CommonModule, ConnectAccountCardComponent],
  templateUrl: './connect-account-tiktok.component.html',
  styleUrl: './connect-account-tiktok.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ConnectAccountTiktokComponent implements OnInit {
  socialMedial = SocialNetwork;
  connectedAccounts: any[] = [];
  constructor(
    private coreFacade: CoreFacade,
    private integrationsService: IntegrationsService,
    protected navigation: NavigationService,
  ) {}

  subsink = new SubSink();
  ngOnInit(): void {
    this.connectedAccountsHandler();
  }

  onClickConnectTiktok() {
    this.integrationsService.connectAccount('tiktok').subscribe();
  }
  connectedAccountsHandler() {
    this.coreFacade.user$.subscribe((user) => {
      // Assuming user?.linked_accounts is an array of LinkedAccount objects
      this.connectedAccounts = (user?.linked_accounts as any[])?.filter(
        (account) => account.nw === SocialNetwork.tiktok,
      );
    });
  }
}
