export enum MessageType {
  text = 'txt',
  idea = 'idea',
  story = 'story',
  notification = 'notif',
  link = 'lnk',
  script = 'script',
  image = 'img',
  images = 'images',
  video = 'vid',
  sound = 'snd',
  creative = 'creative',
  loading = 'loading',
  error = 'ERR',
}
