import { InProgress } from '@bupple/interfaces';
import { IdeaboardDto } from '../dtos/ideaboard.dto';

export class Ideaboard extends InProgress {
  __dto: IdeaboardDto;
  _id: string;
  name: string;
  updated_at: string;
  user_id: string;

  constructor(dto: IdeaboardDto) {
    super();

    this.__dto = dto;
    this._id = dto._id;
    this.name = dto.name as string;
    this.updated_at = dto.updated_at;
    this.user_id = dto.user_id;
    this.inProgress = false;
  }
}
