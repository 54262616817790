import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { RouterModule } from '@angular/router';
import { ButtonComponent } from '@bupple/button';
import {
  AuthService,
  CoreFacade,
  GetUserDto,
  NavigationService,
} from '@bupple/core';
import {
  ConnectAccountFacebookComponent,
  ConnectAccountLinkedinComponent,
  ConnectAccountsInstagramComponent,
  ConnectAccountTiktokComponent,
  ConnectAccountTwitchComponent,
  ConnectAccountTwitterComponent,
} from '@bupple/feature/connect-accounts';
import { TopPatternComponent } from '@bupple/top-pattern';
import { UiIconComponent } from '@bupple/ui/icon';
import { CreditPreviewComponent } from 'libs/feature/price-plans/src/lib/credit-preview/credit-preview.component';
import { SubSink } from 'subsink';
import { SignoutConfirmationComponent } from '../sign-out-confirmation/sign-out-confirmation.component';
@Component({
  selector: 'bupple-profile',
  standalone: true,
  imports: [
    CommonModule,
    UiIconComponent,
    RouterModule,
    ConnectAccountsInstagramComponent,
    ConnectAccountFacebookComponent,
    ConnectAccountTwitchComponent,
    ConnectAccountTwitterComponent,
    TopPatternComponent,
    ConnectAccountLinkedinComponent,
    ConnectAccountTiktokComponent,
    ButtonComponent,
    CreditPreviewComponent,
  ],
  templateUrl: './profile.component.html',
  styleUrl: './profile.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProfileComponent implements OnInit {
  profileItems: ProfileItem[] = [
    // {
    //   name: 'Posts',
    //   icon: 'Outline / Notes / Archive Check',
    //   iconType: 'outline',
    //   route: '/studio/posts/schedule',
    //   link: '',
    // },
    {
      name: 'Premium Plans',
      icon: 'Outline / Essentional, UI / Crown Star',
      iconType: 'outline',
      route: '/studio/plans',
      link: '',
    },
    // {
    //   name: 'What’s New',
    //   icon: 'Outline / Nature, Travel / Fire Minimalistic',
    //   iconType: 'outline',
    //   route: '',
    //   link: 'https://bupple.io/',
    // },
    {
      name: 'Contact Us',
      icon: 'Outline / Call / Phone',
      iconType: 'outline',
      route: '',
      link: 'https://bupple.io/contact-us',
    },
    {
      name: 'About Bupple',
      icon: 'Outline / Essentional, UI / Info Square',
      iconType: 'outline',
      route: '',
      link: 'https://bupple.io/about-us',
    },
    {
      name: 'Privacy Policy',
      icon: 'Outline / Security / Shield Up',
      iconType: 'outline',
      route: '',
      link: 'https://bupple.io/privacy-policy',
    },
    {
      name: 'Terms of Service',
      icon: 'Outline / Security / Shield Up',
      iconType: 'outline',
      route: '',
      link: 'https://bupple.io/terms-of-service',
    },
  ];

  constructor(
    private authService: AuthService,
    private coreFacade: CoreFacade,
    protected navigation: NavigationService,
    private bottomSheet: MatBottomSheet,
  ) {}
  user!: GetUserDto | null;
  ngOnInit(): void {
    this.coreFacade.user$.subscribe((user) => {
      this.user = user;
    });
  }

  subsink = new SubSink();
  signout() {
    this.subsink.sink = this.bottomSheet
      .open(SignoutConfirmationComponent)
      .afterDismissed()
      .subscribe((result) => {
        result && this.authService.logout();
      });
  }
}

export interface ProfileItem {
  name: string;
  icon: string;
  iconType: any;
  route: string;
  link: string;
}
