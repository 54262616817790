import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { RouterModule } from '@angular/router';
import { ButtonComponent } from '@bupple/button';
import { NavigationService } from '@bupple/core';
import { UiIconComponent } from '@bupple/ui/icon';

@Component({
  selector: 'bupple-feature-intro',
  standalone: true,
  imports: [
    CommonModule,
    RouterModule,
    MatIconModule,
    UiIconComponent,
    ButtonComponent,
  ],
  templateUrl: './feature-intro.component.html',
  styleUrls: ['./feature-intro.component.scss'],
})
export class FeatureIntroComponent {
  constructor(protected navigation: NavigationService) {}
}
