import { Route } from '@angular/router';
import { PATH, UnauthorizedGuard } from '@bupple/core';

export const featureIntroRoutes: Route = {
  path: PATH.intro,
  canActivate: [UnauthorizedGuard],
  loadComponent: () =>
    import('./feature-intro/feature-intro.component').then(
      (c) => c.FeatureIntroComponent,
    ),
};
