import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  OnInit,
} from '@angular/core';
import { ActivatedRoute, RouterModule } from '@angular/router';
import { ButtonComponent } from '@bupple/button';
import { NavigationService, PATH } from '@bupple/core';
import { StudioFacade } from '@bupple/studio/data-access';
import { UiIconComponent } from '@bupple/ui/icon';
import { MenuService } from '@bupple/util/services';
import { SubSink } from 'subsink';

@Component({
  selector: 'lib-video-editor-menu',
  standalone: true,
  imports: [CommonModule, UiIconComponent, RouterModule, ButtonComponent],
  providers: [MenuService],
  templateUrl: './video-editor-menu.component.html',
  styleUrl: './video-editor-menu.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class VideoEditorMenuComponent implements OnInit {
  constructor(
    protected navigation: NavigationService,
    private route: ActivatedRoute,
    private studioFacade: StudioFacade,
    private cdr: ChangeDetectorRef,
  ) {}

  path = PATH;
  ngOnInit(): void {
    this.listenRoute();
    this.listenStore();
  }

  videoId!: string;
  view!: string;
  listenRoute() {
    this.route.params.subscribe((params) => {
      this.videoId = params['video-id'];
    });

    this.route.queryParams.subscribe((queryParams) => {
      this.view = queryParams['view'];
    });
  }

  subsink = new SubSink();
  ideaboardId!: string;
  listenStore() {
    this.subsink.sink = this.studioFacade.activeIdeaboardId$.subscribe((id) => {
      this.ideaboardId = id;
      this.cdr.markForCheck();
    });
  }

  onClickMenuElements() {
    this.view === 'elements'
      ? this.navigation.goVideoEditor(this.ideaboardId, this.videoId)
      : this.navigation.goVideoEditorElements(this.ideaboardId, this.videoId);
  }

  onClickMenuCaptions() {
    this.view === 'captions'
      ? this.navigation.goVideoEditor(this.ideaboardId, this.videoId)
      : this.navigation.goVideoEditorCaptions(this.ideaboardId, this.videoId);
  }
}
