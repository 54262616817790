import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';
import { SafeURLPipe } from '@bupple/util/pipes';
import { finalize } from 'rxjs';
import { SubSink } from 'subsink';
import { LinkPreview } from '../interfaces/link-preview.interface';
import { LinkPreviewService } from './link-preview.service';

@Component({
  selector: 'bupple-link-preview',
  standalone: true,
  imports: [CommonModule, SafeURLPipe],
  providers: [LinkPreviewService],
  templateUrl: './link-preview.component.html',
  styleUrls: ['./link-preview.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LinkPreviewComponent {
  constructor(
    private linkPreviewService: LinkPreviewService,
    private cdr: ChangeDetectorRef,
  ) {}

  @Input({
    required: true,
  })
  set link(value: string) {
    if (value) {
      this.subsink.unsubscribe();
      this.getLinkPreview(value);
    } else {
      this.linkPreview = undefined;
      this.change.emit(undefined);
    }
  }

  @Output()
  change: EventEmitter<LinkPreview> = new EventEmitter();

  subsink = new SubSink();
  linkPreview?: LinkPreview;
  getLinkPreview(url: string) {
    this.subsink.sink = this.linkPreviewService
      .get(url)
      .pipe(
        finalize(() => {
          this.cdr.markForCheck();
        }),
      )
      .subscribe({
        next: (result) => {
          this.linkPreview = result;
          this.change.emit(result);
        },
        error: (error) => {
          this.linkPreview = undefined;
          this.change.emit(undefined);
        },
      });
  }

  get description(): string {
    return this.linkPreview
      ? this.linkPreview.description.substring(0, 200) + ' ...'
      : '';
  }
}
