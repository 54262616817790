import { Injectable } from '@angular/core';
import { HttpClientAdaptor } from '@bupple/util/adaptors';
import { Observable } from 'rxjs';
import { PromotionBannerDto } from '../dtos/banner.dto';
@Injectable({
  providedIn: 'root',
})
export class BannersService {
  constructor(private http: HttpClientAdaptor) {}

  getSubscribeBannerData(): Observable<PromotionBannerDto> {
    return this.http.get<PromotionBannerDto>('banner/subscribe');
  }
}
