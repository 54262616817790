import { CommonModule } from '@angular/common';
import { Component, Inject } from '@angular/core';
import {
  MAT_BOTTOM_SHEET_DATA,
  MatBottomSheetModule,
  MatBottomSheetRef,
} from '@angular/material/bottom-sheet';
import { ButtonComponent } from '@bupple/button';
import { Ideaboard } from '@bupple/core';

@Component({
  selector: 'bupple-delete-ideaboard',
  standalone: true,
  imports: [CommonModule, MatBottomSheetModule, ButtonComponent],
  templateUrl: './delete-ideaboard.component.html',
  styleUrls: ['./delete-ideaboard.component.scss'],
})
export class DeleteIdeaboardComponent {
  constructor(
    protected bottomSheetRef: MatBottomSheetRef<DeleteIdeaboardComponent>,
    @Inject(MAT_BOTTOM_SHEET_DATA) protected data: Ideaboard,
  ) {}
}
