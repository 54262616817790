import { Route } from '@angular/router';
import { AuthorizedGuard, PATH, TITLE } from '@bupple/core';

export const featureEditorRoutes: Route = {
  path: PATH.imageEditor + '/' + ':id',
  title: TITLE.editor,
  canActivate: [AuthorizedGuard],
  loadComponent: () =>
    import('./feature-editor/feature-editor.component').then(
      (c) => c.FeatureEditorComponent,
    ),
};
