import { Action, createReducer, on } from '@ngrx/store';
import { cloneDeep } from 'lodash-es';
import {
  SubtitleSegment,
  SubtitleWord,
} from '../interfaces/subtitle.interface';
import {
  TrackAudioItem,
  trackItem,
} from '../interfaces/timeline-track-item.interface';
import * as VideoEditorActions from './video-editor.actions';

export const VIDEO_EDITOR_FEATURE_KEY = 'videoEditor';

export interface VideoEditorState {
  trackItems: trackItem[];
  activeTrackItemId: string | null;
  isPlaying: boolean;
  totalTimeSpent: number;
  segments: SubtitleSegment[];
  audio: TrackAudioItem | null;
  activeVideoIsReady: boolean;
  activeVideoIsPlaying: boolean;
  audioIsReady: boolean;
}

export const initialVideoEditorState: VideoEditorState = {
  trackItems: [],
  isPlaying: false,
  totalTimeSpent: 0,
  activeTrackItemId: null,
  segments: [],
  audio: null,
  activeVideoIsReady: false,
  activeVideoIsPlaying: false,
  audioIsReady: false,
};

const reducer = createReducer(
  initialVideoEditorState,
  on(VideoEditorActions.initVideoEditor, () => ({
    ...initialVideoEditorState,
  })),
  on(VideoEditorActions.setVideoClips, (state, { clips }) => ({
    ...state,
    trackItems: clips,
  })),
  on(VideoEditorActions.setSubtitleSegments, (state, { segments }) => ({
    ...state,
    segments: segments,
  })),
  on(VideoEditorActions.setActiveClipId, (state, { id }) => ({
    ...state,
    activeTrackItemId: id,
  })),
  on(VideoEditorActions.setFirstClipActive, (state) => ({
    ...state,
    activeTrackItemId: state.trackItems[0]?.id as string,
  })),
  on(VideoEditorActions.setIsPlaying, (state, { status }) => ({
    ...state,
    isPlaying: status,
  })),
  on(VideoEditorActions.updateTotalTimeSpent, (state, { time }) => ({
    ...state,
    totalTimeSpent: state.totalTimeSpent + time,
  })),
  on(VideoEditorActions.resetTotalTimeSpent, (state) => ({
    ...state,
    totalTimeSpent: 0,
  })),
  on(
    VideoEditorActions.updateSegmentText,
    (state, { segmentId, segmentText, words }) => {
      const wordIds = words.map((w) => w.id);
      let segment = state.segments.find(
        (s) => s.id === segmentId,
      ) as SubtitleSegment;
      segment = cloneDeep(segment);

      if (segment) {
        segment.text = segmentText;
        segment.words = segment?.words.map((w) =>
          wordIds.includes(w.id) ? words.find((word) => word.id === w.id) : w,
        ) as SubtitleWord[];
      }

      return {
        ...state,
        segments: state.segments.map((s) => (s.id === segmentId ? segment : s)),
      };
    },
  ),
  on(VideoEditorActions.replaceActiveTrackItemAsset, (state, { asset }) => ({
    ...state,
    trackItems: state.trackItems.map((c) =>
      c.id === state.activeTrackItemId ? { ...c, asset } : c,
    ) as trackItem[],
  })),
  on(VideoEditorActions.setAudio, (state, { audio }) => ({
    ...state,
    audio,
  })),
  on(VideoEditorActions.setVideoIsReady, (state) => ({
    ...state,
    activeVideoIsReady: true,
  })),
  on(VideoEditorActions.setAudioIsReady, (state) => ({
    ...state,
    audioIsReady: true,
  })),
  on(VideoEditorActions.setVideoIsPlaying, (state, { status }) => ({
    ...state,
    activeVideoIsPlaying: status,
  })),
);

export function videoEditorReducer(
  state: VideoEditorState | undefined,
  action: Action,
) {
  return reducer(state, action);
}
