import { Route } from '@angular/router';
import { PATH, TITLE, UnauthorizedGuard } from '@bupple/core';

export const authFeatureSignInRoutes: Route = {
  path: PATH.signIn,
  title: TITLE.signIn,
  canActivate: [UnauthorizedGuard],
  loadComponent: () =>
    import('./auth-feature-sign-in/auth-feature-sign-in.component').then(
      (m) => m.AuthFeatureSignInComponent,
    ),
};
