import { Injectable } from '@angular/core';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { SwUpdate, VersionReadyEvent } from '@angular/service-worker';
import { filter } from 'rxjs';
import { InstallNewVersionComponent } from '../components/install-new-version/install-new-version.component';
import { ModalResult } from '../interfaces/modal-result.interface';

@Injectable({ providedIn: 'root' })
export class PwaUpdateService {
  constructor(
    private swUpdate: SwUpdate,
    private bottomSheet: MatBottomSheet,
  ) {}

  observeUpdates() {
    this.swUpdate.versionUpdates
      .pipe(
        filter((evt): evt is VersionReadyEvent => evt.type === 'VERSION_READY'),
      )
      .subscribe(() => {
        this.promptUser();
      });
  }

  private promptUser(): void {
    this.bottomSheet
      .open(InstallNewVersionComponent)
      .afterDismissed()
      .subscribe((result: ModalResult) => {
        result.command && result.command.execute();
      });
  }
}
