<ul class="space-y-3"
    [ngClass]="">
    @for (feature of plan.feature_list; track $index) {
    @if ($index <= 2)
        {
        <li
        class="flex gap-x-2 text-base-contetn items-center">
        <bupple-icon size="lg"
            [name]="feature.icon"
            color="base-content-tertiary"></bupple-icon>
        <p class="sub-text-l text-primary-content">
            {{ feature.detail }}
        </p>
        </li>
        }
        }
</ul>
<div class="collapse"
    *ngIf="plan.feature_list.length > 3">
    <input type="checkbox"
        class="peer min-h-fit" />
    <div class="collapse-title p-0 min-h-fit h-fit peer-checked:mt-3">
        <h4 class="text-base-contetn flex gap-x-2 text-start items-center">
            <bupple-icon class="transition-all rotate-0"
                size="lg"
                name="Outline / Arrows / Alt Arrow Down"
                color="base-content-tertiary"
                [ngClass]="{ '!rotate-180': showMore }"></bupple-icon>
            <span class="sub-text-l">
                {{ showMore ? 'Show Less' : 'Show More' }}
            </span>
        </h4>
    </div>
    <ul class="collapse-content p-0 space-y-3">
        @for (feature of plan.feature_list || []; track $index) {
        @if ($index > 2) {
        <li class="flex gap-x-2 text-base-contetn items-center">
            <bupple-icon size="lg"
                [name]="feature.icon"
                color="base-content-tertiary"></bupple-icon>
            <p class="sub-text-l text-primary-content">
                {{ feature.detail }}
            </p>
        </li>
        }
        }
    </ul>
</div>