import { Injectable } from '@angular/core';
import { CoreFacade } from '@bupple/core';
import { ListAllPostsDto } from '@bupple/interfaces';
import { filter, Observable, tap } from 'rxjs';
import { PostApi } from '../apis/post.api';

@Injectable({
  providedIn: 'root',
})
export class PostService {
  private apiUrl = 'https://api.example.com/posts';

  constructor(
    private postApi: PostApi,
    private coreFacade: CoreFacade,
  ) {}

  getAll(postId: string): Observable<ListAllPostsDto> {
    return this.postApi.getAll(postId).pipe(
      filter((result) => !!result),
      tap((result) => {
        this.coreFacade.setPosts(result);
        console.log('all posts', result);
      }),
    );
  }
}
