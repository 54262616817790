import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Inject,
} from '@angular/core';
import { FormsModule } from '@angular/forms';
import {
  MAT_BOTTOM_SHEET_DATA,
  MatBottomSheetRef,
} from '@angular/material/bottom-sheet';
import { ButtonComponent } from '@bupple/button';
import { ModalResult } from '@bupple/core';
import {
  FeedbackDto,
  IdeaboardChatApi,
  IdeaboardChatService,
  message,
} from '@bupple/studio/data-access';

@Component({
  selector: 'bupple-chat-feedback',
  standalone: true,
  imports: [CommonModule, FormsModule, ButtonComponent],
  providers: [IdeaboardChatService, IdeaboardChatApi],
  templateUrl: './chat-feedback.component.html',
  styleUrl: './chat-feedback.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ChatFeedbackComponent {
  constructor(
    protected bottomSheetRef: MatBottomSheetRef<ChatFeedbackComponent>,
    @Inject(MAT_BOTTOM_SHEET_DATA) private message: message,
    private ideaboardChatService: IdeaboardChatService,
    private cdr: ChangeDetectorRef,
  ) {}

  preparedMessages = [
    {
      title: 'The tone is too formal for my audience',
    },
    {
      title: 'Not what I needed',
    },
    {
      title: 'Not factually correct',
    },
    {
      title: "Didn't fully follow instructions",
    },
    {
      title: "It's too generic and lacks relevance.",
    },
    {
      title: 'The content is off-topic.',
    },
  ];

  feedbackContent = '';
  onSelectAFeedback(title: string) {
    this.feedbackContent = title;
  }

  inProgress = false;
  onClickSend() {
    this.inProgress = true;
    const feedback: FeedbackDto = {
      ideaboard_id: this.message.ideaboard_id,
      chat_id: this.message._id,
      feedback: this.feedbackContent,
    };

    this.ideaboardChatService.feedback(feedback).subscribe({
      next: (result) => {
        const modalResult: ModalResult = {
          data: result,
          error: null,
          command: null,
        };
        this.bottomSheetRef.dismiss(modalResult);
      },
      error: () => {
        this.inProgress = false;
        this.cdr.markForCheck();
      },
    });
  }
}
