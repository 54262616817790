import { Injectable } from '@angular/core';
import { MenuItem } from '@bupple/interfaces';

@Injectable()
export class MenuService {
  private _list: MenuItem[] = [];
  private _activeItem!: MenuItem;

  set list(value: MenuItem[]) {
    this._list = value;
  }

  get list(): MenuItem[] {
    return this._list;
  }

  activateMenu(item: MenuItem) {
    this._activeItem = item;
    this._list = this._list.map((i) => ({
      ...i,
      active: i.id === item.id,
    }));
  }

  get activeItem(): MenuItem {
    return this._activeItem;
  }
}
