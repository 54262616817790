import { Injectable } from '@angular/core';
import { NavigationExtras } from '@angular/router';
import { RouterAdaptor } from '@bupple/util/adaptors';
import { PATH } from '../constants/route-path.constants';

@Injectable({
  providedIn: 'root',
})
export class NavigationService {
  constructor(private router: RouterAdaptor) {}

  navigate(commands: unknown[], extras?: NavigationExtras) {
    this.router.navigate(commands, extras);
  }

  goConnectAccounts(): void {
    this.router.navigate([PATH.connectAccounts]);
  }

  goHome(): void {
    this.router.navigate([PATH.studio]);
  }

  goProfile(): void {
    this.router.navigate([PATH.studio, PATH.profile]);
  }

  goIdeaboard(id: string, extras?: NavigationExtras): void {
    this.router.navigate([PATH.studio, PATH.chat, id], extras);
  }

  goMakePost(
    ideaboardId: string,
    publishableId: string,
    extras?: NavigationExtras,
  ): void {
    this.router.navigate(
      [PATH.studio, ideaboardId, PATH.makePost, publishableId],
      extras,
    );
  }

  goSchedulePost(
    ideaboardId: string,
    publishableId: string,
    SocialNetwork: string,
    extras?: NavigationExtras,
  ): void {
    this.router.navigate(
      [
        PATH.studio,
        ideaboardId,
        PATH.makePost,
        publishableId,
        PATH.schedule,
        SocialNetwork,
      ],
      extras,
    );
  }

  goPublished(
    ideaboardId: string,
    publishableId: string,
    socialNetwork: string,
    extras?: NavigationExtras,
  ): void {
    this.router.navigate(
      [
        PATH.studio,
        ideaboardId,
        PATH.makePost,
        publishableId,
        PATH.schedule,
        socialNetwork,
        PATH.published,
      ],
      extras,
    );
  }

  goIdeaboards(): void {
    this.router.navigate([PATH.studio, PATH.ideaboards]);
  }

  goIntro() {
    this.router.navigate([PATH.intro]);
  }

  goSignIn() {
    this.router.navigate([PATH.auth, PATH.signIn]);
  }

  goSignUp() {
    this.router.navigate([PATH.auth, PATH.signUp]);
  }

  goForgotPassword() {
    this.router.navigate([PATH.auth, PATH.forgotPassword]);
  }

  goPlans(): void {
    this.router.navigate([PATH.studio, PATH.plans]);
  }

  goEditor(chatId: string, scene: string): void {
    this.router.navigate([PATH.studio, PATH.imageEditor, chatId], {
      queryParams: {
        scene,
      },
    });
  }

  goVideoEditor(
    ideaboardId: string,
    videoId: string,
    extras?: NavigationExtras,
  ) {
    this.router.navigate(
      [PATH.studio, ideaboardId, PATH.videoEditor, videoId],
      extras,
    );
  }

  goVideoEditorCaptions(ideaboardId: string, videoId: string) {
    this.goVideoEditor(ideaboardId, videoId, {
      queryParams: {
        view: 'captions',
      },
    });
  }

  goVideoEditorElements(ideaboardId: string, videoId: string) {
    this.goVideoEditor(ideaboardId, videoId, {
      queryParams: {
        view: 'elements',
      },
    });
  }
}
