<div>
  <bupple-add-card-title
    title="Add Video"
    subtitle="Upload your video file"
    icon="videocam"
    class="block mb-3"
  ></bupple-add-card-title>
  <bupple-link-preview
    [link]="linkFormControl.value"
    (change)="onChangeLinkPreview($event)"
  ></bupple-link-preview>
  <form [formGroup]="form" class="pt-3">
    <lib-button
      block
      class="mt-6"
      appearance="tonal"
      [disabled]="form.invalid"
      (clickEvent)="onAddVideoLink()"
      *ngIf="linkFormControl.valid"
    >
      <mat-icon>videocam</mat-icon>
      Add to Videos
    </lib-button>
    <bupple-chat-uploader
      [config]="uploaderConfig"
      (completeUpload)="onAddUnit($event)"
      (selectFile)="onSelectFile($event)"
      (errorUpload)="onErrorUpload()"
    ></bupple-chat-uploader>
  </form>
</div>
