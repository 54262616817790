import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';

@Component({
  selector: 'bupple-no-data-found',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './ui-no-data-found.component.html',
  styleUrls: ['./ui-no-data-found.component.scss'],
})
export class UiNoDataFoundComponent {
  @Input() title: string = 'No data found';
  @Input() titleSub: string = 'No data found';
}
