import {
  ChangeDetectionStrategy,
  Component,
  Input,
  ViewChild,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { message, ReceivedMessageDto } from '@bupple/studio/data-access';
import { UiIconComponent } from '@bupple/ui/icon';

@Component({
  selector: 'lib-thumbnail-video',
  standalone: true,
  imports: [CommonModule, UiIconComponent],
  templateUrl: './thumbnail-video.component.html',
  styleUrl: './thumbnail-video.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ThumbnailVideoComponent {
  @Input() set message(value: message) {
    this.receviedMessage = value as ReceivedMessageDto;
  }
  @Input() videoWidth = 288;
  @Input() videoHeight = 512;

  receviedMessage?: ReceivedMessageDto;
  showThumbnail = true;
  hideThumbnail() {
    this.showThumbnail = false;
  }

  @ViewChild('videoPlayer') videoPlayer: any;

  playVideo() {
    this.videoPlayer.nativeElement.play();
  }

  loading = true;

  onLoadedMetadata() {
    this.loading = false;
  }
}
