import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';
import { ButtonComponent } from '@bupple/button';
import { ScreenSizeService } from '@bupple/core';
import {
  ChatActionButton,
  IdeaboardChatService,
  message,
  MessageType,
} from '@bupple/studio/data-access';
import { UiIconComponent } from '@bupple/ui/icon';
import { ShortTimePipe } from '@bupple/util/pipes';
import { TrackByService } from '@bupple/util/services';
import { ResizeObserverDirective } from '../directives/resize-observer.directive';
import { SentChatMessageImageComponent } from './sent-chat-message-image/sent-chat-message-image.component';
import { SentChatMessageLinkComponent } from './sent-chat-message-link/sent-chat-message-link.component';
import { SentChatMessageTextComponent } from './sent-chat-message-text/sent-chat-message-text.component';
import { SentChatMessageVideoComponent } from './sent-chat-message-video/sent-chat-message-video.component';

@Component({
  selector: 'bupple-sent-chat-message',
  standalone: true,
  imports: [
    CommonModule,
    ShortTimePipe,
    ResizeObserverDirective,
    SentChatMessageImageComponent,
    SentChatMessageVideoComponent,
    SentChatMessageLinkComponent,
    SentChatMessageTextComponent,
    ButtonComponent,
    UiIconComponent,
  ],
  providers: [ShortTimePipe, ScreenSizeService],
  templateUrl: './sent-chat-message.component.html',
  styleUrls: ['./sent-chat-message.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SentChatMessageComponent {
  constructor(
    protected screenSize: ScreenSizeService,
    protected trackBy: TrackByService,
    protected ideaboardChatService: IdeaboardChatService,
  ) {}
  @Input({ required: true }) message!: message;
  @Output() action: EventEmitter<ChatActionButton> = new EventEmitter();
  @Output() clickMakePost: EventEmitter<ChatActionButton> = new EventEmitter();
  messageType = MessageType;

  onClickAction(action: ChatActionButton) {
    this.action.emit(action);
  }

  @Output() resizeHeight = new EventEmitter<null>();
  onResize() {
    this.resizeHeight.emit();
  }

  onClickMakePost() {
    this.clickMakePost.emit();
  }

  @Output() clickDelete: EventEmitter<message> = new EventEmitter();
  onClickDelete() {
    this.clickDelete.emit(this.message);
  }

  onClickCopyText() {
    this.ideaboardChatService.copyText(this.message.content);
  }
}
