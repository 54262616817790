import { Injectable } from '@angular/core';
import { MARKED_OPTIONS, MarkedRenderer } from 'ngx-markdown';

@Injectable({
  providedIn: 'root',
})
export class CustomMarkdownService {
  static getProvider() {
    return {
      markedOptions: {
        provide: MARKED_OPTIONS,
        useFactory: () => {
          const renderer = new MarkedRenderer();
          renderer.heading = (text: string, level: number) => {
            return `
                      <h${level} class="mb-1">
                          ${text}
                        </h${level}>
                      `;
          };
          renderer.paragraph = (text: string) => {
            return `<p>${text}</p><br/>`;
          };
          renderer.list = (body: string, ordered: boolean) => {
            if (ordered) {
              return `<ol class="pl-4 list-decimal">${body}</ol><br/>`;
            } else {
              return `<ul class="pl-4 list-disc">${body}</ul><br/>`;
            }
          };
          renderer.listitem = (text: string) => {
            return `<li class="mb-1">${text}</li>`;
          };
          return {
            renderer,
            breaks: true,
            gfm: true,
          };
        },
      },
      break: true,
    };
  }
}
