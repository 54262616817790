<div class="flex gap-4 min-h-44">
  <!-- Preview -->
  <div
    class="idea-type flex-none w-[5.5rem] md:w-28 flex flex-wrap items-center justify-end overflow-hidden min-h-[9rem] md:min-h-[10rem] gap-1 rounded-xl"
    [ngClass]="{
      one: numberOfIdeaboard === 1,
      two: numberOfIdeaboard === 2,
      three: numberOfIdeaboard === 3,
      four: numberOfIdeaboard === 4,
    }"
  >
    <div
      class="idea-image mask"
      *ngIf="ideaboard.__dto.thumbnail_icons.includes('img')"
    >
      <bupple-icon
        [size]="screenWidth < 600 ? 'sm' : 'md'"
        name="Outline / Ideaboard / Image"
      ></bupple-icon>
    </div>
    <div
      class="idea-voice mask"
      *ngIf="ideaboard.__dto.thumbnail_icons.includes('voi')"
    >
      <bupple-icon
        [size]="screenWidth < 600 ? 'sm' : 'md'"
        name="Outline / Ideaboard / Voice"
      ></bupple-icon>
    </div>
    <div
      class="idea-doc mask"
      *ngIf="
        this.ideaboard.__dto.thumbnail_icons.includes('txt') ||
        ideaboard.__dto.thumbnail_icons.includes('lnk')
      "
    >
      <bupple-icon
        [size]="screenWidth < 600 ? 'sm' : 'md'"
        name="Outline / Ideaboard / Doc"
      ></bupple-icon>
    </div>
    <div
      class="idea-video mask"
      *ngIf="ideaboard.__dto.thumbnail_icons.includes('vid')"
    >
      <bupple-icon
        [size]="screenWidth < 600 ? 'sm' : 'md'"
        name="Outline / Ideaboard / Video"
      ></bupple-icon>
    </div>
  </div>
  <!-- Details -->
  <div class="flex flex-col grow">
    <!-- Ideaboard last status -->
    <bupple-ideaboard-last-status
      [ideaboardLastStatus]="ideaboardLastStatus"
    ></bupple-ideaboard-last-status>
    <!-- Ideaboard Details -->
    <div class="group relative h-fit mt-2">
      <h4 class="title-base mb-3 leading-5 line-clamp-2">
        {{ ideaboard && ideaboard.name }}
      </h4>
      <!-- tooltip of title -->
      <span class="md:hidden hidden tooltip-1 max-w-40">{{
        ideaboard && ideaboard.name
      }}</span>
    </div>
    <div class="grow flex items-end">
      <p class="sub-text-md uppercase text-base-content-secondary">
        {{ ideaboard.updated_at | updatedAt }}
      </p>
    </div>
    <div class="flex gap-2 pt-6">
      <div class="grow">
        <lib-button
          block
          appearance="tonal"
          (clickEvent)="ideaboard && navigation.goIdeaboard(ideaboard._id)"
          [disabled]="ideaboard.inProgress"
        >
          Continue
          <bupple-icon
            name="Outline / Arrows / Arrow Right"
            color="primary"
          ></bupple-icon>
        </lib-button>
      </div>
      <lib-button
        square
        color="neutral"
        appearance="outline"
        (clickEvent)="onClickDelete($event)"
        [loading]="ideaboard.inProgress"
        [disabled]="ideaboard.inProgress"
      >
        <bupple-icon
          name="Outline / Essentional, UI / Trash Bin Trash"
        ></bupple-icon>
      </lib-button>
    </div>
  </div>
</div>
