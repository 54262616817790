import { Injectable } from '@angular/core';
import { ListAllPostsDto } from '@bupple/interfaces';
import { HttpClientAdaptor } from '@bupple/util/adaptors';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class PostApi {
  constructor(private http: HttpClientAdaptor) {}
  private endpointHead = 'publish/';

  getAll(postId: string): Observable<ListAllPostsDto> {
    return this.http.get(this.endpointHead + `get`);
  }
}
