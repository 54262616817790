import { Injectable } from '@angular/core';
import { HttpClientAdaptor } from '@bupple/util/adaptors';
import { Observable } from 'rxjs';
import { RootHeartDto } from '../dtos/root-heart.dto';

@Injectable({
  providedIn: 'root',
})
export class StationApi {
  constructor(private http: HttpClientAdaptor) {}

  private endpointHead = 'station/';

  heart(): Observable<RootHeartDto> {
    return this.http.get<RootHeartDto>(this.endpointHead + 'heart');
  }
}
