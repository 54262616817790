<bupple-top-pattern></bupple-top-pattern>
<bupple-auth-header></bupple-auth-header>

<div
    class="px-page relative z-10 max-w-[390px] w-full mx-auto md:flex md:flex-col md:justify-center md:items-center md:h-[calc(100vh_-_4.5rem)]">
    <form [formGroup]="form"
        class="pt-10 md:max-h-fit md:py-7 md:w-full">
        <h2 class="mb-10 font-bold md:title-xl">Forget password</h2>

        <p class="font-bold mb-2 pl-2">Email</p>
        <label class="input input-bordered flex items-center gap-2 mb-4">
            <bupple-icon name="Outline / Messages, Conversation  /  Letter"></bupple-icon>
            <input formControlName="email"
                type="text"
                class="grow"
                placeholder="example@gmail.com" />
        </label>

        @if(formIsValid('email')){
        <!-- email validation error note  -->
        <p class="text-error sub-text-l pb-2 w-full text-end mt-4">Please enter a valid email address</p>
        }



        <lib-button class="mt-6"
            block
            [disabled]="inProgress"
            (clickEvent)="onSubmit()">
            @if (this.showDescription) {
            Resent Email
            } @else {
            Send Email
            }
            <bupple-icon size="lg"
                name="Outline / Arrows / Arrow Right"
                color="base-content"></bupple-icon>
        </lib-button>
    </form>

    <div *ngIf="showDescription"
        class="flex gap-x-2 mt-6 md:mt-0">
        <bupple-icon name="Outline / Essentional, UI / Info Circle"
            size="xl"
            color="base-content-tertiary"></bupple-icon>
        <p class="sub-text-l">
            Check Your Email please! <br> We will send you an email with a link to reset your password.
        </p>
    </div>


    <div class="divider">OR</div>

    <lib-button block
        color="neutral"
        appearance="tonal"
        (clickEvent)="onContinueWithGoogle()">
        <bupple-icon name="GoogleLogo"
            size="lg"></bupple-icon>
        Sign in with Google
    </lib-button>

    <lib-button class="mt-4"
        block
        color="neutral"
        appearance="tonal"
        (clickEvent)="onContinueWithFacebook()">
        <bupple-icon name="FacebookLogo"
            size="lg"></bupple-icon>
        Sign in with Facebook
    </lib-button>

    <p class="text-center text-base-content-secondary mt-4">
        New to Bupple?
        <a (click)="navigation.goSignUp()"
            class="text-secondary cursor-pointer">Sign up</a>
    </p>

    <bupple-ui-pattern-bottom></bupple-ui-pattern-bottom>
</div>