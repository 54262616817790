import { AsyncPipe, NgClass, NgIf } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { ButtonComponent } from '@bupple/button';
import { NavigationService, ScreenSizeService } from '@bupple/core';
import { ProgressBarComponent } from '@bupple/progress-bar';
import {
  ChatActionButton,
  IdeaboardChatService,
  message,
  MessageType,
  NotifType,
  ReceivedMessageDto,
  StudioFacade,
} from '@bupple/studio/data-access';
import { ThumbnailVideoComponent } from '@bupple/thumbnail-video';
import { UiIconComponent } from '@bupple/ui/icon';
import { UiLoadingComponent } from '@bupple/ui/loading';
import { ShortTimePipe } from '@bupple/util/pipes';
import { isEmpty } from 'lodash-es';
import { ResizeObserverDirective } from '../directives/resize-observer.directive';
import { ReceivedChatMessageCreativeComponent } from './received-chat-message-creative/received-chat-message-creative.component';
import { ReceivedChatMessageImageComponent } from './received-chat-message-image/received-chat-message-image.component';
import { ReceivedChatMessageTextComponent } from './received-chat-message-text/received-chat-message-text.component';
import { ReceivedChatMessageVideoComponent } from './received-chat-message-video/received-chat-message-video.component';

@Component({
  selector: 'bupple-received-chat-message',
  standalone: true,
  imports: [
    NgClass,
    NgIf,
    UiLoadingComponent,
    MatIconModule,
    ShortTimePipe,
    AsyncPipe,
    UiIconComponent,
    ResizeObserverDirective,
    ThumbnailVideoComponent,
    ButtonComponent,
    ReceivedChatMessageImageComponent,
    ReceivedChatMessageTextComponent,
    ReceivedChatMessageVideoComponent,
    ReceivedChatMessageCreativeComponent,
    ProgressBarComponent,
  ],
  providers: [ShortTimePipe, NavigationService, ScreenSizeService],
  templateUrl: './received-chat-message.component.html',
  styleUrls: ['./received-chat-message.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ReceivedChatMessageComponent {
  constructor(
    protected navigation: NavigationService,
    protected screenSize: ScreenSizeService,
    protected ideaboardChatService: IdeaboardChatService,
    private studioFacade: StudioFacade,
  ) {}

  @Input({ required: true }) set message(value: message) {
    this.recievedMessage = value as ReceivedMessageDto;
    this.actionButtons = (value as ReceivedMessageDto)?.buttons;
    this.hasActions = !isEmpty(this.actionButtons);
  }
  recievedMessage?: ReceivedMessageDto;
  actionButtons?: ChatActionButton[] = [];
  hasActions = false;
  messageType = MessageType;
  notifType = NotifType;

  @Output() action: EventEmitter<ChatActionButton> = new EventEmitter();
  @Output() clickMakePost: EventEmitter<ChatActionButton> = new EventEmitter();
  @Output() clickDislike: EventEmitter<null> = new EventEmitter();
  @Output() clickDelete: EventEmitter<ReceivedMessageDto> = new EventEmitter();

  onClickAction(action: ChatActionButton) {
    this.action.emit(action);
  }

  onClickMakePost() {
    this.clickMakePost.emit();
  }

  onClickEditCreative(id: string, scene: string) {
    this.navigation.goEditor(id, scene);
  }

  onClickDelete() {
    this.clickDelete.emit(this.recievedMessage);
  }

  @Output() resizeHeight = new EventEmitter<null>();
  onResize() {
    this.resizeHeight.emit();
  }

  onClickDislike() {
    this.clickDislike.emit();
  }

  onClickDownloadImage() {
    if (this.recievedMessage) {
      this.ideaboardChatService.downloadImage(
        this.recievedMessage.file_full_path,
      );
    }
  }

  onCreativePreviewIsReady(messageId: string, event: Blob) {
    this.ideaboardChatService
      .updateThumbnail(messageId, event)
      .subscribe((result) => {
        this.studioFacade.updateChatMessage(result.chat);
      });

    // this.ideaboardChatService.updateCreative(messageId, 'preview');
  }

  onClickCopyText() {
    if (this.recievedMessage) {
      this.ideaboardChatService.copyText(this.recievedMessage.content);
    }
  }
}
