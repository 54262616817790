<div class="px-page py-3 flex items-center justify-center bg-base-100">
  <lib-button
    class="mr-2"
    size="xs"
    appearance="text"
    (clickEvent)="this.navigation.goIdeaboards()"
  >
    <bupple-icon
      name="Outline / Arrows / Alt Arrow Left"
      size="md"
    ></bupple-icon>
  </lib-button>
  <div class="avatar mr-3">
    <div class="w-8 rounded-full">
      <img src="assets/images/bupple-dark-logo.jpg" />
    </div>
  </div>
  <div class="grow mr-2">
    <p
      class="sub-text-l text-transparent bg-clip-text bg-gradient-to-br from-[#8443EE] to-[#57E1FF] w-fit"
    >
      Bupple Studio
    </p>
    <input
      type="text"
      placeholder="Ideaboard name ..."
      class="input text-sm font-semibold focus:outline-none w-full p-0 h-6"
      [formControl]="nameFormControl"
    />
  </div>
</div>
