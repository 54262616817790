<div class="flex flex-col" *ngIf="linkPreview">
  <img
    [src]="linkPreview.image || linkPreview.url"
    *ngIf="linkPreview.image || linkPreview.url"
    alt="preview-image"
    class="block mb-6 rounded-lg"
    onerror="this.onerror=null;this.src='https://placehold.co/600x400/black/gray?text=!'"
  />
  <p class="text-sm" *ngIf="linkPreview.title">
    {{ linkPreview.title }}
  </p>
  <p class="text-xs text-base-content/50 mt-2" *ngIf="linkPreview.description">
    {{ description }}
  </p>
</div>
