import { Injectable } from '@angular/core';
import {
  CreateMessageDto,
  IdeaboardChatService,
  MessageType,
  SentMessageDto,
  StudioFacade,
} from '@bupple/studio/data-access';
import { catchError, Observable, of, switchMap, throwError } from 'rxjs';
import { v4 } from 'uuid';
import { MockingSentMessage } from '../interfaces/mocking-chat-messages.interface';
import { SendChatMessage } from '../interfaces/send-message.interface';

@Injectable()
export class ImageMessageService
  implements SendChatMessage, MockingSentMessage
{
  constructor(
    private studioFacade: StudioFacade,
    private ideaboardChatService: IdeaboardChatService,
  ) {}

  send(ideaboardId: string, message: CreateMessageDto): Observable<any> {
    this.setMockSentMessage(message.content);

    return this.ideaboardChatService.send(ideaboardId, message).pipe(
      switchMap((result: any) => {
        result?.body?.chat &&
          this.studioFacade.setRealSentMessage(result.body.chat);

        return result.chat ? of(null) : of(result);
      }),
      catchError((result) => {
        this.studioFacade.removeMockSentMessage();
        return throwError(() => result);
      }),
    );
  }

  setMockSentMessage(content: string): void {
    const msg: SentMessageDto = {
      is_publishable: false,
      _id: v4(),
      created_at: '',
      file_full_path: 'https://placehold.co/600x400/1a1a1a/1a1a1a',
      ideaboard_id: '',
      sender_id: v4(),
      updated_at: '',
      type: MessageType.image,
      content: content,
    };
    this.studioFacade.setMockSentMessage(msg);
  }
}
