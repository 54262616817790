import { Inject, Injectable } from '@angular/core';
import { Environment } from '@bupple/interfaces';
// import { Observable } from 'rxjs';
import { Observable, map } from 'rxjs';

import {
  CoreFacade,
  StorageService,
  ToasterService,
  UserWithTokenResponseDto,
} from '@bupple/core';
import { PlanApi } from '../api/plans.api';
import { PlansDTO } from '../dtos/plans.dtos';

@Injectable({
  providedIn: 'root',
})
export class PlansService {
  constructor(
    private PlanApi: PlanApi,
    private coreFacade: CoreFacade,
    private storage: StorageService,
    private toaster: ToasterService,
    @Inject('environment') private environment: Environment,
  ) {}

  storeAutherizationState(state: UserWithTokenResponseDto) {
    this.coreFacade.setUser(state.user);
    this.storage.setToken(state.token);
  }

  screenWidth = window.screen.width;
  screenHeight = window.screen.height;
  width = Math.min(600, this.screenWidth - 20);
  height = 600;
  left = (this.screenWidth - this.width) / 2;
  top = (this.screenHeight - this.height) / 2;

  getPlan(): Observable<PlansDTO> {
    return this.PlanApi.getPlan().pipe(map((result) => result.plans));
  }

  // getFreePlan(): Observable<PlanDTO> {
  //   return this.PlanApi.getPlan().pipe(map((result) => result.plans[0]));
  // }

  // getPremiumPlans(): Observable<PlanDTO[]> {
  //   return this.PlanApi.getPlan().pipe(map((result) => result.plans));
  // }

  subscribePlan(plan_id: string, plan_price_id: string): Observable<boolean> {
    return new Observable((observer) => {
      this.PlanApi.subscribePlan(plan_id, plan_price_id).subscribe({
        next: (response) => {
          const responseUrl = response.url;
          window.open(
            responseUrl,
            'Buppel Plan Subscription',
            `width=${this.width},height=${this.height},top=${this.top},left=${this.left}`,
          );
          observer.next(true);
        },
      });
    });
  }

  RedirectToPlanPanel() {
    return new Observable((observer) => {
      this.PlanApi.RedirectToPlanPanel().subscribe({
        next: (response) => {
          const responseUrl = response.url;
          window.open(
            responseUrl,
            'Connect Facebook Auth',
            `width=${this.screenWidth},height=${this.screenHeight},top=${this.top},left=${this.left}`,
          );
          observer.next(true);
        },
      });
    });
  }
}
