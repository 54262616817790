import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ButtonComponent } from '@bupple/button';
import { UiIconComponent } from '@bupple/ui/icon';
import { IdeaboardChatService } from '@bupple/studio/data-access';

@Component({
  selector: 'lib-image-modal',
  standalone: true,
  imports: [CommonModule, ButtonComponent, UiIconComponent],
  templateUrl: './image-modal.component.html',
  styleUrl: './image-modal.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ImageModalComponent {
  constructor(protected ideaboardChatService: IdeaboardChatService) {}

  @Input({ required: true }) imageUrl: string | undefined;
  @Input({ required: true }) id: string | undefined;

  onClickDownloadImage() {
    this.imageUrl && this.ideaboardChatService.downloadImage(this.imageUrl);
  }
}
