import { createFeatureSelector, createSelector } from '@ngrx/store';
import { sum } from 'lodash-es';
import {
  TrackAudioItem,
  trackItem,
  TrackVideoItem,
} from '../interfaces/timeline-track-item.interface';
import {
  VIDEO_EDITOR_FEATURE_KEY,
  VideoEditorState,
} from './video-editor.reducer';

export const selectVideoEditorState = createFeatureSelector<VideoEditorState>(
  VIDEO_EDITOR_FEATURE_KEY,
);

export const activeTrackItem = createSelector(
  selectVideoEditorState,
  (state: VideoEditorState) => {
    return state.trackItems.find(
      (c) => c.id === state.activeTrackItemId,
    ) as trackItem;
  },
);

export const activeTrackItemAsset = createSelector(
  selectVideoEditorState,
  (state: VideoEditorState) => {
    const activeClip: TrackVideoItem = state.trackItems.find(
      (c) => c.id === state.activeTrackItemId,
    ) as TrackVideoItem;

    return activeClip?.asset;
  },
);

export const assetCandidates = createSelector(
  selectVideoEditorState,
  (state: VideoEditorState) => {
    const activeClip: TrackVideoItem = state.trackItems.find(
      (c) => c.id === state.activeTrackItemId,
    ) as TrackVideoItem;
    return activeClip?.asset_candidates || [];
  },
);

export const nextClip = createSelector(
  selectVideoEditorState,
  (state: VideoEditorState) => {
    const activeClip = state.trackItems.find(
      (c) => c.id === state.activeTrackItemId,
    );
    const index = state.trackItems.indexOf(activeClip as trackItem);
    return state.trackItems[index + 1] as trackItem;
  },
);

export const isPlaying = createSelector(
  selectVideoEditorState,
  (state: VideoEditorState) => {
    return state?.isPlaying;
  },
);

export const clips = createSelector(
  selectVideoEditorState,
  (state: VideoEditorState) => {
    return state.trackItems;
  },
);

export const totalTimeSpent = createSelector(
  selectVideoEditorState,
  (state) => state.totalTimeSpent,
);

export const totalTime = createSelector(selectVideoEditorState, (state) =>
  sum(state.trackItems.map((c) => c.length)),
);

export const segments = createSelector(
  selectVideoEditorState,
  (state) => state.segments,
);

export const audio = createSelector(
  selectVideoEditorState,
  (state) => state.audio as TrackAudioItem,
);

export const canPlayTimeline = createSelector(
  selectVideoEditorState,
  (state) => state.activeVideoIsReady && state.audioIsReady,
);

export const stopAudio = createSelector(
  selectVideoEditorState,
  (state) => !state.activeVideoIsPlaying,
);
