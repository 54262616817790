import { Injectable } from '@angular/core';
import { GoogleTagManagerEvent } from '../interfaces/google-tag-manager-event.interface';

declare let dataLayer: Array<GoogleTagManagerEvent>;

@Injectable({
  providedIn: 'root',
})
export class GoogleTagManagerService {
  public pushToDataLayer(event: Partial<GoogleTagManagerEvent>) {
    dataLayer.push(event as GoogleTagManagerEvent);
  }
}
