import { Route } from '@angular/router';
import { AuthorizedGuard, PATH, TITLE } from '@bupple/core';

export const IdeaboardListRoutes: Route = {
  path: PATH.ideaboards,
  title: TITLE.ideaboards,
  canActivate: [AuthorizedGuard],
  loadComponent: () =>
    import('./ideaboards/ideaboards.component').then(
      (c) => c.IdeaboardsComponent,
    ),
};
