<div [ngClass]="
    'flex flex-row items-center gap-6 justify-center text-base-content px-4 rounded-3xl h-[96px] md:h-[104px] w-full ' +
    background
  ">
  <img [src]="image"
    class="flex-none w-[72px] h-[72px]"
    alt=""
    [ngClass]="imgStatus" />
  <div class="grow">
    <p class="title-base-bold mb-1 tracking-[.15px]">
      {{ title }}
      <bupple-icon [name]="icon"
        size="md"
        color="base-content"></bupple-icon>
    </p>
    <p class="sub-text-l tracking-[.1px]">{{ subtitle }}</p>
  </div>
</div>