import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { message, ReceivedMessageDto } from '@bupple/studio/data-access';
import { ImageModalComponent } from '@bupple/image-modal';

@Component({
  selector: 'bupple-sent-chat-message-image',
  standalone: true,
  imports: [CommonModule, ImageModalComponent],
  templateUrl: './sent-chat-message-image.component.html',
  styleUrl: './sent-chat-message-image.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SentChatMessageImageComponent {
  @Input({ required: true }) set message(value: message) {
    this.receivedMessage = value as ReceivedMessageDto;
  }
  receivedMessage?: ReceivedMessageDto;
}
