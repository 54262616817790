<div>
  <div class="w-full mb-6">
    <img src="assets/images/plans-new.jpg"
      alt=""
      class="mx-auto block h-[177px] object-cover" />
  </div>

  <div class="px-4 flex flex-col gap-y-8 md:max-w-[65rem] md:mx-auto md:items-center md:gap-y-6">
    <div class="flex flex-col gap-y-1">
      <h2 class="title-l-bold text-primary-content leading-8 text-center md:title-xl md:leading-10">
        Keep Your <span class="gradient-text">Unlimited</span> <br />
        access before it's gone!
      </h2>
      <p class="sub-text-l text-base-content-secondary text-center mx-auto md:max-w-full md:sub-text-xl">
        Bupple offers free unlimited access to its early users. <br />From
        <span class="gradient-text">November 1, 20</span>
      </p>
    </div>

    <div
      class="flex w-full justify-center gap-x-2 h-14 border-4 p-[1px] border-base-content/12 rounded-3xl max-w-[21rem] mx-auto">
      <label for="pay-yearly"
        class="flex flex-1"
        [ngClass]="{ 'tab-active': selectedTab === 'yearly' }"
        (click)="tabHandler('yearly')">
        <span
          class="title-xs bg-base-100 rounded-[0.9rem] px-1 py-1 mx-auto w-full flex items-center justify-center gap-x-1">
          Pay Yearly
          <span class="sub-text-sm bg-base-content text-base-500 px-1.5 py-1 rounded-full">
            2 Free Months
          </span>
        </span>
      </label>
      <label for="pay-monthly"
        class="flex flex-1"
        (click)="tabHandler('monthly')"
        [ngClass]="{ 'tab-active': selectedTab === 'monthly' }">
        <span class="title-xs bg-base-100 rounded-[0.9rem] px-6 py-1 mx-auto w-full flex items-center justify-center">
          Pay Monthly
        </span>
      </label>
    </div>
    <div role="tablist"
      class="tabs tabs-lifted w-full">
      <input type="radio"
        name="my_tabs_2"
        role="tab"
        class="tab hidden"
        aria-label="Pay Yearly"
        id="pay-yearly"
        checked="checked" />
      <div role="tabpanel"
        class="tab-content bg-base-100 border-base-300 rounded-box">
        <div class="w-full">
          <div class="w-full flex flex-col gap-y-2 items-center md:flex-row-reverse">
            <ng-container *ngIf="!waitingData">
              @for (plan of plansYearly; track $index) {
              <button class="plan-box"
                [ngClass]="{
                    'tab-active': plan!.title === selectedPlan.title,
                  }"
                (click)="onSelectedPlan(plan)">
                <div class="inner">
                  <div class="content">
                    <div class="info">
                      <div class="flex gap-x-2 h-fit items-center flex-1">
                        <svg class="flex-none"
                          width="16"
                          height="16"
                          viewBox="0 0 16 16"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                          *ngIf="plan!.title === selectedPlan.title">
                          <rect x="1"
                            y="1"
                            width="14"
                            height="14"
                            rx="7"
                            stroke="url(#paint0_linear_12600_1038)"
                            stroke-width="2" />
                          <circle cx="8"
                            cy="8"
                            r="4"
                            fill="url(#paint1_linear_12600_1038)" />
                          <defs>
                            <linearGradient id="paint0_linear_12600_1038"
                              x1="-0.0570786"
                              y1="0.1145"
                              x2="15.9545"
                              y2="15.9895"
                              gradientUnits="userSpaceOnUse">
                              <stop stop-color="#8443EE" />
                              <stop offset="1"
                                stop-color="#57E1FF" />
                            </linearGradient>
                            <linearGradient id="paint1_linear_12600_1038"
                              x1="3.97146"
                              y1="4.05725"
                              x2="11.9772"
                              y2="11.9948"
                              gradientUnits="userSpaceOnUse">
                              <stop stop-color="#8443EE" />
                              <stop offset="1"
                                stop-color="#57E1FF" />
                            </linearGradient>
                          </defs>
                        </svg>
                        <svg class="flex-none"
                          width="16"
                          height="16"
                          viewBox="0 0 16 16"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                          *ngIf="plan!.title !== selectedPlan.title">
                          <rect x="1"
                            y="1"
                            width="14"
                            height="14"
                            rx="7"
                            stroke="#F2F2F3"
                            stroke-width="2" />
                        </svg>

                        <h3 class="title title-base-bold">
                          {{ plan.title }}
                        </h3>
                      </div>
                      <div class="price sub-text-xl">
                        <span class="text-xl title-base-bold text-base-content">${{ plan.price }}</span>
                        / Yr
                        <!-- <br />
                        ${{ plan.price * 12 }} Upfront -->
                      </div>
                    </div>

                    <div class="pt-4 features space-y-3">
                      <lib-plan-features [plan]="plan"></lib-plan-features>
                    </div>
                  </div>
                </div>

                <!-- <div class="absolute badge px-0 -top-2.5 left-2.5 z-10 bg-base-100 border border-accent/12 rounded-2xl"
                  *ngIf="plan.saveStatus">
                  <div class="badge bg-accent/12 border border-accent/12 h-full w-full">
                    <span class="text-accent sub-text-l">Save {{ plan.save }}%</span>
                  </div>
                </div> -->
              </button>
              }
            </ng-container>
            <ng-container *ngIf="waitingData">
              <div class="flex flex-col gap-2 md:flex-row md:gap-0">
                <lib-plan-skeleton></lib-plan-skeleton>
                <lib-plan-skeleton></lib-plan-skeleton>
              </div>
            </ng-container>
          </div>
        </div>
      </div>

      <input type="radio"
        name="my_tabs_2"
        role="tab"
        class="tab hidden"
        aria-label="Pay Monthly"
        id="pay-monthly" />
      <div role="tabpanel"
        class="tab-content bg-base-100 border-base-300 rounded-box">
        <div class="w-full">
          <div class="w-full flex flex-col gap-y-2 items-center md:flex-row-reverse">
            <ng-container *ngIf="!waitingData">
              @for (plan of plansMonthly; track $index) {
              <button class="plan-box"
                [ngClass]="{
                    'tab-active': plan!.title === selectedPlan.title,
                  }"
                (click)="onSelectedPlan(plan)">
                <div class="inner">
                  <div class="content">
                    <div class="info">
                      <div class="flex gap-x-2 h-fit items-center flex-1">
                        <svg width="16"
                          height="16"
                          viewBox="0 0 16 16"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                          *ngIf="plan!.title === selectedPlan.title">
                          <rect x="1"
                            y="1"
                            width="14"
                            height="14"
                            rx="7"
                            stroke="url(#paint0_linear_12360_6937)"
                            stroke-width="2" />
                          <circle cx="8"
                            cy="8"
                            r="4"
                            fill="url(#paint1_linear_12360_6937)" />
                          <defs>
                            <linearGradient id="paint0_linear_12360_6937"
                              x1="-0.0570786"
                              y1="0.1145"
                              x2="15.9545"
                              y2="15.9895"
                              gradientUnits="userSpaceOnUse">
                              <stop stop-color="#8443EE" />
                              <stop offset="1"
                                stop-color="#57E1FF" />
                            </linearGradient>
                            <linearGradient id="paint1_linear_12360_6937"
                              x1="3.97146"
                              y1="4.05725"
                              x2="11.9772"
                              y2="11.9948"
                              gradientUnits="userSpaceOnUse">
                              <stop stop-color="#8443EE" />
                              <stop offset="1"
                                stop-color="#57E1FF" />
                            </linearGradient>
                          </defs>
                        </svg>

                        <svg class="flex-none"
                          width="16"
                          height="16"
                          viewBox="0 0 16 16"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                          *ngIf="plan!.title !== selectedPlan.title">
                          <rect x="1"
                            y="1"
                            width="14"
                            height="14"
                            rx="7"
                            stroke="#F2F2F3"
                            stroke-width="2" />
                        </svg>

                        <h3 class="title title-base-bold">
                          {{ plan.title }}
                        </h3>
                      </div>
                      <div class="price sub-text-xl">
                        <span class="text-xl title-base-bold text-base-content">${{ plan.price }}</span>
                        / Mo
                        <!-- <br />
                        ${{ plan.price * 12 }} Upfront -->
                      </div>
                    </div>

                    <div class="pt-4 features space-y-3">
                      <lib-plan-features [plan]="plan"></lib-plan-features>
                    </div>
                  </div>
                </div>

                <!-- <div class="absolute badge px-0 -top-2.5 left-2.5 z-10 bg-base-100 border border-accent/12 rounded-2xl"
                  *ngIf="plan.saveStatus">
                  <div class="badge bg-accent/12 border border-accent/12 h-full w-full">
                    <span class="text-accent sub-text-l">Save {{ plan.save }}%</span>
                  </div>
                </div> -->
              </button>
              }
            </ng-container>
            <ng-container *ngIf="waitingData">
              <div class="flex flex-col gap-2 md:flex-row md:gap-0">
                <lib-plan-skeleton></lib-plan-skeleton>
                <lib-plan-skeleton></lib-plan-skeleton>
              </div>
            </ng-container>
          </div>
        </div>
      </div>
    </div>

    <div class="w-full">
      <div class="max-w-[23rem] mx-auto">
        <lib-button block
          class="mt-4"
          size="lg"
          (clickEvent)="subscribePlan()">
          <bupple-icon name="Bold / Money / Ticker Star"
            size="lg"
            color="base-content"></bupple-icon>
          <span class="title-base-bold"> Subscribe & Pay </span>
        </lib-button>
      </div>
    </div>
  </div>
</div>