import { Injectable } from '@angular/core';
import { HttpClientAdaptor } from '@bupple/util/adaptors';
import { Observable } from 'rxjs';
import { MakePublishableResponseDto } from '../dtos/post.dto';

@Injectable()
export class PublishableApi {
  constructor(private http: HttpClientAdaptor) {}
  private endpointHead = 'publishable/';

  makeFromChat(messageId: string): Observable<MakePublishableResponseDto> {
    return this.http.post<MakePublishableResponseDto>(
      this.endpointHead + 'from/chat/' + messageId,
      null,
    );
  }

  updatePostCaption(postId: string, caption: string): Observable<any> {
    return this.http.post<Observable<any>>(
      this.endpointHead + `update-post-caption/${postId}`,
      {
        caption: caption,
      },
    );
  }
}
