import { Configuration } from '@cesdk/cesdk-js';

export const CREATIVE_CONFIG: Configuration = {
  license: 'eQ6y-gdo_qW8ovWi7opRfESerczq1hRpYtFWfFr_KRP86C4LuSN_4vuB7az00TFO',
  callbacks: { onUpload: 'local' },
  theme: 'dark',
  ui: {
    elements: {
      blocks: {
        '//ly.img.ubq/page': {
          manage: false,
        },
      },
    },
  },
};
