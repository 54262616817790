import { Injectable } from '@angular/core';
import { ListDto } from '@bupple/interfaces';
import { AssetDefinition } from '@cesdk/cesdk-js';
import { Observable } from 'rxjs';
import { SceneApi } from '../apis/scene.api';
import {
  CreateSceneDto,
  DeleteSceneResultDto,
  GetSceneDto,
  UploadAssetResult,
} from '../dtos/scene.dto';

@Injectable()
export class SceneService {
  constructor(private sceneApi: SceneApi) {}

  getAll(page = 1): Observable<ListDto<GetSceneDto>> {
    return this.sceneApi.getAll(page);
  }

  get(id: string): Observable<GetSceneDto> {
    return this.sceneApi.get(id);
  }

  add(model?: CreateSceneDto): Observable<GetSceneDto> {
    return this.sceneApi.add(model);
  }

  update(sceneId: string, scene: CreateSceneDto): Observable<GetSceneDto> {
    return this.sceneApi.update(sceneId, scene);
  }

  delete(id: string): Observable<DeleteSceneResultDto> {
    return this.sceneApi.delete(id);
  }

  getGlobalAssets(): Observable<AssetDefinition[]> {
    return this.sceneApi.getGlobalAsssets();
  }

  uploadAsset(sceneId: string, file: File): Observable<UploadAssetResult> {
    return this.sceneApi.uploadAsset(sceneId, file);
  }

  uploadGlobalAsset(file: File): Promise<UploadAssetResult> {
    return this.sceneApi.uploadGlobalAsset(file);
  }
}
