import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { ButtonComponent } from '@bupple/button';
import { NavigationService, SocialNetwork } from '@bupple/core';
import { UiIconComponent } from '@bupple/ui/icon';
import { ConnectAccountCardComponent } from '../connect-account-card/connect-account-card.component';
import { ConnectAccountFacebookComponent } from '../connect-account-facebook/connect-account-facebook.component';
import { ConnectAccountLinkedinComponent } from '../connect-account-linkedin/connect-account-linkedin.component';
import { ConnectAccountTiktokComponent } from '../connect-account-tiktok/connect-account-tiktok.component';
import { ConnectAccountTwitchComponent } from '../connect-account-twitch/connect-account-twitch.component';
import { ConnectAccountTwitterComponent } from '../connect-account-twitter/connect-account-twitter.component';
import { ConnectAccountsInstagramComponent } from '../connect-accounts-instagram/connect-accounts-instagram.component';

@Component({
  selector: 'bupple-connect-accounts',
  templateUrl: './connect-accounts.component.html',
  styleUrls: ['./connect-accounts.component.scss'],
  standalone: true,
  imports: [
    ConnectAccountCardComponent,
    ConnectAccountsInstagramComponent,
    ConnectAccountFacebookComponent,
    ConnectAccountTwitchComponent,
    ConnectAccountTwitterComponent,
    UiIconComponent,
    ConnectAccountLinkedinComponent,
    ConnectAccountTiktokComponent,
    ButtonComponent,
  ],
})
export class ConnectAccountsComponent {
  constructor(
    protected navigation: NavigationService,
    private dialogRef: MatDialogRef<ConnectAccountsComponent>,
  ) {}
  socialNetwork = SocialNetwork;

  onClickConnect(socialNetwork: SocialNetwork) {}

  onClickClose() {
    this.dialogRef.close();
  }
}
