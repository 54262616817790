<bupple-add-card-title
  title="Add Link"
  subtitle="Paste you link"
  icon="link"
  class="block mb-3"
></bupple-add-card-title>
<form [formGroup]="form" class="pt-3">
  <input
    type="text"
    class="input input-bordered"
    [placeholder]="'🔗' + ' Paste Here'"
    formControlName="content"
  />
  <lib-button
    block
    class="mt-6"
    [disabled]="form.invalid"
    (clickEvent)="onAdd()"
  >
    Send
  </lib-button>
</form>
