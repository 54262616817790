import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component } from '@angular/core';
import { MatBottomSheetRef } from '@angular/material/bottom-sheet';
import { Command } from '@bupple/interfaces';
import { ModalResult } from '../../interfaces/modal-result.interface';
import { ButtonComponent } from '@bupple/button';

@Component({
  selector: 'install-new-version',
  standalone: true,
  imports: [CommonModule, ButtonComponent],
  templateUrl: './install-new-version.component.html',
  styleUrl: './install-new-version.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InstallNewVersionComponent {
  constructor(private bottomSheetRef: MatBottomSheetRef) {}

  onClickNotNow() {
    this.bottomSheetRef.dismiss();
  }

  onClickInstall() {
    const commad: Command = {
      execute: () => {
        document.location.reload();
      },
    };
    const modalResult: ModalResult = {
      command: commad,
      data: null,
      error: null,
    };

    this.bottomSheetRef.dismiss(modalResult);
  }
}
