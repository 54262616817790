import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  OnDestroy,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';
import {
  FormBuilder,
  FormGroup,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { RouterModule } from '@angular/router';
import { UiIconComponent } from '@bupple/ui/icon';
import { UiPatternBottomComponent } from '@bupple/ui-pattern-bottom';
import { TopPatternComponent } from '@bupple/top-pattern';
import { AuthHeaderComponent } from '@bupple/auth-header';
import { ButtonComponent } from '@bupple/button';
import { ProductHuntBannerComponent } from '@bupple/product-hunt-banner';
import {
  AuthService,
  GoogleTagManagerService,
  NavigationService,
  ToasterService,
} from '@bupple/core';
import { SubSink } from 'subsink';

@Component({
  selector: 'lib-feature-forgot-password',
  standalone: true,
  imports: [
    CommonModule,
    MatIconModule,
    ReactiveFormsModule,
    RouterModule,
    UiIconComponent,
    UiPatternBottomComponent,
    TopPatternComponent,
    AuthHeaderComponent,
    ButtonComponent,
    ProductHuntBannerComponent,
  ],
  templateUrl: './feature-forgot-password.component.html',
  styleUrl: './feature-forgot-password.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FeatureForgotPasswordComponent implements OnDestroy {
  constructor(
    private fb: FormBuilder,
    private authService: AuthService,
    protected navigation: NavigationService,
    private cdr: ChangeDetectorRef,
    private toaster: ToasterService,
    private gtmService: GoogleTagManagerService,
  ) {}

  form: FormGroup = this.fb.group({
    email: [null, [Validators.required, Validators.email]],
  });

  formIsValid(name: string) {
    return (
      this.form.controls[name].invalid &&
      this.form.controls[name].touched &&
      this.form.controls[name].dirty
    );
  }

  subsink = new SubSink();
  inProgress = false;
  showDescription = false;
  onSubmit() {
    if (this.form.valid) {
      this.inProgress = true;
      this.subsink.sink = this.authService
        .forgotPassword(this.form.value.email)
        .subscribe({
          next: () => {
            this.showDescription = true;
            this.cdr.markForCheck();
          },
          error: (error) => {
            this.inProgress = false;
            const message = error.message || error.msg;
            message && this.toaster.error(message);
            this.cdr.markForCheck();
          },
        });
    } else {
      this.toaster.error('Please enter a valid email address');
    }
  }

  onContinueWithGoogle() {
    this.gtmService.pushToDataLayer({
      event: 'button-click',
      buttonId: 'sign-in-with-google',
    });

    this.subsink.sink = this.authService.continueWithGoogle().subscribe({
      next: (success: boolean) => {
        if (success) {
          this.navigation.goHome();
        }
      },
      error: (error: Error) => {
        this.toaster.error(error.message);
      },
    });
  }

  onContinueWithFacebook() {
    this.gtmService.pushToDataLayer({
      event: 'button-click',
      buttonId: 'sign-in-with-facebook',
    });

    this.subsink.sink = this.authService.continueWithFacebook().subscribe({
      next: (success: boolean) => {
        if (success) {
          this.navigation.goHome();
        }
      },
      error: (error: Error) => {
        this.toaster.error(error.message);
      },
    });
  }

  ngOnDestroy(): void {
    this.subsink.unsubscribe();
  }
}
