<section class="w-full h-screen fixed top-0 left-0 right-0 bottom-0 z-10">
  <div class="w-full h-full bg-black flex justify-center items-center">
    <!-- Noise -->
    <img
      class="absolute top-0 bottom-0 left-0 right-0 object-cover h-full w-full z-20"
      src="assets/images/Y/Noise.png"
      alt="noize"
    />

    <!-- Gradient -->
    <img
      class="absolute left-1/2 -translate-x-1/2 -bottom-1 w-full h-[300px] object-cover z-10 md:hidden"
      src="assets/images/Y/Gradient-1.png"
      alt="Gradient"
    />

    <!-- splash Logo     -->
    <img
      src="assets/images/Y/splash-logo.png"
      class="relative z-30"
      alt="splash logo"
    />
  </div>
</section>
