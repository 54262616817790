<div class="flex max-w-[46rem] bg-accent/12 p-3 items-center rounded-xl mx-auto md:w-full">
    <div class="flex-1 flex gap-x-4 items-center">
        <img class="w-8 h-8"
            src="assets/images/product-hunt-icon.png"
            alt="product hunt icon">
        <p class="sub-text-xl text-base-content">
            Welcome to Bupple 👋 We have Special offer for <strong class="title-sm">Product Hunt</strong> users after
            sign up!
        </p>
    </div>
    <bupple-icon class="flex-none"
        (click)="closeBanner()"
        name="Bold / Essentional, UI / Close Circle"
        size="lg"></bupple-icon>
</div>