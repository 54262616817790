export enum KeyOfPing {
  // Message type
  type = 'TYPE',
  // Beginning Message Broadcast
  bmb = 'BMB',
  // Raw Text
  text = 'TXT',
  // End of Message
  eom = 'EOM',
  // Quick Notification Message
  qnm = 'QNM',
  // End of File
  eof = 'EOF',
}
