import { ChangeDetectionStrategy, Component } from '@angular/core';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'lib-subtitle-line-skeleton',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './subtitle-line-skeleton.component.html',
  styleUrl: './subtitle-line-skeleton.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SubtitleLineSkeletonComponent {}
