import { Injectable } from '@angular/core';
import {
  trackItem,
  TrackVideoItem,
} from '../interfaces/timeline-track-item.interface';
import { VideoClip } from '../interfaces/video-clip.interface';

@Injectable()
export class VideoElementService {
  calculateTotalTimeBeforeThisTrack(
    allItems: TrackVideoItem[],
    item: TrackVideoItem,
  ): number {
    const index = allItems.map((i) => i.id).indexOf(item.id);
    let totalTime = 0;

    for (let i = 0; i < index; i++) {
      totalTime += allItems[i].length;
    }

    return totalTime;
  }

  // generateHtmlVideoElement(
  //   clip: TrackVideoItem,
  //   pixi: Application,
  // ): HTMLVideoElement {
  //   const video = document.createElement('video');
  //   video.src = clip.asset.video_url;
  //   video.crossOrigin = 'anonymous';
  //   video.preload = 'auto';
  //   video.autoplay = false;
  //   video.loop = false;
  //   video.width = pixi.screen.width;
  //   video.height = pixi.screen.height;
  //   return video;
  // }

  getVideoElement(clip: trackItem, video: HTMLVideoElement): VideoClip {
    return {
      clipId: clip.id as string,
      element: video,
      lastTimeUpdate: 0,
    };
  }
}
