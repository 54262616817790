import { Injectable } from '@angular/core';
// import { Observable } from 'rxjs';
import { Observable } from 'rxjs';

import {
  ConnectUrlDto,
  CoreFacade,
  StorageService,
  UserWithTokenResponseDto,
} from '@bupple/core';
import { HttpClientAdaptor } from '@bupple/util/adaptors';

@Injectable({
  providedIn: 'root',
})
export class IntegrationsService {
  constructor(
    private coreFacade: CoreFacade,
    private storage: StorageService,
    private http: HttpClientAdaptor,
  ) {}

  storeAutherizationState(state: UserWithTokenResponseDto) {
    this.coreFacade.setUser(state.user);
    this.storage.setToken(state.token);
  }

  screenWidth = window.screen.width;
  screenHeight = window.screen.height;
  width = Math.min(600, this.screenWidth - 20);
  height = 600;
  left = (this.screenWidth - this.width) / 2;
  top = (this.screenHeight - this.height) / 2;

  connectAccount(social: string): Observable<boolean> {
    return new Observable((observer) => {
      this.http.get<ConnectUrlDto>('account/token/' + social).subscribe({
        next: (response) => {
          const responseUrl = response.url;
          window.open(
            responseUrl,
            'Connect Facebook Auth',
            `width=${this.width},height=${this.height},top=${this.top},left=${this.left}`,
          );
          observer.next(true);
        },
      });
    });
  }
}
