import { CommonModule } from '@angular/common';
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  ViewChild,
} from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import {
  CREATIVE_CONFIG,
  CreativeEditorService,
  CreativeSceneService,
} from '@bupple/core';
import {
  IdeaboardChatApi,
  IdeaboardChatService,
} from '@bupple/studio/data-access';
import CreativeEditorSDK from '@cesdk/cesdk-js';
import { combineLatest, filter } from 'rxjs';

@Component({
  selector: 'lib-feature-editor',
  standalone: true,
  imports: [CommonModule],
  providers: [CreativeEditorService, IdeaboardChatService, IdeaboardChatApi],
  templateUrl: './feature-editor.component.html',
  styleUrl: './feature-editor.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FeatureEditorComponent implements AfterViewInit {
  constructor(
    private creativeEditorService: CreativeEditorService,
    private editorSceneService: CreativeSceneService,
    private route: ActivatedRoute,
    private ideaboardChatService: IdeaboardChatService,
  ) {}

  @ViewChild('cesdk_container') containerRef: ElementRef = {} as ElementRef;

  editor!: CreativeEditorSDK;
  ngAfterViewInit() {
    this.listenRoute();
  }

  chatId!: string;
  listenRoute() {
    combineLatest([this.route.params, this.route.queryParams])
      .pipe(filter(([{ id }, { scene }]) => !!id && !!scene))
      .subscribe(async ([{ id }, { scene }]) => {
        this.chatId = id;
        this.editor = await this.initializeEditor();

        await this.setupEmptyScene();
        await this.loadScene(scene);
      });
  }

  async initializeEditor(): Promise<CreativeEditorSDK> {
    const instance = await this.creativeEditorService.getEditor(
      this.containerRef,
      {
        ...CREATIVE_CONFIG,
        ui: {
          ...CREATIVE_CONFIG.ui,
          elements: {
            ...CREATIVE_CONFIG.ui?.elements,
            navigation: {
              action: {
                custom: [
                  {
                    label: 'Save Changes',
                    iconName: 'save',
                    callback: () => {
                      this.updateScene();
                    },
                  },
                ],
              },
            },
          },
        },
      },
    );

    return instance;
  }

  async setupEmptyScene() {
    await this.editor.engine.scene.create();
  }

  async loadScene(scene: string) {
    await this.editorSceneService.loadSceneFromUrl(this.editor.engine, scene);
  }

  async updateScene() {
    const scene = await this.editorSceneService.getCurrentScene(
      this.editor.engine,
    );

    this.ideaboardChatService
      .updateCreative(this.chatId, scene)
      .subscribe((result) => {
        console.log(result);
      });
  }
}
