<!-- Header of entry pages  -->
<div class="md:flex md:flex-row-reverse md:justify-between">
  <div class="w-full flex justify-start items-center relative min-h-16 md:flex-none md:w-fit md:self-start">
    <form method="dialog">
      <button (click)="onClickClose()">
        <bupple-icon class="flex-none absolute top-1/2 -translate-y-1/2 right-4 z-50"
          name="Outline / Essentional, UI / Close Circle"
          size="xl"></bupple-icon>
      </button>
    </form>
    <lib-button class="md:hidden"
      color="neutral"
      appearance="text">
      Connect Account
    </lib-button>
  </div>

  <div class="p-page md:p-8 md:pb-0 md:flex-1">
    <div class="flex mb-6">
      <div class="w-full max-w-80 md:max-w-[calc(100%_-_2rem)]">
        <h2 class="title-l-bold">Connect your Accounts</h2>
        <p class="text-sm text-base-content-secondary md:sub-text-xl">
          Connect your accounts to share your post and more awesome features.
        </p>
      </div>
    </div>
  </div>
</div>
<div class="flex flex-col gap-3 px-page md:px-8">
  <bupple-connect-accounts-instagram></bupple-connect-accounts-instagram>
  <bupple-connect-account-facebook></bupple-connect-account-facebook>
  <bupple-connect-account-twitter></bupple-connect-account-twitter>
  <bupple-connect-account-linkedin></bupple-connect-account-linkedin>
  <bupple-connect-account-tiktok></bupple-connect-account-tiktok>
</div>

<div class="h-full flex items-end pb-6 px-page md:hidden w-full">
  <lib-button (clickEvent)="onClickClose()"
    block> Continue </lib-button>
</div>