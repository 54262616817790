<div class="chat-container md:mb-8 bg-transparent">
  <!-- Actions -->
  @if (showActions) {
    <div
      class="p-2 md:px-0 gap-y-2 sm:gap-0 flex flex-wrap md:flex-row w-full cursor-pointer bg-transparent"
    >
      @for (action of chatActions; track $index) {
        <div class="flex-none w-1/2 md:w-1/4 px-1 bg-transparent">
          <bupple-ui-chat-action-item
            [action]="action"
            [hasDes]="false"
            creditSide="rt"
            class="block min-h-[3rem]"
            (clickAction)="onClickAction(action)"
          ></bupple-ui-chat-action-item>
        </div>
      }
    </div>
  }
  <!-- Input -->
  <div
    class="md:max-w-full bg-base-content/12 rounded-lg overflow-hidden border border-base-100"
  >
    <div
      class="inner md:!w-[calc(100%_-_2px)] md:mx-auto md:relative p-0 md:flex md:justify-center md:items-start z-10 justify-start"
    >
      <div
        class="w-full py-2 z-50 md:flex-none md:w-fit md:ml-2"
        *ngIf="selectedAction"
      >
        <div class="btn btn-xs gap-x-2m ml-4 md:ml-0">
          <span class="title-xxs-bold">
            {{ selectedAction && selectedAction.title }}
          </span>
          <lib-button
            circle
            size="xs"
            appearance="text"
            (clickEvent)="onCancelAction()"
          >
            <bupple-icon
              size="sm"
              name="Outline / Essentional, UI / Close Circle"
            ></bupple-icon>
          </lib-button>
        </div>
      </div>
      <div
        class="pb-3 pr-2 join w-full items-end bg-base-500 md:z-10 md:bg-transparent rounded-lg md:h-full md:min-h-full md:pl-2 md:placeholder-base-content/50 md:placeholder:sub-text-xl md:pb-0 overflow-hidden"
      >
        <textarea
          class="textarea join-item grow overflow-x-hidden border-none outline-none block min-h-12 max-h-[25dvh] px-2 placeholder:sub-text-xl-bold placeholder:leading-8 bg-transparent"
          [ngClass]="{ 'placeholder:pt-0 md:px-0 md:pt-2': selectedAction }"
          [(ngModel)]="enteredText"
          [placeholder]="selectedAction?.placeholder || 'Write a Message ...'"
          (focus)="onFocusInput($event)"
          (keyup.enter)="onEnter($event)"
          (keyup.backspace)="onBackspace($event)"
          #chatInput
          (input)="onInput()"
          (change)="onChangeInput()"
          rows="1"
        ></textarea>
        <button
          class="join-item btn-text btn-square"
          (click)="toggleActionsVisibility()"
          [disabled]="isRecievingMessage"
        >
          <span>
            <bupple-icon name="Bold / Chat Magic Action"></bupple-icon>
          </span>
        </button>
        <button
          *ngIf="!enteredText"
          class="join-item btn-text btn-square"
          [matMenuTriggerFor]="menu"
          [disabled]="isRecievingMessage"
        >
          <bupple-icon
            name="Outline / Essentional, UI / Add Square"
          ></bupple-icon>
        </button>
        <mat-menu #menu="matMenu" class="bg-base-500 rounded-lg">
          <button mat-menu-item (click)="addLink()">
            <mat-icon>link</mat-icon>
            Add Link
          </button>
          <button mat-menu-item (click)="addImage()">
            <mat-icon>image</mat-icon>
            Add Image
          </button>
          <button mat-menu-item (click)="addVideo()">
            <mat-icon>videocam</mat-icon>
            Add Video
          </button>
        </mat-menu>
        <button
          class="join-item btn-text btn-square"
          (click)="onEnter()"
          *ngIf="enteredText"
          [disabled]="isRecievingMessage"
        >
          <bupple-icon name="Bold / Chat Input Bar / Send"></bupple-icon>
        </button>
      </div>
    </div>
  </div>
</div>
