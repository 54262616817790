import { CommonModule, NgClass } from '@angular/common';
import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import { ButtonComponent } from '@bupple/button';
import { SocialNetwork } from '@bupple/core';
import { UiIconComponent } from '@bupple/ui/icon';
import { PascalCasePipe } from '@bupple/util/pipes';
import {
  socialMediaIconMap,
  socialMediaTitleMap,
} from '../constants/socialmedia-names.constants';

@Component({
  selector: 'bupple-connect-account-card',
  templateUrl: './connect-account-card.component.html',
  styleUrls: ['./connect-account-card.component.scss'],
  standalone: true,
  imports: [
    PascalCasePipe,
    UiIconComponent,
    NgClass,
    CommonModule,
    ButtonComponent,
  ],
})
export class ConnectAccountCardComponent implements OnInit {
  connectedAccountsInput: any[] = [];
  @Input({ required: true }) set connectedAccounts(value: any[]) {
    this.connectedAccountsInput = value;
    this.cdr.markForCheck();
  }
  @Input({ required: true }) socialMedia!: SocialNetwork;
  @Input({ required: true }) iconBg?: string;
  // @Input({ required: false }) connectedAccounts!: any[];
  @Input({ required: false }) connectDisable = false;

  constructor(private cdr: ChangeDetectorRef) {}

  @Output() clickConnect: EventEmitter<MouseEvent> = new EventEmitter();
  iconName = '';
  platformName = '';
  ngOnInit(): void {
    this.platformName = socialMediaTitleMap.get(this.socialMedia) as string;
    this.setIconName(this.socialMedia);
  }

  setIconName(socialMedia: SocialNetwork) {
    if (!socialMedia) return socialMedia;
    this.iconName = socialMediaIconMap.get(this.socialMedia) as string;
  }

  connectedAccount() {
    this.clickConnect.emit();
  }
}
