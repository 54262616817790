<button
  [type]="type"
  [disabled]="disabled"
  [ngClass]="_classes"
  [routerLink]="routerLink"
  (click)="onClick($event)"
>
  <span
    *ngIf="loading"
    class="loading loading-spinner"
    [class.loading-xs]="size === 'xs'"
    [class.loading-sm]="size === 'sm'"
    [class.loading-md]="size === 'md'"
    [class.loading-lg]="size === 'lg'"
    [class.text-base-100]="color === 'neutral' && appearance !== 'outline'"
    [class.text-base-content]="color !== 'neutral'"
  ></span>

  <ng-content *ngIf="!(loading && (square || circle))"></ng-content>
</button>
