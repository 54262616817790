import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { CoreFacade, NavigationService, SocialNetwork } from '@bupple/core';
import { SubSink } from 'subsink';
import { ConnectAccountCardComponent } from '../connect-account-card/connect-account-card.component';
import { IntegrationsService } from '../services/integrations.service';

@Component({
  selector: 'bupple-connect-accounts-instagram',
  standalone: true,
  imports: [CommonModule, ConnectAccountCardComponent],
  templateUrl: './connect-accounts-instagram.component.html',
  styleUrl: './connect-accounts-instagram.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ConnectAccountsInstagramComponent implements OnInit {
  socialMedial = SocialNetwork;
  connectedAccounts: any[] = [];
  constructor(
    private coreFacade: CoreFacade,
    private integrationsService: IntegrationsService,
    protected navigation: NavigationService,
  ) {}

  subsink = new SubSink();
  ngOnInit(): void {
    this.connectedAccountsHandler();
  }

  onClickConnectInstagram() {
    this.integrationsService.connectAccount('facebook').subscribe();
  }

  connectedAccountsHandler() {
    this.coreFacade.user$.subscribe((user) => {
      this.connectedAccounts = (user?.linked_accounts as any[])?.filter(
        (account) => account.nw === SocialNetwork.instagram,
      );
    });
  }
}
