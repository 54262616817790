import { Ideaboard } from '@bupple/core';
import { ListDto } from '@bupple/interfaces';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { message } from '../dtos/message.dto';
import { PublishableDto } from '../dtos/post.dto';
import { STUDIO_FEATURE_KEY, StudioState } from './studio.reducer';

const selectStudioState =
  createFeatureSelector<StudioState>(STUDIO_FEATURE_KEY);

export const activeIdeaboard = createSelector(
  selectStudioState,
  (state: StudioState) => {
    return state.activeIdeaboard as Ideaboard;
  },
);

export const activeIdeaboardId = createSelector(
  selectStudioState,
  (state: StudioState) => state.activeIdeaboard?._id as string,
);

export const chatInputValue = createSelector(
  selectStudioState,
  (state: StudioState) => state.chatInputValue,
);

export const chatMessage = createSelector(
  selectStudioState,
  (state: StudioState) => state.chatMessage,
);

export const homeInputChatMessage = createSelector(
  selectStudioState,
  (state: StudioState) => state.homeInputChatMessage,
);

export const activeIdeaboardChat = createSelector(
  selectStudioState,
  (state: StudioState) => state.activeIdeaboardChat as ListDto<message>,
);

export const publishableId = createSelector(
  selectStudioState,
  (state: StudioState) => state.publishableId,
);

export const publishable = createSelector(
  selectStudioState,
  (state: StudioState) => state.publishable as PublishableDto,
);

export const isRecievingMessage = createSelector(
  selectStudioState,
  (state: StudioState) => state.isRecievingMessage,
);

export const timeToScroll = createSelector(
  selectStudioState,
  (state: StudioState) => state.timeToScroll,
);

export const selectedAction = createSelector(
  selectStudioState,
  (state: StudioState) => state.selectedAction,
);

export const ideaboards = createSelector(
  selectStudioState,
  (state: StudioState) => state.ideaboards,
);

export const recentIdeaboards = createSelector(
  selectStudioState,
  (state: StudioState) => state.ideaboards?.data.slice(0, 10),
);

export const homeIdeaboards = createSelector(
  selectStudioState,
  (state: StudioState) => state.ideaboards?.data.slice(0, 6),
);

export const posts = createSelector(
  selectStudioState,
  (state: StudioState) => state.posts,
);

export const videoProgress = createSelector(
  selectStudioState,
  (state: StudioState) => state.videoProgress,
);

export const videoNotif = createSelector(
  selectStudioState,
  (state: StudioState) => state.videoNotif,
);

export const isPostPublished = createSelector(
  selectStudioState,
  (state: StudioState) => state.isPostPublished,
);
