import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Environment } from '@bupple/interfaces';
import { Observable } from 'rxjs';
import { StorageService } from '../services/storage.service';

@Injectable({
  providedIn: 'root',
})
export class TokenInterceptor implements HttpInterceptor {
  constructor(
    private storage: StorageService,
    @Inject('environment') private environment: Environment,
  ) {}

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler,
  ): Observable<HttpEvent<any>> {
    req = req.url?.includes(this.environment.api)
      ? req.clone({
          headers: req.headers
            .set(
              'Authorization',
              this.storage.token ? `Bearer ${this.storage.token}` : '',
            )
            .set('Access-Control-Allow-Origin', '*'),
        })
      : req;

    return next.handle(req);
  }
}
