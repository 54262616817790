import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { RouterLink, RouterLinkActive } from '@angular/router';
import { UiIconComponent } from '@bupple/ui/icon';
import { MenuService } from '@bupple/util/services';
import { MAIN_PAGES_MENU } from '../constants/main-pages-menu.constant';

@Component({
  selector: 'lib-ui-bottom-menu',
  standalone: true,
  imports: [CommonModule, RouterLink, RouterLinkActive, UiIconComponent],
  providers: [MenuService],
  templateUrl: './ui-bottom-menu.component.html',
  styleUrl: './ui-bottom-menu.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UiBottomMenuComponent implements OnInit {
  constructor(protected menuService: MenuService) {}

  ngOnInit(): void {
    this.menuService.list = MAIN_PAGES_MENU;
  }
}
