import { Injectable } from '@angular/core';
import { CreativeEngine as EditorEngine } from '@cesdk/cesdk-js';
import CreativeEngine from '@cesdk/engine';
import { ScenePreviewResult } from '../interfaces/scene-preview.result';
import { forEach } from 'lodash-es';
import { ReceivedMessageDto } from '@bupple/studio/data-access';
import { log } from 'console';

type engine = CreativeEngine | EditorEngine;
@Injectable({
  providedIn: 'root',
})
export class CreativeSceneService {
  async loadSceneFromUrl(engine: engine, sceneUrl: string) {
    await engine.scene.loadFromURL(
      sceneUrl + `?cacheBust=${new Date().getTime()}`,
    );
  }

  async createScene(engine: engine): Promise<string> {
    const scene = await engine.scene.create();
    const page = engine.block.create('page');
    engine.block.setWidth(page, 1024);
    engine.block.setHeight(page, 1024);
    engine.block.appendChild(scene, page);
    const stringifiedScene = await engine.scene.saveToString();
    return stringifiedScene;
  }

  async getCurrentScene(engine: engine) {
    const scene = await engine.scene.saveToString();
    return scene;
  }

  async getScenePreview(
    engine: engine,
    receivedMessage: ReceivedMessageDto,
  ): Promise<ScenePreviewResult> {
    await engine.scene.loadFromURL(
      receivedMessage.file_full_path + `?cacheBust=${new Date().getTime()}`,
    );

    this.replaceAssets(engine, receivedMessage);

    const scene = engine.scene.get();
    const mimeType = 'image/png';

    engine.editor.getMaxExportSize();

    const options = {
      targetWidth: null,
      targetHeight: null,
    };

    const blob = await engine.block.export(
      scene as number,
      mimeType as any,
      options as any,
    );

    const url = URL.createObjectURL(blob);

    // this.updateScene(engine, receivedMessage.ideaboard_id);

    return { blob, url };
  }

  replaceAssets(engine: engine, receivedMessage: ReceivedMessageDto) {
    if (receivedMessage.meta?.scene_assets !== undefined) {
      forEach(receivedMessage.meta?.scene_assets, (asset) => {
        const blockId = engine.block.findByName(asset.key)[0];
        if (asset.type === 'text') {
          engine.block.replaceText(blockId, asset.value);
        }
        if (asset.type === 'image') {
          const imageFill = engine.block.createFill('image');
          engine.block.setString(
            imageFill,
            'fill/image/imageFileURI',
            asset.value,
          );

          engine.block.setFill(blockId, imageFill);

          console.log('Asghar', engine.block.getType(blockId));
        }
      });
    }
  }
}
