import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  CUSTOM_ELEMENTS_SCHEMA,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { FormsModule } from '@angular/forms';
import {
  MatBottomSheet,
  MatBottomSheetModule,
} from '@angular/material/bottom-sheet';
import { MatIconModule } from '@angular/material/icon';
import { RouterModule } from '@angular/router';
import { ButtonComponent } from '@bupple/button';
import {
  BannersService,
  CoreFacade,
  Ideaboard,
  MessageAction,
  NavigationService,
  PromotionBannerDto,
  StationService,
} from '@bupple/core';
import {
  IdeaboardCardComponent,
  IdeaboardCardSkeletonComponent,
} from '@bupple/ideaboard-card';
import {
  PromotionBannerComponent,
  PromotionBannerSkeletonComponent,
} from '@bupple/promotion-banner';
import {
  IdeaboardApi,
  IdeaboardChatApi,
  IdeaboardChatService,
  IdeaboardService,
  MessageType,
  SseService,
  StudioFacade,
} from '@bupple/studio/data-access';
import { DeleteIdeaboardComponent } from '@bupple/studio/feature-ideaboards';
import { TopPatternComponent } from '@bupple/top-pattern';
import { UiBottomMenuComponent } from '@bupple/ui-bottom-menu';
import { UiChatActionItemComponent } from '@bupple/ui/chat-action-item';
import { UiIconComponent } from '@bupple/ui/icon';
import { UiLoadingComponent } from '@bupple/ui/loading';
import { UiNoDataFoundComponent } from '@bupple/ui/no-data-found';
import { finalize } from 'rxjs';
import { SubSink } from 'subsink';
import { TutorialComponent } from '../tutorial/tutorial.component';

@Component({
  selector: 'bupple-feature-home',
  standalone: true,
  imports: [
    CommonModule,
    MatIconModule,
    RouterModule,
    UiChatActionItemComponent,
    UiNoDataFoundComponent,
    UiLoadingComponent,
    IdeaboardCardComponent,
    UiIconComponent,
    MatBottomSheetModule,
    UiBottomMenuComponent,
    TopPatternComponent,
    ButtonComponent,
    FormsModule,
    PromotionBannerComponent,
    TutorialComponent,
    IdeaboardCardSkeletonComponent,
    PromotionBannerSkeletonComponent,
  ],
  providers: [
    IdeaboardChatService,
    IdeaboardChatApi,
    StudioFacade,
    IdeaboardService,
    IdeaboardApi,
    SseService,
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  templateUrl: './feature-home.component.html',
  styleUrls: ['./feature-home.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FeatureHomeComponent implements OnInit, OnDestroy {
  constructor(
    protected navigation: NavigationService,
    private cdr: ChangeDetectorRef,
    private ideaboardService: IdeaboardService,
    private rootService: StationService,
    private bottomSheet: MatBottomSheet,
    private studioFacade: StudioFacade,
    private bannerService: BannersService,
    private coreFacade: CoreFacade,
  ) {}

  ngOnInit(): void {
    this.getHomePageData();
    this.listenStore();
  }

  subsink = new SubSink();
  chatActions: MessageAction[] = [];
  ideaboards: Ideaboard[] = [];
  inProgress = false;
  getHomePageData() {
    this.inProgress = true;
    this.rootService
      .heart()
      .pipe(
        finalize(() => {
          this.inProgress = false;
          this.cdr.detectChanges();
        }),
      )
      .subscribe((result) => {
        this.chatActions = result.buttons;
      });
  }

  loading = true;
  bannerData: PromotionBannerDto = { title: '', description: '', url: '' };
  bannerLoading = false;
  listenStore() {
    this.subsink.sink = this.studioFacade.homeIdeaboards$.subscribe(
      (ideaboards) => {
        if (ideaboards) {
          this.ideaboards = ideaboards;
          this.loading = false;
          this.cdr.markForCheck();
        }
      },
    );

    this.subsink.sink = this.coreFacade.promotionBanner$.subscribe((banner) => {
      this.bannerLoading = true;
      if (banner) {
        this.bannerData = banner;
        this.bannerLoading = false;
        this.cdr.markForCheck();
      }
    });
  }

  inProgressCreate = false;
  chatMessage = '';
  onCreateIdeaboard() {
    if (this.inProgressCreate) {
      return;
    }

    this.inProgressCreate = true;
    this.subsink.sink = this.ideaboardService.add().subscribe({
      next: (ideaboard: Ideaboard) => {
        this.chatMessage && this.setHomeInputMessage();
        this.navigation.goIdeaboard(ideaboard._id);
      },
      error: () => {
        this.inProgressCreate = false;
        this.cdr.markForCheck();
      },
    });
  }

  setHomeInputMessage() {
    this.studioFacade.setHomeInputChatMessage({
      content: this.chatMessage,
      type: MessageType.text,
    });
  }

  onClickAction(action: MessageAction) {
    this.subsink.sink = this.ideaboardService.add().subscribe({
      next: (result: Ideaboard) => {
        this.navigation.goIdeaboard(result._id, {
          queryParams: {
            action: action.slug,
          },
        });
      },
      error: (error: Error) => {
        this.inProgressCreate = false;
        this.cdr.markForCheck();
      },
    });
  }

  onDeleteIdeaboard(event: MouseEvent, ideaboard: Ideaboard) {
    this.subsink.sink = this.bottomSheet
      .open(DeleteIdeaboardComponent, {
        data: ideaboard,
      })
      .afterDismissed()
      .subscribe((result) => {
        result && this.deleteIdeaboard(ideaboard._id);
      });
  }

  deleteIdeaboard(id: string): void {
    this.ideaboards = this.ideaboards.map((i) =>
      i._id === id ? { ...i, inProgress: true } : i,
    );
    this.cdr.markForCheck();

    this.subsink.sink = this.ideaboardService
      .delete(id)
      .pipe(
        finalize(() => {
          this.cdr.markForCheck();
        }),
      )
      .subscribe({
        next: (result) => {
          if (result.msg) {
            this.ideaboards = this.ideaboards.filter((i) => i._id !== id);
          }
        },
        error: () => {
          this.ideaboards = this.ideaboards.map((i) =>
            i._id === id ? { ...i, inProgress: false } : i,
          );
        },
      });
  }

  ngOnDestroy(): void {
    this.subsink.unsubscribe();
  }
}
