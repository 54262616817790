import { Injectable } from '@angular/core';
import {
  DeleteIdeaboardResultDto,
  Ideaboard,
  IdeaboardDto,
} from '@bupple/core';
import { ListDto } from '@bupple/interfaces';
import { Observable, map, tap } from 'rxjs';
import { StudioFacade } from '../+state/studio.facade';
import { IdeaboardApi } from '../apis/ideaboard.api';

@Injectable()
export class IdeaboardService {
  constructor(
    private studioFacade: StudioFacade,
    private ideaboardApi: IdeaboardApi,
  ) {}

  getAll(page = 1): Observable<ListDto<Ideaboard>> {
    return this.ideaboardApi.getAll(page).pipe(
      map((list) => ({
        ...list.ideaboards,
        data: list.ideaboards.data.map((i) => new Ideaboard(i)),
      })),
      tap((result) => {
        this.studioFacade.setIdeaboards(result);
      }),
    );
  }

  get(id: string): Observable<Ideaboard> {
    return this.ideaboardApi.get(id).pipe(
      map((result) => new Ideaboard(result.ideaboard)),
      tap((ideaboard) => {
        this.studioFacade.setActiveIdeaboard(ideaboard);
      }),
    );
  }

  add(): Observable<Ideaboard> {
    return this.ideaboardApi.add().pipe(
      map((result) => new Ideaboard(result.ideaboard)),
      tap((ideaboard) => {
        this.studioFacade.addNewIdeaboard(ideaboard);
      }),
    );
  }

  updateIdeaboardName(ideaboard: IdeaboardDto): Observable<Ideaboard> {
    return this.ideaboardApi.update(ideaboard).pipe(
      map((result) => new Ideaboard(result.ideaboard)),
      tap((ideaboard) => {
        this.studioFacade.updateIdeaboardName(ideaboard.name, ideaboard._id);
      }),
    );
  }

  delete(id: string): Observable<DeleteIdeaboardResultDto> {
    return this.ideaboardApi.delete(id).pipe(
      tap(() => {
        this.studioFacade.removeIdeaboard(id);
      }),
    );
  }

  getVideoData(ideaboardId: string) {
    const script = `Ready to turn your little pumpkin into the cutest creature on the block? Let’s dive into some outrageously adorable Halloween costume ideas for babies that are as fun to dress up in as they are to admire! As a parent, you know the challenge of trying to wrangle a squirmy baby into a costume. But here's the secret sauce—pick something that’s comfy and easy for those last-minute diaper changes. Think soft onesies with a sprinkle of creative flair! How about a cuddly bumblebee or a snuggly pumpkin that just slides on? Trust me, you’ll thank me later when you see how easily these come together. If you’re planning any photo ops for Instagram, get ready to see your feed blowing up with likes and heart-eye emojis. Babies dressed as little acorns or fluffy lambs? Yes, please! You’ll have your followers gushing in no time. And here’s the game changer: layering! Choose costumes that allow you to layer under or over clothing for a quick switcheroo when needed. Making costume changes fun and stress-free is key to keeping the Halloween spirit high! So, are you pumped to get your baby styled for Spooktober? Hit that like button if you’re inspired and share your baby’s costume ideas in the comments—can’t wait to see all the cuteness overload!`;

    return this.ideaboardApi.getVideoData(ideaboardId, script);
  }
}
