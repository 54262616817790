import { createFeatureSelector, createSelector } from '@ngrx/store';
import { CORE_FEATURE_KEY, CoreState } from './core.reducer';

export const selectCoreState =
  createFeatureSelector<CoreState>(CORE_FEATURE_KEY);

export const initialized = createSelector(
  selectCoreState,
  (state: CoreState) => state.initialized,
);

export const user = createSelector(
  selectCoreState,
  (state: CoreState) => state.user,
);

export const theme = createSelector(
  selectCoreState,
  (state: CoreState) => state.theme,
);

export const promotionBanner = createSelector(
  selectCoreState,
  (state: CoreState) => state.promotionBanner,
);

export const posts = createSelector(
  selectCoreState,
  (state: CoreState) => state.posts,
);

export const userCredit = createSelector(
  selectCoreState,
  (state: CoreState) => state.userCredit,
);

export const currentCredit = createSelector(
  selectCoreState,
  (state: CoreState) => state.userCredit?.current_credit,
);
