<bupple-top-pattern></bupple-top-pattern>

<div class="px-page">
  <div class="mt-7 md:hidden">
    <lib-promotion-banner
      *ngIf="!bannerLoading"
      class=""
      [title]="bannerData.title"
      [subtitle]="bannerData.description"
      [image]="bannerData.url"
      background="bg-gradient-to-br from-[#883FFF] to-[#00D1FF]  from-45%"
      (click)="navigation.goPlans()"
    ></lib-promotion-banner>

    <lib-promotion-banner-skeleton
      *ngIf="bannerLoading"
    ></lib-promotion-banner-skeleton>
  </div>

  <div class="md:gardient-gray md:py-8 w-full">
    <div class="md:max-w-[56.5rem] mx-auto">
      <!-- Input Title  -->
      <div class="md:block hidden space-y-1">
        <span class="flex gap-2 items-end">
          <img
            class="w-11"
            src="assets/images/Y/desktop/icon/heart-note.svg"
            alt="heart-note"
          />
          <h2 class="title-xl text-base-content">Create with Bupple.</h2>
        </span>
      </div>

      <!-- input -->
      <div
        class="md:mt-7 max-w-full mx-auto mt-5 cursor-pointer bg-base-content/12 rounded-lg overflow-hidden"
      >
        <div
          class="inner flex items-center md:pr-6 max-h-[3.5rem] md:max-h-[4.5rem]"
        >
          <input
            type="text"
            class="outline-none h-[3.5rem] md:h-[4.5rem] bg-transparent placeholder-base-content/50 placeholder:sub-text-xl md:placeholder:title-base grow z-10 px-4"
            placeholder="Ask Bupple..."
            [(ngModel)]="chatMessage"
            (keyup.enter)="onCreateIdeaboard()"
          />
          <div class="hidden md:block">
            <lib-button
              block
              (clickEvent)="onCreateIdeaboard()"
              [loading]="inProgressCreate"
            >
              <span class="title-sm">Chat with Bupple AI</span>
              <bupple-icon
                name="Outline / Arrows / Arrow Right"
                size="md"
                color="base-content"
              ></bupple-icon>
            </lib-button>
          </div>
          <lib-button
            appearance="text"
            class="md:hidden"
            (clickEvent)="onCreateIdeaboard()"
            [loading]="inProgressCreate"
          >
            <bupple-icon
              name="Outline / Arrows / Arrow Right"
              color="base-content"
            ></bupple-icon>
          </lib-button>
        </div>
      </div>
      <p
        class="mt-4 text-base-content-secondary sub-text-xl tracking-[0.1px] md:title-base"
      >
        Start the chat or choose one of the actions to do magic together. 🎨
      </p>

      <!-- Actions -->
      <div class="mb-12 mt-4 md:mb-0 md:flex md:gap-2 w-full md:min-h-28">
        @for (action of chatActions; track $index) {
          <bupple-ui-chat-action-item
            [action]="action"
            class="block mb-2 w-full min-h-16 h-full cursor-pointer md:w-[calc(25%_-_2px)] md:min-h-[4.5rem]"
            (clickAction)="onClickAction(action)"
          ></bupple-ui-chat-action-item>
        }
      </div>
    </div>
  </div>

  <!-- Recent Ideaboards -->
  <div *ngIf="ideaboards.length">
    <p class="mb-6 title-base-bold md:title-l-bold">Recent Ideaboards</p>
    <div
      class="flex pb-10 md:pb-0 gap-4 flex-row flex-wrap md:w-full"
      *ngIf="!loading"
    >
      @for (ideaboard of ideaboards; track $index) {
        <bupple-ideaboard-card
          [ideaboard]="ideaboard"
          (delete)="onDeleteIdeaboard($event, ideaboard)"
          class="w-full p-page md:w-[22.5rem] bg-base-500 rounded-2xl"
        ></bupple-ideaboard-card>
      }
    </div>

    <div
      class="flex flex-col pb-10 md:pb-0 md:gap-4 md:flex-row flex-wrap md:w-full"
      *ngIf="loading"
    >
      <div
        *ngFor="let i of [0, 1, 2, 3, 4, 5]"
        class="w-full md:w-[22.5rem] md:bg-base-500 rounded-2xl md:p-4"
      >
        <bupple-ideaboard-card-skeleton></bupple-ideaboard-card-skeleton>
      </div>
    </div>
    @if (ideaboards.length > 6) {
      <div class="flex justify-center">
        <button
          class="btn btn-neutral btn-text bg-base-500 btn-block max-w-[24rem]"
          (click)="navigation.goIdeaboards()"
          *ngIf="!inProgress"
        >
          Show all ideaboards
          <bupple-icon
            name="Outline / Arrows / Arrow Right"
            color="base-content"
          ></bupple-icon>
        </button>
      </div>
    }
  </div>

  <!-- How to Use Bupple -->
  <section class="pt-10 pb-24 mb-10">
    <div class="flex mb-6">
      <p class="title-base-bold md:title-l-bold mr-4">How to use Bupple</p>
      <div class="flex items-center gap-x-2">
        <div class="swiper-button-prev">
          <bupple-icon
            name="Outline / Arrows / Round Alt Arrow Left"
            color="base-content"
            size="xl"
          ></bupple-icon>
        </div>
        <div class="swiper-button-next">
          <bupple-icon
            class="rotate-180"
            name="Outline / Arrows / Round Alt Arrow Left"
            color="base-content"
            size="xl"
          ></bupple-icon>
        </div>
      </div>
    </div>
    <bupple-tutorial></bupple-tutorial>
  </section>

  <div class="sticky bottom-0 left-0 w-full md:hidden">
    <lib-ui-bottom-menu></lib-ui-bottom-menu>
  </div>
</div>
