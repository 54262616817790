import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  HostBinding,
  Input,
  Output,
} from '@angular/core';
import { RouterLink } from '@angular/router';
import {
  buttonAppearance,
  buttonColor,
  buttonSize,
  buttonType,
} from '../types/button-input.type';

@Component({
  selector: 'lib-button',
  standalone: true,
  imports: [CommonModule, RouterLink],
  templateUrl: './button.component.html',
  styleUrl: './button.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ButtonComponent {
  @Input() type: buttonType = 'button';
  @Input() disabled = false;
  @Input() color: buttonColor = 'primary';
  @Input() appearance: buttonAppearance = 'filled';
  @Input() size: buttonSize = 'md';
  @Input() routerLink!: string;
  @Input() loading = false;

  private _square = false;
  @Input() set square(value: boolean | string) {
    this._square = value === true || value === '';
  }
  get square() {
    return this._square;
  }

  private _circle = false;
  @Input() set circle(value: boolean | string) {
    this._circle = value === true || value === '';
  }
  get circle() {
    return this._circle;
  }
  @Input() block: boolean | string = false;
  @HostBinding('class.block') get isBlock() {
    return this.block === '' || this.block === true;
  }

  @Output() clickEvent: EventEmitter<MouseEvent> = new EventEmitter();
  onClick(event: MouseEvent) {
    event.stopPropagation();
    event.preventDefault();
    this.clickEvent.emit(event);
  }

  protected get _classes(): string {
    return `btn btn-${this.color} btn-${this.appearance} ${this.size ? 'btn-' + this.size : ''} ${this.block ? 'btn-block' : ''} ${this.square === true || this.square === '' ? 'btn-square' : ''} ${this.circle === true || this.circle === '' ? 'btn-circle' : ''}`;
  }
}
