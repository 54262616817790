import { Inject, Injectable } from '@angular/core';
import { StorageService } from '@bupple/core';
import { Environment } from '@bupple/interfaces';
import Pusher, { Channel } from 'pusher-js';
import { StudioFacade } from '../+state/studio.facade';
import { PusherEventType } from '../enums/pusher-event-type.enum';

@Injectable({
  providedIn: 'root',
})
export class PusherService {
  constructor(
    private storage: StorageService,
    @Inject('environment') private environment: Environment,
    private studioFacade: StudioFacade,
  ) {}

  xsrfToken = '';
  ownerIdeaboardId = '';
  async listenIdeaboardEvents(ideaboardId: string) {
    this.ownerIdeaboardId = ideaboardId;
    this.subscribeIdeaboardChannel(ideaboardId);
  }
  private channel!: Channel;
  private creditChannel!: Channel;
  private async subscribeIdeaboardChannel(id: string) {
    const pusher = new Pusher(this.environment.pusher.key, {
      channelAuthorization: {
        endpoint: this.environment.pusher.authEndpoint,
        headers: {
          Authorization: 'Bearer ' + this.storage.token,
        },
        transport: 'ajax',
      },
      cluster: this.environment.pusher.cluster,
    });

    this.channel = pusher.subscribe('private-ideaboard.' + id);
    this.listenIdeaboardChanges();
  }

  private listenIdeaboardChanges() {
    this.channel.bind('ideaboard-update', (data: any) => {
      if (
        data?.updates?.chat &&
        data?.updates?.chat?.ideaboard_id !== this.ownerIdeaboardId
      ) {
        return;
      }

      if (
        data.type === PusherEventType.notifUpdate ||
        data.type === PusherEventType.chatUpdate
      ) {
        this.studioFacade.updateChatMessage(data.updates.chat);
        this.studioFacade.setVideoProgress(
          data.updates.chat.meta.notif.progress,
        );
        this.studioFacade.setVideoNotif(data.updates.chat.content);
      } else if (data.type === PusherEventType.newChat) {
        this.studioFacade.setRealReceivedMessage(data.updates.chat);
      } else if (data.type === PusherEventType.newPublishable) {
        this.studioFacade.setPublishableId(data.updates.publishable_id);
      }
    });
  }
}
