import { CommonModule, DatePipe, NgFor } from '@angular/common';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { FormControl, ReactiveFormsModule } from '@angular/forms';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { RouterModule } from '@angular/router';
import { ButtonComponent } from '@bupple/button';
import { Ideaboard, NavigationService } from '@bupple/core';
import { IdeaboardService, StudioFacade } from '@bupple/studio/data-access';
import { UiIconComponent } from '@bupple/ui/icon';
import { LoadingDirective } from '@bupple/util/directives';
import { UpdatedAtPipe } from '@bupple/util/pipes';
import { SubSink } from 'subsink';
import { DeepReadonly } from 'ts-essentials';

@Component({
  selector: 'bupple-ideaboard-list-card',
  standalone: true,
  imports: [
    MatIconModule,
    RouterModule,
    DatePipe,
    LoadingDirective,
    NgFor,
    UiIconComponent,
    UpdatedAtPipe,
    CommonModule,
    ReactiveFormsModule,
    MatMenuModule,
    ButtonComponent,
  ],
  templateUrl: './ideaboard-list-card.component.html',
  styleUrl: './ideaboard-list-card.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class IdeaboardListCardComponent implements OnInit, OnDestroy {
  constructor(
    protected navigation: NavigationService,
    private ideaboardService: IdeaboardService,
    private studioFacade: StudioFacade,
    private cdr: ChangeDetectorRef,
  ) {}

  @Input({
    required: true,
  })
  ideaboard!: Ideaboard;
  isNameEditing = false;

  ngOnInit(): void {
    this.listenStore();
  }

  listenStore() {
    this.ideaboard && this.handleForm(this.ideaboard);
    this.cdr.markForCheck();
  }

  handleForm(ideaboard: DeepReadonly<Ideaboard>): void {
    this.setNameFormControlValue(ideaboard);
  }

  subsink = new SubSink();
  nameFormControl = new FormControl('');
  setNameFormControlValue(ideaboard: DeepReadonly<Ideaboard>) {
    this.nameFormControl.setValue(ideaboard.name);
  }

  @Output() delete: EventEmitter<MouseEvent> = new EventEmitter();
  onDelete(event: MouseEvent) {
    this.delete.emit(event);
  }

  @ViewChild('nameInput') nameInput!: ElementRef;
  onEditName() {
    this.isNameEditing = true;
    setTimeout(() => {
      this.nameInput.nativeElement.focus();
    }, 0);
  }
  onRename() {
    this.ideaboardService
      .updateIdeaboardName({
        ...this.ideaboard.__dto,
        name: this.nameFormControl.value as string,
      })
      .subscribe(() => {
        this.isNameEditing = false;
        this.cdr.markForCheck();
      });
  }

  ngOnDestroy(): void {
    this.subsink.unsubscribe();
  }
}
