import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';
import { NavigationService } from '@bupple/core';
import { IdeaboardService } from '@bupple/studio/data-access';
import { UiIconComponent } from '@bupple/ui/icon';
import { SubSink } from 'subsink';

@Component({
  selector: 'bupple-new-ideaboard-button',
  standalone: true,
  imports: [CommonModule, UiIconComponent],
  templateUrl: './new-ideaboard-button.component.html',
  styleUrl: './new-ideaboard-button.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NewIdeaboardButtonComponent {
  @Input() className?: string;
  @Input() btnTitle?: string;
  @Input() inProgressCreate?: boolean;
  @Input() iconName?: any;
  @Input() iconType: any = 'outline';
  @Output() subsinkEmit = new EventEmitter();

  subsink = new SubSink();

  constructor(
    private ideaboardService: IdeaboardService,
    private navigation: NavigationService,
    private cdr: ChangeDetectorRef,
  ) {}

  onCreateIdeaboard() {
    this.subsinkEmit.emit(this.subsink.sink);
  }
}
