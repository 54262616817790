import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'timeFormat',
  standalone: true,
})
export class TimeFormatPipe implements PipeTransform {
  transform(seconds: number | null): string {
    if (!seconds) {
      return '00:00';
    }

    const minutes = Math.floor(seconds / 60);
    const sec = Math.floor(seconds % 60);
    const sp = Math.floor((seconds % 1) * 60); // Fractional seconds

    const minutesStr = minutes.toString().padStart(2, '0');
    const secondsStr = sec.toString().padStart(2, '0');
    const spStr = sp.toString().padStart(2, '0');

    return `${minutesStr}:${secondsStr}`;
  }
}
