import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { ProgressBarComponent } from '@bupple/progress-bar';
import {
  MessageType,
  ReceivedMessageDto,
  StudioFacade,
} from '@bupple/studio/data-access';
import { ThumbnailVideoComponent } from '@bupple/thumbnail-video';
import { UiIconComponent } from '@bupple/ui/icon';
import { SubSink } from 'subsink';

@Component({
  selector: 'bupple-received-chat-message-video',
  standalone: true,
  imports: [
    CommonModule,
    UiIconComponent,
    ThumbnailVideoComponent,
    ProgressBarComponent,
  ],
  templateUrl: './received-chat-message-video.component.html',
  styleUrls: ['./received-chat-message-video.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ReceivedChatMessageVideoComponent implements OnInit, OnDestroy {
  @Input() message!: ReceivedMessageDto;

  messageType = MessageType;
  subsink = new SubSink();
  constructor(
    protected studioFacade: StudioFacade,
    private cdr: ChangeDetectorRef,
  ) {}

  ngOnInit(): void {
    this.listenStore();
  }
  videoProgressPercent = 1;
  progressBarview = true;
  listenStore() {
    this.subsink.sink = this.studioFacade.videoProgress$.subscribe(
      (progress) => {
        this.videoProgressPercent = progress ?? 1;
        if (
          this.videoProgressPercent === 100 ||
          this.videoProgressPercent === 1
        ) {
          this.progressBarview = false;
          this.studioFacade.setVideoProgress(1);
        } else {
          this.progressBarview = true;
        }
        this.cdr.markForCheck();
      },
    );
  }

  ngOnDestroy(): void {
    this.subsink.unsubscribe();
  }
}
