<bupple-top-pattern></bupple-top-pattern>

<lib-button
  block
  class="mt-2 md:hidden"
  color="neutral"
  appearance="text"
  [loading]="inProgressCreate"
  *ngIf="ideaboards.length"
  (clickEvent)="onCreateIdeaboard()"
>
  <bupple-icon name="Bold / Essentional, UI / Add Square"></bupple-icon>
  <span class="title-base-bold">New Ideaboard</span>
</lib-button>
<div class="divider line mt-0 opacity-50 md:hidden"></div>
<div
  class="px-page mb-4 flex justify-between items-center md:pt-4"
  *ngIf="ideaboards.length"
>
  <h2 class="title-l-bold grow">Ideaboards</h2>
  <!-- Sort Part -->
  <!-- <bupple-icon name="Outline / List / Sort From Top To Bottom" className="w-6 h-6"></bupple-icon> -->
</div>
<div class="pb-32 pt-4 md:pb-0 md:pt-0">
  <div
    class="my-5 text-center flex flex-col gap-10 h-full justify-center md:max-w-[27.75rem] md:mx-auto md:h-[calc(100vh_-_10rem)] md:my-0 md:gap-y-14"
    *ngIf="!ideaboards.length && !inProgress"
  >
    <bupple-no-data-found
      title="Use AI to Create Engaging Content"
      titleSub="Explore the most sophisticated tools."
    ></bupple-no-data-found>
    <bupple-new-ideaboard-button
      [inProgressCreate]="inProgressCreate"
      btnTitle="Create Ideaboard"
      class="px-8"
      iconName="Outline / Arrows / Arrow Right"
      (subsinkEmit)="onCreateIdeaboard()"
    ></bupple-new-ideaboard-button>
    <bupple-loading *ngIf="inProgress"></bupple-loading>
  </div>

  <div
    class="flex flex-wrap gap-4 pb-2 md:pb-10 px-page"
    *ngIf="ideaboards.length"
  >
    <ng-container *ngFor="let ideaboard of ideaboards">
      <bupple-ideaboard-card
        [ideaboard]="ideaboard"
        (delete)="onDeleteIdeaboard($event, ideaboard)"
        class="px-page w-full md:w-[22.5rem] bg-base-500 rounded-2xl p-4"
      ></bupple-ideaboard-card>
    </ng-container>
  </div>
  <div class="text-center pb-7 max-w-32 mx-auto" *ngIf="hasNextPage">
    <lib-button
      class="btn-block"
      size="sm"
      color="neutral"
      appearance="outline"
      (clickEvent)="getIdeaboards(currentPage + 1)"
      [loading]="inProgress"
    >
      Load More
    </lib-button>
  </div>
</div>
<div class="absolute bottom-0 left-0 w-full md:hidden">
  <lib-ui-bottom-menu></lib-ui-bottom-menu>
</div>
