import { inject, Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { flatten } from 'lodash-es';
import { filter, map } from 'rxjs';
import {
  SubtitleSegment,
  SubtitleWord,
} from '../interfaces/subtitle.interface';
import {
  TrackAudioItem,
  trackItem,
  VideoAsset,
} from '../interfaces/timeline-track-item.interface';
import { Timeline } from '../interfaces/timeline.interface';
import { SubtitleService } from '../services/subtitle.service';
import * as VideoEditorActions from './video-editor.actions';
import * as VideoEditorSelectors from './video-editor.selectors';

@Injectable()
export class VideoEditorFacade {
  constructor(private subtitleService: SubtitleService) {}
  private readonly store = inject(Store);

  activeTrackItem$ = this.store.pipe(
    select(VideoEditorSelectors.activeTrackItem),
  );
  activeTrackItemAsset$ = this.store.pipe(
    select(VideoEditorSelectors.activeTrackItemAsset),
  );
  nextClip$ = this.store.pipe(select(VideoEditorSelectors.nextClip));
  isPlaying$ = this.store.pipe(select(VideoEditorSelectors.isPlaying));
  clips$ = this.store.pipe(select(VideoEditorSelectors.clips));
  totalTime$ = this.store.pipe(select(VideoEditorSelectors.totalTime));
  totalTimeSpent$ = this.store.pipe(
    select(VideoEditorSelectors.totalTimeSpent),
  );
  subtitleLines$ = this.store.pipe(select(VideoEditorSelectors.segments)).pipe(
    filter((segments: SubtitleSegment[]) => Boolean(segments.length)),
    map((segments) => {
      const lines = segments.map((s) =>
        this.subtitleService.getSegmentLines(s, 15),
      );
      const flatenLines = flatten(lines);
      return flatenLines;
    }),
  );
  assetCandidates$ = this.store.pipe(
    select(VideoEditorSelectors.assetCandidates),
  );
  audio$ = this.store.pipe(select(VideoEditorSelectors.audio));
  canPlayTimeline$ = this.store.pipe(
    select(VideoEditorSelectors.canPlayTimeline),
  );
  stopAudio$ = this.store.pipe(select(VideoEditorSelectors.stopAudio));

  setTimeline(timeline: Timeline) {
    this.store.dispatch(VideoEditorActions.setTimeline({ timeline }));
  }

  setVideoClips(item: trackItem[]) {
    this.store.dispatch(
      VideoEditorActions.setVideoClips({
        clips: item,
      }),
    );
  }

  setSubtitleSegments(segments: SubtitleSegment[]) {
    this.store.dispatch(
      VideoEditorActions.setSubtitleSegments({
        segments,
      }),
    );
  }

  setActiveClip(id: string) {
    this.store.dispatch(VideoEditorActions.setActiveClipId({ id }));
  }

  setFirstClipActive() {
    this.store.dispatch(VideoEditorActions.setFirstClipActive());
  }

  setIsPlaying(status: boolean) {
    this.store.dispatch(VideoEditorActions.setIsPlaying({ status }));
  }

  updateTotalTimeSpent(time: number) {
    this.store.dispatch(VideoEditorActions.updateTotalTimeSpent({ time }));
  }

  resetTotalTimeSpent() {
    this.store.dispatch(VideoEditorActions.resetTotalTimeSpent());
  }

  updateSubtitleSegment(
    segmentId: string,
    segmentText: string,
    words: SubtitleWord[],
  ) {
    this.store.dispatch(
      VideoEditorActions.updateSegmentText({
        segmentId,
        segmentText,
        words,
      }),
    );
  }

  replaceActiveTrackItemAsset(asset: VideoAsset) {
    this.store.dispatch(
      VideoEditorActions.replaceActiveTrackItemAsset({ asset }),
    );
  }

  setAudio(audio: TrackAudioItem) {
    this.store.dispatch(VideoEditorActions.setAudio({ audio }));
  }

  setVideoIsReady() {
    this.store.dispatch(VideoEditorActions.setVideoIsReady());
  }

  setVideoIsPlaying(status: boolean) {
    this.store.dispatch(VideoEditorActions.setVideoIsPlaying({ status }));
  }

  setAudioIsReady() {
    this.store.dispatch(VideoEditorActions.setAudioIsReady());
  }
}
