<h4>Tell us more:</h4>
<div class="pt-4 pb-4 flex flex-wrap gap-2">
  <lib-button
    size="sm"
    color="neutral"
    appearance="outline"
    *ngFor="let message of preparedMessages"
    (clickEvent)="onSelectAFeedback(message.title)"
  >
    {{ message.title }}
  </lib-button>
</div>
<div>
  <textarea
    rows="5"
    [(ngModel)]="feedbackContent"
    placeholder="(Optional) Feel free to add specific details"
    class="textarea textarea-bordered w-full block my-4"
  ></textarea>
</div>
<div class="flex gap-2 mt-8">
  <div class="grow">
    <lib-button
      block
      color="neutral"
      appearance="outline"
      (clickEvent)="bottomSheetRef.dismiss(false)"
    >
      Cancel
    </lib-button>
  </div>
  <div class="grow">
    <lib-button
      block
      (clickEvent)="onClickSend()"
      [disabled]="!feedbackContent || inProgress"
    >
      Send
    </lib-button>
  </div>
</div>
