<bupple-top-pattern></bupple-top-pattern>
<bupple-auth-header></bupple-auth-header>

<div
  class="relative z-10 max-w-[390px] w-full mx-auto md:flex md:items-center md:justify-center md:h-[calc(100vh_-_4.5rem)]">
  <form [formGroup]="form"
    class="py-10 px-page md:py-7">
    <h2 class="mb-10 font-bold md:title-xl">Create Your Account</h2>

    <p class="font-bold mb-2 pl-2">Name</p>
    <input formControlName="name"
      id="name"
      type="text"
      class="input mb-4 bdr placeholder-base-content/50"
      placeholder="John Doe"
      autofocus />

    @if(formIsValid('name')){
    <!-- email validation error note  -->
    <p class="text-error sub-text-l pb-2 w-full text-end">Please enter name field</p>
    }

    <p class="font-bold mb-2 pl-2">Email</p>
    <label class="input input-bordered flex items-center gap-2 mb-4">
      <bupple-icon name="Outline / Messages, Conversation  /  Letter"></bupple-icon>
      <input formControlName="email"
        type="text"
        class="grow"
        placeholder="example@gmail.com" />
    </label>

    @if(formIsValid('email')){
    <!-- email validation error note  -->
    <p class="text-error sub-text-l pb-2 w-full text-end">Please enter a valid email address</p>
    }

    <p class="font-bold mb-2 pl-2">Password</p>
    <label class="input input-bordered flex items-center gap-2">
      <bupple-icon name="Outline / Security / Password Minimalistic Input"></bupple-icon>
      <input formControlName="password"
        class="grow"
        [type]="showPassword ? 'text' : 'password'"
        placeholder="*****************" />
      <bupple-icon [name]="
          showPassword
            ? 'Bold / Security / Eye'
            : 'Bold / Security / Eye Closed'
        "
        (click)="showPassword = !showPassword"></bupple-icon>
    </label>

    @if(formIsValid('password')){
    <!-- password validation error note -->
    <p class="text-error sub-text-l pb-2 w-full text-end mt-4">Password must be at least 6 characters
      long</p>
    }


    @if((form.invalid && form.touched)){
    <p class="text-error sub-text-l pb-2 w-full mt-4">Please fill out all required fields</p>
    }


    <lib-button class="mt-6"
      block
      (clickEvent)="onSubmitForm()"
      [disabled]="inProgress">
      Sign Up
      <bupple-icon color="base-content"
        size="lg"
        name="Outline / Arrows / Arrow Right"></bupple-icon>
    </lib-button>

    <div class="divider">OR</div>

    <lib-button block
      color="neutral"
      appearance="tonal"
      (clickEvent)="onContinueWithGoogle()">
      <bupple-icon name="GoogleLogo"></bupple-icon>
      Sign up with Google
    </lib-button>
    <lib-button class="mt-4"
      block
      color="neutral"
      appearance="tonal"
      (clickEvent)="onContinueWithFacebook()">
      <bupple-icon name="FacebookLogo"></bupple-icon>
      Sign up with Facebook
    </lib-button>
    <p class="text-center text-base-content-secondary mt-4">
      Already have an account?
      <a (click)="navigation.goSignIn()"
        class="text-secondary cursor-pointer">Sign in</a>
    </p>
  </form>
  <bupple-ui-pattern-bottom></bupple-ui-pattern-bottom>
</div>