import { Injectable } from '@angular/core';
import { HttpClientAdaptor } from '@bupple/util/adaptors';
import { Observable } from 'rxjs';
import { LinkPreview } from '../interfaces/link-preview.interface';

@Injectable()
export class LinkPreviewService {
  constructor(private http: HttpClientAdaptor) {}

  key = 'f052cfef56a6de57331f06fac4f5cdc9';
  get(url: string): Observable<LinkPreview> {
    return this.http.get<LinkPreview>(
      'http://api.linkpreview.net/?key=' + this.key + '&q=' + url,
    );
  }

  isValidLink(link: string) {
    return true;
  }
}
