<div class="flex flex-col w-full border-t border-base-content/12">
  <div
    class="timeline-tools flex-none h-12 border-b border-t border-base-content/12 items-center flex justify-center"
  >
    <lib-button
      circle
      size="xs"
      color="neutral"
      (clickEvent)="onClickTogglePlay()"
      [disabled]="!canPlayTimeline"
    >
      <bupple-icon
        [name]="
          isPlaying
            ? 'Outline / Video, Audio, Sound / Pause'
            : 'Outline / Video, Audio, Sound / Play'
        "
        size="xxs"
        [color]="canPlayTimeline ? 'base-100' : 'base-content'"
      ></bupple-icon>
    </lib-button>
    <div class="text-xs flex justify-center ml-4 gap-2 w-[90px]">
      <span class="">
        {{ videoEditorFacade.totalTimeSpent$ | async | timeFormat }}
      </span>
      <span class="inline-block">|</span>
      <span class="text-end text-base-content-tertiary">
        {{ videoEditorFacade.totalTime$ | async | timeFormat }}
      </span>
    </div>
  </div>
  <div class="timeline-panel grow p-3 min-h-[84px]">
    <div class="w-full overflow-x-auto">
      <div
        cdkDropList
        cdkDropListOrientation="horizontal"
        class="video-list"
        (cdkDropListDropped)="drop($event)"
      >
        @for (trackItem of trackItems; track trackItem.id) {
          <div
            cdkDrag
            class="video-item"
            [class.video-item--active]="
              activeTrackItem && trackItem.id === activeTrackItem.id
            "
            (click)="onClickClip(trackItem)"
          >
            <img
              [src]="trackItem.asset.thumbnail_url"
              alt=""
              class="max-w-full max-h-full"
            />
          </div>
        }
      </div>
    </div>
  </div>
</div>
