import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PlanDTO } from '../dtos/plans.dtos';
import { UiIconComponent } from '@bupple/ui/icon';

@Component({
  selector: 'lib-plan-features',
  standalone: true,
  imports: [CommonModule, UiIconComponent],
  templateUrl: './plan-features.component.html',
  styleUrl: './plan-features.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PlanFeaturesComponent {
  @Input({ required: true }) plan: PlanDTO = {} as PlanDTO;
  @Input() showMore?: boolean;
}
