<div class="h-screen flex">
  <aside
    class="flex-none h-screen w-[250px] bg-base-500 overflow-x-hidden overflow-y-auto p-4"
  >
    <lib-button
      [loading]="inProgressAdd"
      color="neutral"
      size="sm"
      block
      (clickEvent)="onClickAddScene()"
      >New Scene</lib-button
    >
    <div class="flex flex-col gap-4 pt-4">
      <lib-scene-card
        *ngFor="let scene of scenes"
        [scene]="scene"
        (clickDelete)="onClickDeleteScene(scene)"
        [loadingDelete]="scene.inProgress"
        (selectScene)="onSelectScene(scene)"
      ></lib-scene-card>
      <div class="text-center pt-4">
        <bupple-loading *ngIf="inProgressGetAll"></bupple-loading>
        <span
          *ngIf="!scenes.length && !inProgressGetAll"
          class="text-base-content-tertiary text-sm"
        >
          No scenes found
        </span>
      </div>
    </div>
  </aside>
  <section class="grow">
    <div #cesdk_container style="width: 100%; height: 100vh"></div>
  </section>
</div>
