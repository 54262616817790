<div
  class="h-full flex flex-col items-center justify-center px-page text-center"
>
  <h1 class="text-[70px] font-extrabold">Ooops!</h1>

  <h3 class="mt-10">Something went wrong!</h3>
  <p class="mt-4 mb-10 text-sm text-base-content/50">
    Don't wory, our team is here to help.
  </p>

  <div class="flex gap-4">
    <a href="https://bupple.io/contact-us" target="_blank">
      <lib-button appearance="outline">Contact Us</lib-button>
    </a>
    <lib-button (clickEvent)="navigation.goHome()"> Go Home </lib-button>
  </div>
</div>
