import { createAction, props } from '@ngrx/store';
import {
  SubtitleSegment,
  SubtitleWord,
} from '../interfaces/subtitle.interface';
import {
  TrackAudioItem,
  trackItem,
  VideoAsset,
} from '../interfaces/timeline-track-item.interface';
import { Timeline } from '../interfaces/timeline.interface';

export const initVideoEditor = createAction('[VideoEditor Page] Init');

export const setTimeline = createAction(
  '[Video Editor] Set Timeline',
  props<{ timeline: Timeline }>(),
);

export const setVideoClips = createAction(
  '[Video Editor] Set Video Clips',
  props<{ clips: trackItem[] }>(),
);

export const setSubtitleSegments = createAction(
  '[Video Editor] Set Subtitle Segments',
  props<{ segments: SubtitleSegment[] }>(),
);

export const setActiveClipId = createAction(
  '[Video Editor] Set Active Clip Id',
  props<{ id: string }>(),
);

export const setFirstClipActive = createAction(
  '[Video Editor] Set First Clip Active',
);

export const setIsPlaying = createAction(
  '[Video Editor] Set Is Playing',
  props<{ status: boolean }>(),
);

export const updateTotalTimeSpent = createAction(
  '[Video Editor] Update Total Time Spent',
  props<{ time: number }>(),
);

export const resetTotalTimeSpent = createAction(
  '[Video Editor] Reset Total Time Spent',
);

export const updateSegmentText = createAction(
  '[Video Editor] Update Segment Tesxt',
  props<{ segmentId: string; segmentText: string; words: SubtitleWord[] }>(),
);

export const replaceActiveTrackItemAsset = createAction(
  '[Video Editor] Replace Active Track Item Asset',
  props<{ asset: VideoAsset }>(),
);

export const setAudio = createAction(
  '[Video Editor] Set Audio',
  props<{ audio: TrackAudioItem }>(),
);

export const setVideoIsReady = createAction(
  '[Video Editor] Set Video Is Ready',
);

export const setAudioIsReady = createAction(
  '[Video Editor] Set Audio Is Ready',
);

export const setVideoIsPlaying = createAction(
  '[Video Editor] Set Video Is Playing',
  props<{ status: boolean }>(),
);
