import { CommonModule, NgIf } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  HostBinding,
  Input,
} from '@angular/core';
import { KebabCasePipe } from '@bupple/util/pipes';
import { SvgIconComponent } from 'angular-svg-icon';
import { COLORS_CLASS_MAP, SIZE_CLASS_MAP } from '../ui-icon.constants';
type colorType =
  | 'base-content'
  | 'base-content-secondary'
  | 'base-content-tertiary'
  | 'primary'
  | 'error'
  | 'warning'
  | 'success'
  | 'base-100'
  | 'secondary'
  | '';

@Component({
  selector: 'bupple-icon',
  standalone: true,
  imports: [NgIf, SvgIconComponent, CommonModule, KebabCasePipe],
  templateUrl: './ui-icon.component.html',
  styleUrls: ['./ui-icon.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UiIconComponent {
  @HostBinding() class = 'inline-block align-middle';

  protected _name = '';
  @Input({ required: true }) set name(value: string) {
    this._name = value;
  }

  private _color: colorType = '';
  @Input() set color(value: colorType) {
    this._color = value ? COLORS_CLASS_MAP.get(value) : this._color;
  }

  private _size = SIZE_CLASS_MAP.get('md');
  @Input() set size(value: string) {
    this._size = value ? SIZE_CLASS_MAP.get(value) : this._size;
  }

  private _className = '';
  @Input() set className(value: string) {
    this._className = value || this._className;
  }

  protected get _class(): string {
    return `${this._color} ${this._size} ${this._className}`;
  }
}
