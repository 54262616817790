import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { PublishableApi } from '../apis/publishable.api';
import { MakePublishableResponseDto } from '../dtos/post.dto';

@Injectable()
export class PublishableService {
  constructor(private publishableApi: PublishableApi) {}

  makeFromChat(messageId: string): Observable<MakePublishableResponseDto> {
    return this.publishableApi.makeFromChat(messageId);
  }

  updatePostCaption(postId: string, caption: string): Observable<any> {
    return this.publishableApi.updatePostCaption(postId, caption);
  }
}
