import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LastStatusDto } from '@bupple/core';
import { UiIconComponent } from '@bupple/ui/icon';

@Component({
  selector: 'bupple-ideaboard-last-status',
  standalone: true,
  imports: [CommonModule, UiIconComponent],
  templateUrl: './ideaboard-last-status.component.html',
  styleUrl: './ideaboard-last-status.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class IdeaboardLastStatusComponent {
  @Input() ideaboardLastStatus!: LastStatusDto;
}
