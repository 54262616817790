import { Route } from '@angular/router';
import { PARAMS } from '@bupple/core';
import { provideState } from '@ngrx/store';
import { VideoEditorFacade } from './+state/video-editor.facade';
import * as fromVideoEditor from './+state/video-editor.reducer';
import { FeatureVideoEditorComponent } from './feature-video-editor/feature-video-editor.component';

export const featureVideoEditorRoutes: Route[] = [
  {
    path: `:${PARAMS.ideaboardId}/video-editor`,
    providers: [
      VideoEditorFacade,
      provideState(
        fromVideoEditor.VIDEO_EDITOR_FEATURE_KEY,
        fromVideoEditor.videoEditorReducer,
      ),
    ],
    children: [
      {
        path: ':video-id',
        component: FeatureVideoEditorComponent,
      },
    ],
  },
];
