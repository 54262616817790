<div class="ideaboard-last-status pb-1">
  <div
    class="flex gap-x-1 w-fit py-1 px-2 sub-text-l rounded-xl items-center border border-solid border-base-content/8 bg-base-content-secondary/16">
    <!-- [ngClass]="{
      secondary: ideaboardLastStatus.color === '#4143EE',
      accent: ideaboardLastStatus.color === '#43EEA6',
      red: ideaboardLastStatus.color === '#EE204A',
    }" -->
    <bupple-icon [name]="ideaboardLastStatus.icon" color="base-content" size="sm"></bupple-icon>
    <span class="text-base-content-secondary">{{
      ideaboardLastStatus.name
      }}</span>
  </div>
</div>