<div class="text-center">
  <div class="w-full -mt-3 pb-6 pt-6">
    <img src="assets/images/credit.png"
      alt=""
      class="mx-auto block h-[155px]" />
  </div>
  <div class="">

    <h2 class="font-bold mb-3">Bupple Pro</h2>
    <p class="text-base-content-secondary text-sm mb-8">
      You have reached your limit. Want access to all Bupple features?
    </p>

    <lib-button block
      class="mb-2"
      (clickEvent)="goToPremium()"
      size="lg">
      See Plans
    </lib-button>
    <lib-button block
      color="neutral"
      appearance="outline"
      (clickEvent)="bottomSheetRef.dismiss(false)"
      size="lg">
      Cancel
    </lib-button>
  </div>
</div>