import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { ButtonComponent } from '@bupple/button';

@Component({
  selector: 'lib-add-scene',
  standalone: true,
  imports: [CommonModule, ButtonComponent, FormsModule],
  templateUrl: './add-scene.component.html',
  styleUrl: './add-scene.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AddSceneComponent {
  constructor(protected dialog: MatDialogRef<AddSceneComponent>) {}

  name = '';
}
