import { ElementRef, Injectable } from '@angular/core';
import CreativeEditorSDK, { Configuration } from '@cesdk/cesdk-js';
import { CREATIVE_CONFIG } from '../constants/creative-config.constants';

@Injectable({
  providedIn: 'root',
})
export class CreativeEditorService {
  private _editor: CreativeEditorSDK | undefined = undefined;

  async getEditor(
    containerRef: ElementRef,
    config?: Configuration,
  ): Promise<CreativeEditorSDK> {
    if (!this._editor) {
      this._editor = await this.initializeEditor(containerRef, config);
    }

    return this._editor;
  }

  private async initializeEditor(
    containerRef: ElementRef,
    config?: Configuration,
  ) {
    const instance = await CreativeEditorSDK.create(
      containerRef.nativeElement,
      config || CREATIVE_CONFIG,
    );

    instance.addDefaultAssetSources();
    instance.addDemoAssetSources({ sceneMode: 'Design' });
    return instance;
  }
}
