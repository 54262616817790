import { Injectable, TrackByFunction } from '@angular/core';
import { HasId, HasMedia } from '@bupple/interfaces';

@Injectable()
export class TrackByService {
  id: TrackByFunction<any> = function (index: number, item: HasId): string {
    return item?._id;
  };

  media: TrackByFunction<any> = function (
    index: number,
    item: HasMedia,
  ): string {
    return item?.media;
  };
}
