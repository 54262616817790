import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component } from '@angular/core';
import { MatBottomSheetRef } from '@angular/material/bottom-sheet';
import { ButtonComponent } from '@bupple/button';

@Component({
  selector: 'bupple-sign-out-confirmation',
  standalone: true,
  imports: [CommonModule, ButtonComponent],
  templateUrl: './sign-out-confirmation.component.html',
  styleUrl: './sign-out-confirmation.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SignoutConfirmationComponent {
  constructor(
    protected bottomSheetRef: MatBottomSheetRef<SignoutConfirmationComponent>,
  ) {}
}
