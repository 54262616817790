import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component } from '@angular/core';
import { MatBottomSheetRef } from '@angular/material/bottom-sheet';
import { ButtonComponent } from '@bupple/button';
import { UiIconComponent } from '@bupple/ui/icon';
import { NavigationService } from '../../services/navigation.service';

@Component({
  selector: 'continue-premium',
  standalone: true,
  imports: [CommonModule, ButtonComponent, UiIconComponent],
  templateUrl: './continue-premium.component.html',
  styleUrl: './continue-premium.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ContinuePremiumComponent {
  constructor(
    protected bottomSheetRef: MatBottomSheetRef<ContinuePremiumComponent>,
    private navigate: NavigationService,
  ) {}

  goToPremium() {
    this.navigate.goPlans();
    this.bottomSheetRef.dismiss(true);
  }
}
