import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { FormControl, ReactiveFormsModule } from '@angular/forms';
import { ButtonComponent } from '@bupple/button';
import { Ideaboard, NavigationService } from '@bupple/core';
import {
  IdeaboardApi,
  IdeaboardService,
  StudioFacade,
} from '@bupple/studio/data-access';
import { UiIconComponent } from '@bupple/ui/icon';
import { filter, switchMap } from 'rxjs';
import { SubSink } from 'subsink';
import { DeepReadonly } from 'ts-essentials';

@Component({
  selector: 'bupple-chat-topbar',
  standalone: true,
  imports: [
    CommonModule,
    UiIconComponent,
    ReactiveFormsModule,
    ButtonComponent,
  ],
  providers: [StudioFacade, IdeaboardService, NavigationService, IdeaboardApi],
  templateUrl: './chat-topbar.component.html',
  styleUrl: './chat-topbar.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ChatTopbarComponent implements OnInit, OnDestroy {
  constructor(
    private studioFacade: StudioFacade,
    private ideaboardService: IdeaboardService,
    protected navigation: NavigationService,
    private cdr: ChangeDetectorRef,
  ) {}

  ngOnInit(): void {
    this.listenStore();
  }

  listenStore() {
    this.subsink.sink = this.studioFacade.activeIdeaboard$
      .pipe(filter((i) => !!i))
      .subscribe((ideaboard) => {
        !this.ideaboard && this.handleForm(ideaboard);
        this.ideaboard = ideaboard;
        this.cdr.markForCheck();
      });
  }

  handleForm(ideaboard: DeepReadonly<Ideaboard>): void {
    this.setNameFormControlValue(ideaboard);
    this.observeOnNameFormControl();
  }

  subsink = new SubSink();
  ideaboard!: Ideaboard;
  nameFormControl = new FormControl('');
  setNameFormControlValue(ideaboard: DeepReadonly<Ideaboard>) {
    this.nameFormControl.setValue(ideaboard.name);
  }

  observeOnNameFormControl() {
    this.subsink.sink = this.nameFormControl.valueChanges
      .pipe(
        filter((value) => !!value),
        switchMap((value) =>
          this.ideaboardService.updateIdeaboardName({
            ...this.ideaboard.__dto,
            name: value as string,
          }),
        ),
      )
      .subscribe();
  }

  ngOnDestroy(): void {
    this.subsink.unsubscribe();
  }
}
