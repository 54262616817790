import {
  HTTP_INTERCEPTORS,
  provideHttpClient,
  withInterceptorsFromDi,
} from '@angular/common/http';
import {
  APP_INITIALIZER,
  ApplicationConfig,
  ErrorHandler,
  importProvidersFrom,
  isDevMode,
} from '@angular/core';
import { MAT_BOTTOM_SHEET_DEFAULT_OPTIONS } from '@angular/material/bottom-sheet';
import { MAT_FORM_FIELD_DEFAULT_OPTIONS } from '@angular/material/form-field';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import {
  provideRouter,
  Router,
  withComponentInputBinding,
  withEnabledBlockingInitialNavigation,
} from '@angular/router';
import { provideServiceWorker } from '@angular/service-worker';
import {
  AuthService,
  CoreFacade,
  CoreFeature,
  HttpErrorInterceptor,
  NavigationService,
  StorageService,
  TokenInterceptor,
} from '@bupple/core';
import { provideHotToastConfig } from '@ngneat/hot-toast';
import { provideEffects } from '@ngrx/effects';
import { provideStore } from '@ngrx/store';
import { provideStoreDevtools } from '@ngrx/store-devtools';
import * as Sentry from '@sentry/angular';
import { provideAngularSvgIcon } from 'angular-svg-icon';
import { AngularSvgIconPreloaderModule } from 'angular-svg-icon-preloader';
import { environment } from '../environments/environment';
import { appRoutes } from './app.routes';

function initializeAppFactory(
  storageService: StorageService,
  authService: AuthService,
  coreFacade: CoreFacade,
): () => void {
  return async () => {
    if (storageService.token) {
      try {
        await authService.me();
      } catch (error) {
        await authService.logoutOnClient();
      } finally {
        coreFacade.setInitialized();
      }
    } else {
      coreFacade.setInitialized();
    }
  };
}

export const appConfig: ApplicationConfig = {
  providers: [
    importProvidersFrom(BrowserAnimationsModule),
    provideHotToastConfig({ duration: 5000, position: 'bottom-right' }),
    provideHttpClient(withInterceptorsFromDi()),
    importProvidersFrom(
      AngularSvgIconPreloaderModule.forRoot({
        configUrl: './assets/icons/icons.json',
      }),
    ),
    provideRouter(
      appRoutes,
      withEnabledBlockingInitialNavigation(),
      withComponentInputBinding(),
    ),
    provideEffects(),
    provideStore({
      core: CoreFeature.coreReducer,
    }),
    provideStoreDevtools({
      maxAge: 25, // Retains last 25 states
      logOnly: !isDevMode(), // Restrict extension to log-only mode
      autoPause: true, // Pauses recording actions and state changes when the extension window is not open
      trace: false, //  If set to true, will include stack trace for every dispatched action, so you can see it in trace tab jumping directly to that part of code
      traceLimit: 75, // maximum stack trace frames to be stored (in case trace option was provided as true)
      connectInZone: true, // If set to true, the connection is established within the Angular zone
    }),
    provideAngularSvgIcon(),
    {
      provide: ErrorHandler,
      useValue: Sentry.createErrorHandler({
        showDialog: false,
      }),
    },
    {
      provide: Sentry.TraceService,
      deps: [Router],
    },
    {
      provide: APP_INITIALIZER,
      useFactory: () => () => ({}),
      deps: [Sentry.TraceService],
      multi: true,
    },
    {
      provide: MAT_BOTTOM_SHEET_DEFAULT_OPTIONS,
      useValue: {
        hasBackdrop: true,
        backdropClass: 'bupple-backdrop',
      },
    },
    {
      provide: MAT_FORM_FIELD_DEFAULT_OPTIONS,
      useValue: { appearance: 'outline' },
    },
    {
      provide: APP_INITIALIZER,
      useFactory: initializeAppFactory,
      deps: [StorageService, AuthService, CoreFacade, NavigationService],
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpErrorInterceptor,
      multi: true,
    },
    {
      provide: 'environment',
      useValue: environment,
    },
    provideServiceWorker('ngsw-worker.js', {
      enabled: !isDevMode(),
      registrationStrategy: 'registerWhenStable:30000',
    }),
  ],
};
