import { Route } from '@angular/router';
import { AuthorizedGuard, TITLE } from '@bupple/core';

export const featureConnectAccountsRoutes: Route[] = [
  {
    path: '',
    canActivate: [AuthorizedGuard],
    title: TITLE.connectAccounts,
    pathMatch: 'full',
    loadComponent: () =>
      import('./connect-accounts/connect-accounts.component').then(
        (c) => c.ConnectAccountsComponent,
      ),
  },
  {
    path: 'onboarding',
    canActivate: [AuthorizedGuard],
    pathMatch: 'full',
    loadComponent: () =>
      import(
        './connect-accounts-onboarding/connect-accounts-onboarding.component'
      ).then((c) => c.ConnectAccountsOnboardingComponent),
  },
];
