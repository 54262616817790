import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  HostBinding,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { FormsModule } from '@angular/forms';
import {
  MatBottomSheet,
  MatBottomSheetModule,
} from '@angular/material/bottom-sheet';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { ActivatedRoute } from '@angular/router';
import { ButtonComponent } from '@bupple/button';
import { MessageAction, ModalResult, NavigationService } from '@bupple/core';
import { MessageActionSlug } from '@bupple/enums';
import {
  IdeaboardChatService,
  MessageType,
  StudioFacade,
} from '@bupple/studio/data-access';
import { UiChatActionItemComponent } from '@bupple/ui/chat-action-item';
import { UiIconComponent } from '@bupple/ui/icon';
import { cloneDeep } from 'lodash-es';
import { combineLatest, filter } from 'rxjs';
import { SubSink } from 'subsink';
import { AddImageComponent } from '../add-image/add-image.component';
import { AddLinkComponent } from '../add-link/add-link.component';
import { AddVideoComponent } from '../add-video/add-video.component';
import { Regex } from '../enums/regex.enum';
@Component({
  selector: 'bupple-chat-inputbar',
  standalone: true,
  imports: [
    CommonModule,
    UiChatActionItemComponent,
    MatMenuModule,
    FormsModule,
    MatBottomSheetModule,
    UiIconComponent,
    FormsModule,
    MatIconModule,
    ButtonComponent,
  ],
  providers: [StudioFacade, IdeaboardChatService, NavigationService],
  templateUrl: './chat-inputbar.component.html',
  styleUrl: './chat-inputbar.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ChatInputbarComponent implements OnInit, OnDestroy {
  constructor(
    private studioFacade: StudioFacade,
    private ideaboardChatService: IdeaboardChatService,
    private navigation: NavigationService,
    private route: ActivatedRoute,
    private bottomSheet: MatBottomSheet,
    private cdr: ChangeDetectorRef,
  ) {}

  @HostBinding() class = 'block w-full';
  @Output() focusInput: EventEmitter<FocusEvent> = new EventEmitter();
  @Output() changeMessage: EventEmitter<string | null> = new EventEmitter();
  @Output() enterMessage: EventEmitter<string | null> = new EventEmitter();

  subsink = new SubSink();
  ngOnInit(): void {
    this.listenStore();
    this.listenRoute();
    this.getActions();
  }

  ideaboardId!: string;
  isRecievingMessage = false;
  listenStore() {
    this.subsink.sink = this.studioFacade.isRecievingMessage$.subscribe(
      (is) => {
        this.isRecievingMessage = is;
        this.cdr.markForCheck();
      },
    );

    this.subsink.sink = combineLatest([
      this.studioFacade.activeIdeaboardChat$,
      this.studioFacade.homeInputChatMessage$,
    ])
      .pipe(filter(([chat, message]) => !!chat && !!message))
      .subscribe(() => {
        this.listenHomeInputChatMessage();
      });
  }

  listenHomeInputChatMessage() {
    this.subsink.sink = this.studioFacade.homeInputChatMessage$
      .pipe(filter((message) => !!message))
      .subscribe((message) => {
        this.studioFacade.setHomeInputChatMessage(null);
        this.studioFacade.setChatMessage(message);
      });
  }

  private actionOnRoute: MessageActionSlug | undefined;
  selectedAction: MessageAction | null = null;
  listenRoute() {
    this.route.params
      .pipe(filter((params) => !!params['id']))
      .subscribe(({ id }) => {
        const switchedToANewIdeaboard =
          this.ideaboardId && this.ideaboardId !== id;
        this.ideaboardId = id;

        if (switchedToANewIdeaboard) {
          this.resetChatInput();
          this.cdr.markForCheck();
        }
      });

    this.route.queryParams.subscribe((params) => {
      this.actionOnRoute = params['action'];

      if (this.actionOnRoute && this.chatActions.length) {
        this.setSelectedAction(this.actionOnRoute);
        this.cdr.markForCheck();
      }
    });
  }

  findAction(actionSlug: MessageActionSlug): MessageAction {
    return this.chatActions.find((a) => a.slug === actionSlug) as MessageAction;
  }

  chatActions: MessageAction[] = [];
  getActions(messageType?: MessageType) {
    this.subsink.sink = this.ideaboardChatService
      .actions(messageType)
      .subscribe((result) => {
        this.chatActions = result.general;

        this.actionOnRoute && this.setSelectedAction(this.actionOnRoute);
        this.cdr.markForCheck();
      });
  }

  setSelectedAction(actionOnRoute: MessageActionSlug) {
    this.selectedAction = this.findAction(actionOnRoute);
    this.studioFacade.setSelectedAction(this.selectedAction);
    this.setSuitableInputHeightForActions();
    this.chatInput.nativeElement.focus();
  }

  onFocusInput(event: FocusEvent) {
    this.showActions = false;
    this.cdr.markForCheck();
    this.focusInput.emit(event);
  }

  onEnter(event?: Event) {
    event?.preventDefault();

    const isLink = Regex.url.test(this.enteredText);
    !isLink &&
      this.enteredText &&
      !this.isRecievingMessage &&
      this.sendTextMessage();
  }

  @ViewChild('chatInput') chatInput: ElementRef = new ElementRef(null);
  sendTextMessage() {
    this.studioFacade.setChatMessage(
      cloneDeep({
        type: MessageType.text,
        content: (this.selectedAction?.prompt || '') + this.enteredText,
        func: this.selectedAction?.slug as string,
      }),
    );

    this.resetChatInput();
  }

  resetChatInput() {
    this.resetChatText();
    this.resetInputHeight();

    this.selectedAction && this.resetChatAction();
  }

  resetChatText() {
    this.enteredText = '';
  }

  onClickAction(action: MessageAction) {
    this.showActions = false;
    this.cdr.markForCheck();
    this.navigation.goIdeaboard(this.ideaboardId, {
      queryParams: {
        action: action.slug,
      },
    });
  }

  suitableInputHieghtForAction = 100;
  setSuitableInputHeightForActions() {
    if (window.innerWidth > 768) {
      this.suitableInputHieghtForAction = 80;
    }
    this.chatInput.nativeElement.style.height =
      this.suitableInputHieghtForAction + 'px';
  }

  onCancelAction() {
    this.resetChatAction();
    this.studioFacade.resetSelectedAction();
    this.resetInputHeight();
  }

  resetChatAction() {
    this.selectedAction = null;
    this.resetChatActionOnRoute();
  }

  resetChatActionOnRoute() {
    this.navigation.goIdeaboard(this.ideaboardId);
  }

  minimumInputHeight = 40;
  resetInputHeight() {
    if (window.innerWidth > 768) {
      this.minimumInputHeight = 48;
    }
    this.chatInput.nativeElement.style.height = this.minimumInputHeight + 'px';
  }

  showActions = false;
  toggleActionsVisibility() {
    this.showActions = !this.showActions;
    this.cdr.markForCheck();
  }

  addLink() {
    this.bottomSheet
      .open(AddLinkComponent)
      .afterDismissed()
      .subscribe((result: ModalResult) => {
        this.enteredText = (result?.data as string) || '';
        this.enteredText && this.sendLinkMessage();
      });
  }

  sendLinkMessage() {
    this.studioFacade.setChatMessage(
      cloneDeep({
        type: MessageType.link,
        content: this.enteredText,
      }),
    );

    this.resetChatInput();
  }

  addImage() {
    this.bottomSheet
      .open(AddImageComponent, { data: this.ideaboardId })
      .afterDismissed()
      .subscribe();
  }

  addVideo() {
    this.bottomSheet
      .open(AddVideoComponent, { data: this.ideaboardId })
      .afterDismissed()
      .subscribe();
  }

  enteredText = '';
  onInput(): void {
    this.adjustInputHeight();
  }

  adjustInputHeight() {
    this.chatInput.nativeElement.style.height =
      this.chatInput.nativeElement.scrollHeight + 'px';
  }

  onChangeInput() {
    this.changeMessage.emit(this.enteredText);
  }

  onBackspace(event: Event) {
    if (!this.enteredText) {
      this.onCancelAction();
      this.resetInputHeight();
    }
  }

  ngOnDestroy(): void {
    this.subsink.unsubscribe();
  }
}
