import { HttpEvent } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ToasterService } from '@bupple/core';
import { Observable, map, tap } from 'rxjs';
import { StudioFacade } from '../+state/studio.facade';
import { IdeaboardChatApi } from '../apis/ideaboard-chat.api';
import { ChatDto } from '../dtos/chat.dto';
import { FeedbackDto, FeedbackResultDto } from '../dtos/feedback.dto';
import {
  CreateMessageDto,
  CreateMessageResultDto,
  SentMessageDto,
  UpdateChatMessageResultDto,
} from '../dtos/message.dto';
import { MessageType } from '../enums/message-type.enum';

@Injectable()
export class IdeaboardChatService {
  constructor(
    private ideaboardChatApi: IdeaboardChatApi,
    private studioFacade: StudioFacade,
    private toaster: ToasterService,
  ) {}

  actions(type?: MessageType): Observable<any> {
    return this.ideaboardChatApi
      .actions(type)
      .pipe(map((result) => ({ general: Object.values(result.general) })));
  }

  getAll(ideaboardId: string, page = 1): Observable<ChatDto> {
    return this.ideaboardChatApi.getAll(ideaboardId, page).pipe(
      tap((result) => {
        if (result.chats.current_page === 1) {
          this.studioFacade.setActiveIdeaboardChat(result.chats);
        } else {
          this.studioFacade.prependNewChatMessages(result.chats);
        }
      }),
    );
  }

  send(
    ideaboardId: string,
    message: CreateMessageDto,
  ): Observable<
    | CreateMessageResultDto<SentMessageDto>
    | HttpEvent<CreateMessageResultDto<SentMessageDto>>
  > {
    const data = new FormData();
    data.append('type', message.type);
    data.append('content', message.content);
    message.func && data.append('func', message.func);
    message.file && data.append('file', message.file as File);
    message.unit_id && data.append('unit_id', message.unit_id);

    return this.ideaboardChatApi.send(ideaboardId, data);
  }

  delete(messageId: string): Observable<any> {
    return this.ideaboardChatApi.delete(messageId).pipe(
      tap((result) => {
        result.msg && this.studioFacade.deleteMessage(messageId);
      }),
    );
  }

  downloadImage(url: string) {
    fetch(url)
      .then((response) => response.blob())
      .then((blob) => {
        const url_new = window.URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url_new;
        link.download = 'Bupple-downloaded-photo.jpg';
        link.click();
        window.URL.revokeObjectURL(url_new);
      })
      .catch((error) => {
        console.error('Error downloading the image:', error);
      });
  }

  copyText(text: string) {
    navigator.clipboard
      .writeText(text)
      .then(() => {
        this.toaster.success('Text copied to clipboard');
      })
      .catch((err) => {
        this.toaster.error(`Failed to copy text: ${err}`);
        console.error('Failed to copy text: ', err);
      });
  }

  feedback(feedback: FeedbackDto): Observable<FeedbackResultDto> {
    return this.ideaboardChatApi.feedback(feedback);
  }

  updateThumbnail(
    chatId: string,
    file: Blob,
  ): Observable<UpdateChatMessageResultDto> {
    return this.ideaboardChatApi.updateThumbnail(chatId, file);
  }

  updateCreative(chatId: string, scene: string) {
    return this.ideaboardChatApi.updateCreative(chatId, scene);
  }
}
