import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  OnInit,
} from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { VideoEditorCaptionsComponent } from '../video-editor-captions/video-editor-captions.component';
import { VideoEditorElementsComponent } from '../video-editor-elements/video-editor-elements.component';

@Component({
  selector: 'lib-video-editor-sidebar',
  standalone: true,
  imports: [
    CommonModule,
    VideoEditorCaptionsComponent,
    VideoEditorElementsComponent,
  ],

  templateUrl: './video-editor-sidebar.component.html',
  styleUrl: './video-editor-sidebar.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class VideoEditorSidebarComponent implements OnInit {
  constructor(
    private route: ActivatedRoute,
    private cdr: ChangeDetectorRef,
  ) {}

  ngOnInit(): void {
    this.listenRoute();
  }

  view!: string;
  listenRoute() {
    this.route.queryParams.subscribe((params) => {
      this.view = params['view'];
      this.cdr.markForCheck();
    });
  }
}
